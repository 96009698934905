import { createSlice } from '@reduxjs/toolkit'

const initialQuizesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	premiumQuiz: undefined,
	quizForEdit: undefined,
	currentQuiz: undefined,
	lastError: null,
}

export const callTypes = {
	list: 'list',
	action: 'action',
}

export const quizSlice = createSlice({
	name: 'quiz',
	initialState: initialQuizesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			state.currentQuiz = undefined
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getAllQuizes
		quizesFetched: (state, action) => {
			const { totalCount, entities, premiumQuiz } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
			state.premiumQuiz = premiumQuiz
		},
		// getQuizById
		quizFetched: (state, action) => {
			state.listLoading = false
			state.actionsLoading = false
			state.currentQuiz = action.payload.currentQuiz
			state.error = null
		},
		// Add Quiz
		quizAdded: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		// Update Quiz
		quizUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities = action.payload.list
		},
		// Delete Question
		quizDeleted: (state, action) => {
			let { quizId } = action.payload
			quizId = parseInt(quizId)
			state.actionsLoading = false
			state.error = null
			state.entities = state.entities.filter((el) => el.quizId !== quizId)
			state.totalCount = state.totalCount - 1
		},
		clearFetched: (state) => {
			state.listLoading = false
			state.error = null
			state.currentQuiz = undefined
		},
	},
})
