import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
// import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls'

export function QuizEditDialogHeader({ id }) {
	// Locations Redux state
	// const { locationForEdit, actionsLoading } = useSelector(
	//   (state) => ({
	//     locationForEdit: state.locations.locationForEdit,
	//     actionsLoading: state.locations.actionsLoading,
	//   }),
	//   shallowEqual
	// );

	const [title, setTitle] = useState('Create')
	// // Title couting
	// useEffect(() => {
	//   let _title = id ? "" : "Create New Location";
	//   if (locationForEdit && id) {
	//     _title = `Edit location`;
	//   }

	//   setTitle(_title);
	//   // eslint-disable-next-line
	// }, [locationForEdit, actionsLoading]);

	return (
		<>
			{/* {actionsLoading && <ModalProgressBar />} */}
			<Modal.Header>
				<div className='container' style={{ padding: 0 }}>
					<Modal.Title id='example-modal-sizes-title-lg'>{title}</Modal.Title>
				</div>
			</Modal.Header>
		</>
	)
}
