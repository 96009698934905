import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { QuizPasswordForm } from './QuizPasswordForm'

import Alert from '@material-ui/lab/Alert';

const QuizPasswordPage = ({ password, nextPlace }) => {
    useEffect(() => {
        if (password === "")
            nextPlace()
    }, [])
    const [pass, setPass] = useState("")

    const [alertStatus, setAlertStatus] = useState(false)

    const verifyPass = () => {
        if (pass === password)
            nextPlace()
        else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setAlertStatus(true);
            setTimeout(() => {
                setAlertStatus(false)
            }, 4000)
        }
    }

    return (
        <div style={style.root}>
            {alertStatus &&
                <Alert severity="error" onClose={() => { setAlertStatus(false) }} className="mb-4">
                    <FormattedMessage id="ALERT.PASSWORD_INVALID"></FormattedMessage>
                </Alert>
            }
            <QuizPasswordForm
                verifyPass={verifyPass}
                setPass={setPass}
            />
        </div>
    )
}

const style = {
    root: {
        width: '90%',
        maxWidth: 400,
        margin: '50px auto',
    }
}

export default QuizPasswordPage