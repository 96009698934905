import * as requestFromServer from './adminSettingCrud'

import { adminSettingSlice, callTypes } from './adminSettingSlice'

const { actions } = adminSettingSlice

export const fetchAdminSetting = () => async (dispatch) => {
	await dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.getAdminSetting()
		.then((response) => {
			if (response.status === 200) {
				let { adminSetting } = response.data
				dispatch(actions.adminSettingFetched(adminSetting))
			} else {
				dispatch(actions.adminSettingFetched({}))
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't get admin setting"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
