import React, { Fragment, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'

import {
    // getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from '../../../../../../../_metronic/_helpers'

import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { useFeedbackUIContext } from '../FeedbackUIContext'
import * as uiHelpers from '../FeedbackUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/feedback/feedbackAction'
import { feedbackSlice } from '../../../../_redux/feedback/feedbackSlice'

import { FeedbackFilter } from './feedback-filter/FeedbackFilter'

const feedback_slice = feedbackSlice.actions

const FeedbackTable = ({ intl }) => {
    const feedbackUIContext = useFeedbackUIContext()
    const feedbackUIProps = useMemo(() => {
        return {
            ids: feedbackUIContext.ids,
            setIds: feedbackUIContext.setIds,
            queryParams: feedbackUIContext.queryParams,
            setQueryParams: feedbackUIContext.setQueryParams,
            openViewFeedbackDialog: feedbackUIContext.openViewFeedbackDialog,
        }
    }, [feedbackUIContext])

    // Getting curret state of feedback list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({
            currentState: state.feedback,
        }),
        shallowEqual
    )

    const { totalCount, entities, listLoading } = currentState

    // feedback Redux state
    const dispatch = useDispatch()
    useEffect(() => {
        // clear selections list
        feedbackUIProps.setIds([])
        // server call by queryParams

        //initially make empty
        let totalCount = 0
        let entities = []
        dispatch(feedback_slice.feedbacksFetched({ totalCount, entities }))
        dispatch(actions.fetchAllFeedbacks(feedbackUIProps.queryParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackUIProps.queryParams, dispatch])

    // Table columns
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: 'studentName',
            text: `${intl.formatMessage({ id: 'STUDENT_NAME' })}`,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: 'questionText',
            text: `${intl.formatMessage({ id: 'QUESTION' })}`,
        },
        {
            dataField: 'feedbackText',
            text: `${intl.formatMessage({ id: 'NAV.FEEDBACK' })}`,
        },
        {
            dataField: 'action',
            text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openViewFeedbackDialog: feedbackUIProps.openViewFeedbackDialog,
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: 80,
            },
        },
    ]

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: feedbackUIProps.queryParams.pageSize,
        page: feedbackUIProps.queryParams.pageNumber,
    }

    return (
        <Fragment>
            <FeedbackFilter />
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination isLoading={listLoading} paginationProps={paginationProps} variant='primary'>
                            <BootstrapTable
                                wrapperClasses='table-responsive'
                                bordered={false}
                                classes='table table-head-custom table-vertical-center text-dark'
                                bootstrap4
                                remote
                                keyField='id'
                                data={entities === null ? [] : entities}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(feedbackUIProps.setQueryParams)}
                                selectRow={{ mode: 'radio', hideSelectColumn: true }}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    )
                }}
            </PaginationProvider>
        </Fragment>
    )
}

export default injectIntl(FeedbackTable)
