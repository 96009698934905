import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Button, Spinner } from 'react-bootstrap'
import AWS from '../../../../../../../s3config'
import { addQuestionFromCSV } from '../../../../_redux/question/questionCrud'
import swal from 'sweetalert2'

const QuestionNewCatCourseDialog = ({ show, onHide }) => {
	const [photo, setPhoto] = useState(null)
	const [loading, setLoading] = useState(false)

	const onImageChange = (event) => {
		if (event.target.files.length) {
			let file = event.target.files[0]
			if (!event.target.files.length) {
				// document.getElementById('top').scrollIntoView(true)
				// setAlert('No file attached', 'danger')
			} else {
				let extension = file.type.split('/')[1]
				if (extension === 'csv') {
					setPhoto(file)
				} else {
					// document.getElementById('top').scrollIntoView(true)
					// setAlert('Invalid file type', 'danger')
				}
			}
		} else {
			// document.getElementById('top').scrollIntoView(true)
			// setAlert('No file attached', 'danger')
		}
	}
	
	const submitCSV = async () => {
		if (photo) {
			setLoading(true)
			//First uploading images
			let extension = photo.type.split('/')[1]
			let key = Math.floor(new Date().getTime()) + '.' + extension
			let path = process.env.REACT_APP_S3_BUCKET_FOLDER_NAME + '/' + key

			let params = {
				Bucket: process.env.REACT_APP_S3_BUCKET,
				Key: path,
				Body: photo,
				ACL: 'public-read',
			}

			const s3 = new AWS.S3()
			let data = await s3.upload(params).promise()
			try {
				const result = await addQuestionFromCSV(data)
				setLoading(false)
				if (result.status === 200) {
					swal.fire(
						'Great',
						'Your data is been proccessed, you will be notified by email when completed',
						'success',
					)
					onHide()
				} else
					swal.fire(
						'Oops!',
						result?.data?.message || 'Error while uploading data',
						'error',
					)
			} catch (e) {
				setLoading(false)
				swal.fire('Oops!', 'Error while uploading data', 'error')
			}
		}
	}
	return (
		<Fragment>
			<Modal
				show={show}
				onHide={onHide}
				keyboard={false}
				backdrop='static'
				aria-labelledby='example-modal-sizes-title-lg'
			>
				<Modal.Header>
					<Modal.Title id='example-modal-sizes-title-lg'>
						Upload CSV to Create Course
						{/* <FormattedMessage id='TEACHER.CREATE_CATEGORY_AND_COURSE'></FormattedMessage> */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='mb-5'>
						<a
							href='https://s3.amazonaws.com/teachnow.io/TeachNow-Dev/1637719752512.csv'
							target='_blank'
							rel='noreferrer noopener'
						>
							Download Sample CSV
						</a>
					</div>
					<input
						accept='.csv'
						onChange={(event) => onImageChange(event)}
						type='file'
						id='questionFile'
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='success' onClick={submitCSV} disabled={loading}>
						Submit{' '}
						{loading && (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
							/>
						)}
					</Button>
					<Button variant='secondary' onClick={onHide}>
						<FormattedMessage id='CLOSE'></FormattedMessage>
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}

export default QuestionNewCatCourseDialog
