import React, { Fragment, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { isEqual } from "lodash";

import { useQuizViewStatsUIContext } from "../QuizViewStatsUIContext";

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const prepareFilter = (queryParams, searchText) => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.studentName = searchText;
    filter.studentEmail = searchText;
    newQueryParams.filter = filter;
    return newQueryParams;
};

export const QuizViewStatsFilter = () => {
    // QuizViewStats UI Context
    const quizViewStatsUIContext = useQuizViewStatsUIContext();
    const quizViewStatsUIProps = useMemo(() => {
        return {
            setQueryParams: quizViewStatsUIContext.setQueryParams,
            queryParams: quizViewStatsUIContext.queryParams,
        };
    }, [quizViewStatsUIContext]);

    const [searchText, setSearchText] = useState("")

    const setSearchTextHandler = (value) => {
        setSearchText(value)
        applyFilter(value)
    }

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(quizViewStatsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, quizViewStatsUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            quizViewStatsUIProps.setQueryParams(newQueryParams);
        }
    };

    const useStyles = makeStyles(theme => ({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
            maxWidth: 250
        },
        button: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            textTransform: 'none'
        }
    }));
    const classes = useStyles();

    return (
        <Fragment>
            <TextField
                id="outlined-search"
                label={<FormattedMessage id="FILTER.SEARCH"></FormattedMessage>}
                className={classes.textField}
                value={searchText}
                onChange={(e) => setSearchTextHandler(e.target.value)}
                margin="dense"
                variant="outlined"
                color="primary"
                helperText={<FormattedMessage id="FILTER.SEARCH_BY_STUDENT_NAME | EMAIL"></FormattedMessage>}
            />
        </Fragment>
    )
}