import React, { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { injectIntl, FormattedMessage } from "react-intl"
import { Icon } from "@material-ui/core"

import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../../../_metronic/_partials/controls"
import { Chip } from "@material-ui/core"
import { toAbsoluteUrl, getSignedUrl } from "../../../../../../../_metronic/_helpers"
import { Button, Spinner } from "react-bootstrap"
import SVG from "react-inlinesvg"
import Swal from "sweetalert2"
import * as actions from "../../../../_redux/takenQuiz/takenQuizAction"
import { makeStyles } from "@material-ui/core"
import ProvideFeedbackModal from '../../quizPool/quiz-take-page/quiz-feedback-modal/QuizFeedbackModal';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(1),
        width: "fit-content",
    },
}))

const HistoryQuizResultPage = ({
    match: {
        params: { id },
    },
    intl,
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()

    const [questions,setQuestions] = useState([])

    const { currentTakenQuiz, actionsLoading } = useSelector(
        (state) => ({
            currentTakenQuiz: state.takenQuiz.currentTakenQuiz,
            actionsLoading: state.takenQuiz.actionsLoading,
        }),
        shallowEqual
    )

    const goToDownloadCertificatePage = () => {
        if(currentTakenQuiz.quize.takenQuizes.length){
            let id = currentTakenQuiz.quize.takenQuizes[0].takenQuizId
            history.push("/student/takenQuiz/"+id+"/download")
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `${intl.formatMessage({ id: "NOT_ENOUGH_INFORMATION" })}`,
                confirmButtonColor: "#1BC5BD",
            })
        }
    }

    useEffect(() => {
        if (id) dispatch(actions.fetchLatestTakenQuizById(id, history))
    }, [dispatch, id])

    const goBack = () => {
        history.push("/student/quizPool")
    }

    useEffect(() => {
        
        if(currentTakenQuiz){
            let lists = currentTakenQuiz.quize.quizQuestionLists
            let q_list = []
            for(let i=0;i<lists.length;i++){
                q_list.push(lists[i].question)
            }
            setQuestions(q_list)
        }

    }, [currentTakenQuiz]);

    const [viewQuestion, setViewQuestion] = useState(false)
    const [showFeedBack, setShowFeedback] = useState(false)
    return (
        <Fragment>
            <ProvideFeedbackModal
                showFeedBack={showFeedBack}
                onHide={() => setShowFeedback(false)}
                question={{}}
                questions={questions}
            />
                <Card>
                    <CardHeader title={`${intl.formatMessage({ id: "QUIZ_RESULT" })}`}>
                        <CardHeaderToolbar>
                            <Button variant='secondary' size='sm' onClick={goBack}>
                                <span className='svg-icon svg-icon-md svg-icon-dark'>
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
                                </span>
                                <FormattedMessage id='GO_BACK'></FormattedMessage>
                            </Button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        {actionsLoading && (
                            <div className="text-center p-2">
                                <Spinner animation="border" variant="dark" />
                            </div>
                        )}
                        {currentTakenQuiz && !actionsLoading && (
                            <Fragment>
                        <div className='d-flex mb-8'>
                                    <h6 className='mr-4'>
                                        <FormattedMessage id='QUIZ_NAME'></FormattedMessage>
                                    </h6>
                            <p>{currentTakenQuiz.quize.quizName}</p>
                        </div>

                                {/* <p>
                                    <FormattedMessage id='PROMPT_YOU_HAVE_SCORED'></FormattedMessage>{" "}
                                    <strong>{Math.floor(currentTakenQuiz.percentage*currentTakenQuiz.quize.totalQuestion/100)}</strong> <FormattedMessage id='OF'></FormattedMessage>{" "}
                                    <strong>{currentTakenQuiz.quize.totalQuestion}</strong> points
                                </p> */}

                        <p>
                            <FormattedMessage id='PROMPT_YOU_HAVE_SCORED'></FormattedMessage>{" "}
                                    <strong>{currentTakenQuiz.percentage}%</strong>
                        </p>
                        
                                <p>
                                    <FormattedMessage id='CORRECT_ANSWERED'></FormattedMessage>{": "}
                                    <strong>{currentTakenQuiz.correctAnswered}</strong>
                                </p>
                                
                            <p>
                                <FormattedMessage id='NEGATIVE_POINTS'></FormattedMessage>{": "}
                                <strong>{currentTakenQuiz.negativePoints}</strong>
                            </p>
                        
                        <p>
                            <FormattedMessage id='PROMPT_TIME_TO_COMPLETE_QUIZ'></FormattedMessage>{" "}
                            <strong>
                                {Math.floor(currentTakenQuiz.timeTaken / 60)} min {currentTakenQuiz.timeTaken % 60} sec
                            </strong>
                        </p>
                                <div style={{...style.btnGroup, maxWidth: currentTakenQuiz.quize.showAnswer === 'Yes' ? 550 : 400}}>
                                    {currentTakenQuiz.quize.showAnswer === 'Yes' &&
                            <Button className="mr-2" variant='primary' onClick={() => setViewQuestion(!viewQuestion)}>
                                <FormattedMessage id='VIEW_QUESTIONS'></FormattedMessage>
                            </Button>
                                    }
                            <Button className="mr-2"
                                variant='primary'
                                onClick={() => history.push(`/student/quizPool/${currentTakenQuiz.quizId}/take`)}
                            >
                                <FormattedMessage id='RESTART_EXAM'></FormattedMessage>
                            </Button>
                            <Button className="mr-2" variant='primary' onClick={() => setShowFeedback(true)}>
                                <FormattedMessage id='PROVIDE_FEEDBACK'></FormattedMessage>
                            </Button>
                            <Button className="mr-2" variant='primary' onClick={() => goToDownloadCertificatePage()}>
                                <FormattedMessage id='DOWNLOAD_CERTIFICATE'></FormattedMessage>
                            </Button>
                        </div>
                                {viewQuestion && currentTakenQuiz.quize.showAnswer === 'Yes' && (
                            <div>
                                <h6>
                                    <FormattedMessage id='QUESTION_LIST'></FormattedMessage>
                                </h6>
                                {currentTakenQuiz.historyTakenQuizAnswers.map((takenQuestion, j) => (
                                    <div key={j} className='my-4 border border-dark rounded p-4'>
                                                <strong>
                                                    <FormattedMessage id='QUESTION'></FormattedMessage>
                                                </strong>
                                        <p>{takenQuestion.question.questionText}</p>
                                        <div style={style.box}>
                                                    <strong>
                                                        <FormattedMessage id='GIVEN_ANSWERS'></FormattedMessage>
                                                    </strong>
                                            <span className='text-success ml-4'>
                                                {true && (
                                                    <span className='d-flex align-items-baseline'>
                                                        {takenQuestion.answerStatus ? (
                                                            <span className='text-success ml-4'>
                                                                <span className='d-flex align-items-baseline'>
                                                                    <Icon className='fas fa-check mr-2' />
                                                                    <span>
                                                                        <FormattedMessage id='CORRECT'></FormattedMessage>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        ) : (
                                                            <span className='text-danger ml-4'>
                                                                <span className='d-flex align-items-center'>
                                                                    <Icon className='fas fa-times mr-2' />
                                                                    <span>
                                                                        <FormattedMessage id='WRONG'></FormattedMessage>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        )}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        {takenQuestion.historyAnswerFurthers.length > 0 ? (
                                            takenQuestion.historyAnswerFurthers.map((answer, index) => (
                                                <Chip key={index} label={answer.optionText} className={classes.chip} />
                                            ))
                                        ) : (
                                                    <h6>
                                                        <FormattedMessage id='NO_ANSWER_GIVEN'></FormattedMessage>
                                                    </h6>
                                        )}
                                        <p>
                                            <strong>
                                                <FormattedMessage id='ANSWER_EXPLAINATION'></FormattedMessage>
                                            </strong>
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: takenQuestion.question.answerExplaination }}
                                        ></div>
                                        <div>
                                            {takenQuestion.question.image &&
                                            <img
                                                className="questionImage my-4"
                                                src={getSignedUrl(takenQuestion.question.image)}
                                                alt="explaination"
                                            />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                            </Fragment>
                        )}
                    </CardBody>
                </Card>
        </Fragment>
    )
}
const style = {
    btnGroup: {
        width: "100%",
        margin: "30px auto",
        display: "flex",
        justifyContent: "center",
    },
    box: {
        display: "flex",
        alignItems: "baseline",
        marginBottom: 10,
    },
}

export default injectIntl(HistoryQuizResultPage)
