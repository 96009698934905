import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Input } from "../../../../../../../../_metronic/_partials/controls";
import { Button } from 'react-bootstrap'

const QuizPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password required!')
});

export function QuizPasswordForm({ verifyPass, setPass }) {
    const intl = useIntl();

    return (
        <Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={{ password: '' }}
                onSubmit={(values) => {
                    verifyPass(values.password)
                }}
                validationSchema={QuizPasswordSchema}
            >
                {({ values, setFieldValue, handleSubmit }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <Field
                                        name="password"
                                        component={Input}
                                        type="password"
                                        placeholder={intl.formatMessage({ id: "QUIZ_PASSWORD" })}
                                        label={<FormattedMessage id="QUIZ_PASSWORD"></FormattedMessage>}
                                        value={values.password}
                                        onChange={e => {
                                            setFieldValue('password', e.target.value);
                                            setPass(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                onSubmit={handleSubmit}
                            >
                                <FormattedMessage id="VERIFY"></FormattedMessage>
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </Fragment >
    )
}