import React, { Fragment } from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../../_metronic/_partials/controls";
import { Button } from 'react-bootstrap'

const newCatCourseSchema = Yup.object().shape({
    category: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Question category is required"),
    course: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Question course is required")
});

export function NewCatCourseForm({ formData, saveFormData, btnRef }) {
    const intl = useIntl();

    return (
        <Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(values) => {
                    saveFormData(values);
                }}
                validationSchema={newCatCourseSchema}
            >
                {({ handleSubmit }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group">
                                <div className="mb-4">
                                    <Field
                                        name="category"
                                        component={Input}
                                        disabled={formData.category !== ''}
                                        placeholder={intl.formatMessage({ id: "QUESTION_CATEGORY" })}
                                        label={<FormattedMessage id="QUESTION_CATEGORY"></FormattedMessage>}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        name="course"
                                        component={Input}
                                        placeholder={intl.formatMessage({ id: "QUESTION_COURSE" })}
                                        label={<FormattedMessage id="QUESTION_COURSE"></FormattedMessage>}
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                className="d-none"
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            >
                                <FormattedMessage id="CREATE"></FormattedMessage>
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </Fragment>
    )
}