import * as requestFromServer from './questionCrud'

import Swal from 'sweetalert2'

import { questionSlice, callTypes } from './questionSlice'

const { actions } = questionSlice

export const fetchQuestions = (queryParams, category, course) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	return requestFromServer
		.getAllQuestion(queryParams, category, course)
		.then((response) => {
			const { totalCount, entities } = response.data
			dispatch(actions.questionsFetched({ totalCount, entities }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find questions"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchQuestionById = (qId, history) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.getQuestionById(qId)
		.then((response) => {
			if (response.status === 200) {
				const { question } = response.data
				dispatch(actions.questionFetched({ question }))
			} else {
				dispatch(actions.endCall({ callType: callTypes.action }))
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				}).then(() => {
					history.push('/teacher/questions')
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find question"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const randomQuestion = (filter) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	requestFromServer
		.randomQuestion(filter)
		.then((response) => {
			const { totalCount, entities } = response.data
			dispatch(actions.questionsFetched({ totalCount, entities }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find quizes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const addQuestion = (questionData, history, openedinmodal) => (
	dispatch,
) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.addQuestion(questionData)
		.then((response) => {
			dispatch(actions.endCall({ callType: callTypes.action }))
			if (response.status === 200) {
				if (!openedinmodal) {
					// const { totalCount, entities } = response.data
					// dispatch(actions.questionsFetched({ totalCount, entities }))
					Swal.fire({
						title: 'success',
						html: '<strong>Question Added</strong>',
						icon: 'success',
						confirmButtonColor: '#1BC5BD',
						confirmButtonText: 'CONTINUE',
					}).then(() => {
						history.push('/teacher/questions')
					})
				} else {
					return response
				}
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't add quiz"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateQuestion = (currentQuestion, history, questionData) => (
	dispatch,
) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	requestFromServer
		.updateQuestion(questionData)
		.then((response) => {
			if (response.status === 200) {
				const { list } = response.data
				dispatch(actions.questionUpdated({ list }))
				Swal.fire({
					title: 'success',
					html: '<strong>Question Updated</strong>',
					icon: 'success',
					confirmButtonColor: '#1BC5BD',
					confirmButtonText: 'CONTINUE',
				}).then(() => {
					//history.push('/teacher/questions/'+currentQuestion.questionId+'/view')
					history.push(
						'/teacher/questions/' +
							currentQuestion.questionCategory +
							'/' +
							currentQuestion.questionCourse +
							'/load',
					)
				})
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't add quiz"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
