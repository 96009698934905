import { uniq } from 'lodash'

import * as requestFromServer from './takenQuizCrud'

import Swal from 'sweetalert2'

import { takenQuizSlice, callTypes } from './takenQuizSlice'

const { actions } = takenQuizSlice

export const answerOne = (questionId, optionType, answer) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    let response = await requestFromServer.answerOneQuestions(questionId, optionType, answer)
    // .then((response) => {

    // })
    // .catch((error) => {
    //     error.clientMessage = "Can't find taken quiz"
    //     dispatch(actions.catchError({ error, callType: callTypes.list }))
    // })
    //Just for reset loading
    await dispatch(actions.endQuiz({ callType: callTypes.action }))
    if (response.status === 200) {
        console.log(response.data)
        let { answerStatus } = response.data
        if (answerStatus) {
            return 1
        } else {
            return 0
        }
    } else {
        let { message } = response.data
        Swal.fire({
            icon: 'error',
            title: 'Error',
            html: `<strong>${message}</strong>`,
        }).then(() => {
            return -1
        })
    }
}
export const answerAll = (answers, quizId, timeTaken, history, intl) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .answerAllQuestions(answers, quizId, timeTaken)
        .then((response) => {
            dispatch(actions.endQuiz())
            if (response.status === 200) {
                console.log(response.data)
                const { takenQuizObject } = response.data
                Swal.fire({
                    icon: 'success',
                    title: `${intl.formatMessage({ id: 'QUIZ_COMPLETED' })}`,
                    html: `${intl.formatMessage({ id: 'QUIZ_COMPLETED' })}`,
                    confirmButtonColor: '#1BC5BD',
                }).then(() => {
                    history.push(`/student/takenQuiz/${takenQuizObject.takenQuizId}/newResult`)
                    // history.push(`/student/takenQuiz`)
                })
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}

export const fetchTakenQuizByStudentId = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getTakenQuizByStudentId(queryParams)
        .then((response) => {
            if (response.status === 200) {
                const { totalCount, entities } = response.data
                dispatch(actions.takenQuizFetched({ totalCount, entities, entityType: 'taken' }))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    dispatch(actions.takenQuizFetched({ totalCount: 0, entities: [], entityType: 'taken' }))
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchAllTakenQuiz = (queryParams) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getAllTakenQuiz(queryParams)
        .then((response) => {
            if (response.status === 200) {
                const { totalCount, entities } = response.data
                dispatch(actions.takenQuizFetched({ totalCount, entities, entityType: 'all' }))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    dispatch(actions.takenQuizFetched({ totalCount: 0, entities: [], entityType: 'all' }))
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const updateStatus = (takenQuizIds, status, qId, queryParams) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .updateStatus(takenQuizIds, status)
        .then((response) => {
            if (response.status === 200) {
                dispatch(fetchTakenQuizByTeacherIdAndQId(qId, queryParams))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    dispatch(actions.takenQuizFetched({ totalCount: 0, entities: [], entityType: 'stats' }))
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchTakenQuizByTeacherIdAndQId = (qId, queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getTakenQuizByTeacherIdAndQId(qId, queryParams)
        .then((response) => {
            dispatch(actions.clearList())
            if (response.status === 200) {
                const { totalCount, entities } = response.data
                dispatch(actions.takenQuizFetched({ totalCount, entities, entityType: 'stats' }))
            } else {
                dispatch(actions.takenQuizFetched({ totalCount: 0, entities: [], entityType: 'stats' }))
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchTakenQuizById = (id, history) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .getTakenQuizById(id)
        .then((response) => {
            if (response.status === 200) {
                dispatch(actions.takenQuizByIdFetched(response.data))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    history.push('/student/takenQuiz')
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}

export const fetchLatestTakenQuizById = (id, history) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .getLatestTakenQuizById(id)
        .then((response) => {
            if (response.status === 200) {
                dispatch(actions.takenQuizByIdFetched(response.data))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    history.push(`/student/takenQuiz/${id}/newResult`)
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find taken quiz"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}

export const fetchTakenQuizByStudentIdAndQId = (sId, qId) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getTakenQuizByStudentIdAndQId(sId, qId)
        .then((response) => {
            dispatch(actions.totalAttemptsFetched(response.data))
        })
        .catch((error) => {
            error.clientMessage = "Can't find remaining attempts"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const fetchTakenQuizNames = () => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getAllTakenQuizName()
        .then((response) => {
            if (response.status === 200) {
                let { quizNames } = response.data
                dispatch(actions.takenQuizNameFetched(uniq(quizNames)))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                })
                dispatch(actions.takenQuizNameFetched([]))
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find quiz names"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const sendEmail = (studentName, studentEmail, subject, message, qId, history) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .sendEmail(studentName, studentEmail, subject, message)
        .then((response) => {
            dispatch(actions.endCall({ callType: callTypes.action }))
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Email sent',
                    html: 'Email has been sent successfully',
                    confirmButtonColor: '#1BC5BD',
                }).then(() => {
                    if (qId === -1) {
                        history.push('/admin/leaderBoard')
                    } else history.push(`/teacher/quiz/${qId}/viewStats`)
                })
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't send email"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}

export const downloadCSV = (id) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .downloadCSV(id)
        .then((response) => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'CSV downloaded',
                    html: 'Your CSV file has been downloaded',
                    confirmButtonColor: '#1BC5BD',
                }).then(() => {
                    dispatch(actions.endCall({ callType: callTypes.action }))
                })
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't send email"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}
