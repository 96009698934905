export default
    [
        {
            id: "9e516e22-b27d-4a73-9efe-1b26c46adb4f",
            questionText: 'New Question text 1',
            category: 'Calculation',
            course: 'Calculus',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 1',
                    status: false
                },
                {
                    text: 'Option text 2',
                    status: false
                },
                {
                    text: 'Option text 3',
                    status: true
                }
            ],
            answerExplaination: "<p>New Question text 1 answer explaination</p>"
        },
        {
            id: "51b85773-5699-444d-ba9b-7914ac421996",
            questionText: 'New Question text 2',
            category: 'Calculation',
            course: 'Differential Equation',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 4',
                    status: false
                },
                {
                    text: 'Option text 5',
                    status: false
                },
                {
                    text: 'Option text 6',
                    status: true
                }
            ],
            answerExplaination: "<p>New Question text 2 answer explaination</p>"
        },
        {
            id: "b89fca36-0156-4ab7-9826-16512dd3f6d8",
            questionText: 'New Question text 3',
            category: 'Calculation',
            course: 'Differential Equation',
            optionType: 'checkbox',
            options: [
                {
                    text: 'Option text 7',
                    status: true
                },
                {
                    text: 'Option text 8',
                    status: false
                },
                {
                    text: 'Option text 9',
                    status: true
                }
            ],
            answerExplaination: "<p>New Question text 3 answer explaination</p>"
        },
        {
            id: "85735ab2-7ae5-40e3-ac2f-61ff244e1543",
            questionText: 'New 2 Question text 1',
            category: 'Literature',
            course: 'English',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 1',
                    status: false
                },
                {
                    text: 'Option text 2',
                    status: true
                },
                {
                    text: 'Option text 3',
                    status: false
                }
            ],
            answerExplaination: "<p>New 2 Question text 1 answer explaination</p>"
        },
        {
            id: "36c3c924-ab70-4226-b7bf-e81d4e3562e2",
            questionText: 'New 2 Question text 2',
            category: 'Literature',
            course: 'Geography',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 4',
                    status: true
                },
                {
                    text: 'Option text 5',
                    status: false
                },
                {
                    text: 'Option text 6',
                    status: false
                }
            ],
            answerExplaination: "<p>New 2 Question text 2 answer explaination</p>"
        },
        {
            id: "61188ee7-36c0-4e82-a456-59d91f0e1bfb",
            questionText: 'New 2 Question text 3',
            category: 'Literature',
            course: 'English',
            optionType: 'checkbox',
            options: [
                {
                    text: 'Option text 7',
                    status: false
                },
                {
                    text: 'Option text 8',
                    status: true
                },
                {
                    text: 'Option text 9',
                    status: true
                }
            ],
            answerExplaination: "<p>New 2 Question text 3 answer explaination</p>"
        },
        {
            id: "9bfae27a-f379-43f6-b230-0a9aa991ad52",
            questionText: 'Quiz 3 Question text 1',
            category: 'Calculation',
            course: 'Math',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 1',
                    status: true
                },
                {
                    text: 'Option text 2',
                    status: false
                },
                {
                    text: 'Option text 3',
                    status: false
                }
            ],
            answerExplaination: "<p>Quiz 3 Question text 1 answer explaination</p>"
        },
        {
            id: "55689a75-6184-4271-99a3-9f6a14132a38",
            questionText: 'Quiz 3 Question text 2',
            category: 'Calculation',
            course: 'Math',
            optionType: 'radio',
            options: [
                {
                    text: 'Option text 4',
                    status: false
                },
                {
                    text: 'Option text 5',
                    status: false
                },
                {
                    text: 'Option text 6',
                    status: true
                }
            ],
            answerExplaination: "<p>Quiz 3 Question text 2 answer explaination</p>"
        }
    ]