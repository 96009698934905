import React from 'react'
import { QuizUIProvider } from './QuizUIContext'
import QuizTable from './quiz-table/QuizTable'
import { QuizEditDialog } from './quiz-edit-dialog/QuizEditDialog'
import { Route } from 'react-router'

const QuizPoolPage = ({ history }) => {
	const quizUIEvents = {
		newQuizButtonClick: () => {
			history.push('/teacher/quizPool/new')
		},
		openEditQuizDialog: (id) => {
			history.push(`/teacher/quizPool/${id}/edit`)
		},
		openEditQuizPage: (id) => {
			history.push(`/teacher/quiz/${id}/edit`)
		},
		openViewQuizPage: (id) => {
			history.push(`/teacher/quiz/${id}/view`)
		},
		openViewStatsPage: (id) => {
			history.push(`/teacher/quiz/${id}/viewStats`)
		},
	}

	return (
		<QuizUIProvider quizUIEvents={quizUIEvents}>
			<Route path='/teacher/quizPool/new'>
				{({ history, match }) => (
					<>
						<QuizEditDialog
							show={match != null}
							onHide={() => {
								history.push('/teacher/quizPool')
							}}
						/>
					</>
				)}
			</Route>
			<Route path='/teacher/quizPool/:id/edit'>
				{({ history, match }) => (
					<QuizEditDialog
						show={match != null}
						id={match && match.params.id}
						onHide={() => {
							history.push('/teacher/quizPool')
						}}
					/>
				)}
			</Route>
			<QuizTable />
		</QuizUIProvider>
	)
}

export default QuizPoolPage
