import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { FormattedMessage } from 'react-intl'
import { Modal, Button, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { deleteQuestion } from "../../../../_redux/question/questionCrud";
import { questionSlice } from "../../../../_redux/question/questionSlice"
const { actions } = questionSlice

const QuestionDeleteDialog = ({ id, show, onHide }) => {
    // if !id we should close modal
    useEffect(() => {
        if (!id) {
            onHide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const dispatch = useDispatch()

    const [loading, setLoading]= useState(false)

    const deleteIt = async () => {
        setLoading(true)
        let response = await deleteQuestion(id)
        setLoading(false)
        if(response.status===200){
            let { message } = response.data
            dispatch(actions.questionDeleted({ questionId: id }))
            Swal.fire({
                title: 'success',
                html: `<strong>${message}</strong>`,
                icon: 'success',
                confirmButtonColor: '#1BC5BD',
                confirmButtonText: 'CONTINUE',
            })
        }
        else{
            let { message } = response.data
            Swal.fire({
                icon: 'error',
                title: 'Error',
                html: `<strong>${message}</strong>`,
              })
        }
        onHide()
    }

    return (
        <Fragment>
            <Modal
                show={show}
                onHide={onHide}
                keyboard={false}
                backdrop="static"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <FormattedMessage id="DELETE_QUESTION"></FormattedMessage>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <FormattedMessage id="PROMPT_DELETE_QUESTION"></FormattedMessage>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteIt()}>
                        <div className="d-flex align-items-center">
                        <FormattedMessage id="DELETE"></FormattedMessage>
                            {loading &&
                                <Spinner animation="border" className="ml-2" variant="light" />
                            }
                        </div>
                    </Button>
                    <Button variant="secondary" onClick={() => onHide()}>
                        <FormattedMessage id="CLOSE"></FormattedMessage>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default QuestionDeleteDialog
