import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Icon } from '@material-ui/core'

export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openViewFeedbackDialog }) => {
    return (
        <Fragment>
            <OverlayTrigger
                overlay={
                    <Tooltip id='feedback-view-tooltip'>
                        <FormattedMessage id='VIEW_QUESTION'></FormattedMessage>
                    </Tooltip>
                }
            >
                <div
                    className='btn btn-icon btn-sm btn-light btn-hover-primary text-primary'
                    onClick={() => openViewFeedbackDialog(row.id)}
                >
                    <Icon className='fas fa-eye w-75' />
                </div>
            </OverlayTrigger>
        </Fragment>
    )
}
