import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, CardBody, CardHeader } from '../../../../../../_metronic/_partials/controls'

import FeedbackTable from './feedback-table/FeedackTable'

const FeedbackCard = () => {
    return (
        <Fragment>
            <Card>
                <CardHeader title={<FormattedMessage id='NAV.FEEDBACK'></FormattedMessage>}></CardHeader>
                <CardBody>
                    <FeedbackTable />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default FeedbackCard
