import React, { Fragment } from "react";
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (cellContent,
    row,
    rowIndex,
    { downloadCertificateDialog, emailStudentDialog,user }) => {

    let {profile} = user
    
    return (
        
        profile === parseInt(process.env.REACT_APP_OWNER_PROFILE_ID) ?
        
        <Fragment>
            {user.userType!=='Student' &&
            <Fragment>
            <OverlayTrigger
                overlay={<Tooltip id="quiz-view-tooltip"><FormattedMessage id="EMAIL"></FormattedMessage></Tooltip>}
            >
                <div
                    className="btn btn-icon btn-sm btn-light btn-hover-primary ml-3"
                    onClick={() => emailStudentDialog(row.id)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
                    </span>
                </div>
            </OverlayTrigger>

            <></>
            </Fragment>
            }
            <OverlayTrigger
                overlay={<Tooltip id="quiz-view-tooltip"><FormattedMessage id="DOWNLOAD_CERTIFICATE"></FormattedMessage></Tooltip>}
            >
                <div
                    className="btn btn-icon btn-sm btn-light btn-hover-primary ml-3"
                    onClick={() => downloadCertificateDialog(row.id)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-download.svg")} />
                    </span>
                </div>
            </OverlayTrigger>
        </Fragment>
        :
        <Fragment></Fragment>
    )
}