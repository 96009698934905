import React, { Fragment, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import ReactStars from 'react-rating-stars-component'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../../../../_metronic/_partials/controls'

import {
	// getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from '../../../../../../../_metronic/_helpers'

import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { useTakenQuizUIContext } from '../TakenQuizUIContext'
import * as uiHelpers from '../TakenQuizUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/takenQuiz/takenQuizAction'
import { takenQuizSlice } from '../../../../_redux/takenQuiz/takenQuizSlice'

import { TakenQuizFilter } from '../taken-quiz-filter/TakenQuizFilter'

const takenQuiz_slice = takenQuizSlice.actions

const TakenQuizTable = ({ intl }) => {
	const takenQuizUIContext = useTakenQuizUIContext()
	const takenQuizUIProps = useMemo(() => {
		return {
			ids: takenQuizUIContext.ids,
			setIds: takenQuizUIContext.setIds,
			queryParams: takenQuizUIContext.queryParams,
			setQueryParams: takenQuizUIContext.setQueryParams,
			downloadCertificateDialog: takenQuizUIContext.downloadCertificateDialog,
			retakeQuizDialog: takenQuizUIContext.retakeQuizDialog,
			openResultPage: takenQuizUIContext.openResultPage,
		}
	}, [takenQuizUIContext])

	// Getting curret state of quiz list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({
			currentState: state.takenQuiz,
		}),
		shallowEqual,
	)

    const { totalCount, entities, entityType, listLoading } = currentState

	// taken quiz Redux state
	const dispatch = useDispatch()
	useEffect(() => {
		// clear selections list
		takenQuizUIProps.setIds([])
		// server call by queryParams

		//initially make empty
		let totalCount = 0
		let entities = []
        dispatch(takenQuiz_slice.takenQuizFetched({ totalCount, entities, entityType:"" }))
		dispatch(actions.fetchTakenQuizByStudentId(takenQuizUIProps.queryParams))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [takenQuizUIProps.queryParams, dispatch])

	// Table columns
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'quizName',
			text: `${intl.formatMessage({ id: 'QUIZ_NAME' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'quizDescription',
			text: `${intl.formatMessage({ id: 'DESCRIPTION' })}`,
			style: {
				maxWidth: 150,
				display: 'table-cell',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
		{
			dataField: 'totalQuestion',
			text: `${intl.formatMessage({ id: 'TOTAL_QUESTION' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'percentage',
			text: `${intl.formatMessage({ id: 'PERCENTAGE' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'attemptDate',
			text: `${intl.formatMessage({ id: 'DATE_OF_ATTEMPT' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
			style: {
				minWidth: 120,
			},
			formatter: (cell, row) => {
				let date = new Date(parseInt(cell))
				return <>{moment(date).format('MMM DD, YYYY - HH:MM a')}</>
				// return date.toLocaleString()
			},
		},
		// {
		//     dataField: "rating",
		//     text: `${intl.formatMessage({ id: "RATING" })}`,
		//     headerClasses: "text-center",
		//     formatter:(cell,row) => {
		//         return <div className="d-flex justify-content-center">
		//             <ReactStars
		//                 count={5}
		//                 value={cell ? parseInt(cell) : 0}
		//                 size={24}
		//                 edit={false}
		//                 activeColor="#ffd700"
		//             />
		//         </div>
		//     }
		// },
		{
			dataField: 'status',
			text: `${intl.formatMessage({ id: 'STATUS' })}`,
		},
		{
			dataField: 'action',
			text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				retakeQuizDialog: takenQuizUIProps.retakeQuizDialog,
				downloadCertificateDialog: takenQuizUIProps.downloadCertificateDialog,
				openResultPage: takenQuizUIProps.openResultPage,
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '130px',
			},
		},
	]

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: takenQuizUIProps.queryParams.pageSize,
		page: takenQuizUIProps.queryParams.pageNumber,
	}

	return (
		<Fragment>
			<Card>
				<CardHeader
					title={<FormattedMessage id='NAV.TAKEN_QUIZ'></FormattedMessage>}
				></CardHeader>
				<CardBody>
					<TakenQuizFilter />
					<PaginationProvider pagination={paginationFactory(paginationOptions)}>
						{({ paginationProps, paginationTableProps }) => {
							return (
                                <Pagination isLoading={listLoading || entities === null || entityType !=="taken"} paginationProps={paginationProps} variant='primary'>
									<BootstrapTable
										wrapperClasses='table-responsive'
										bordered={false}
										classes='table table-head-custom table-vertical-center'
										bootstrap4
										remote
										keyField='id'
                                        data={entities === null || entityType !=="taken" ? [] : entities}
										columns={columns}
										defaultSorted={uiHelpers.defaultSorted}
										onTableChange={getHandlerTableChange(
											takenQuizUIProps.setQueryParams,
										)}
										//For selecting columns
										// selectRow={getSelectRow({
										//     entities,
										//     ids,
										//     setIds,
										// })}
										{...paginationTableProps}
									>
										<PleaseWaitMessage entities={entities} />
										<NoRecordsFoundMessage entities={entities} />
									</BootstrapTable>
								</Pagination>
							)
						}}
					</PaginationProvider>
				</CardBody>
			</Card>
		</Fragment>
	)
}
export default injectIntl(TakenQuizTable)
