import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Button } from 'react-bootstrap';

import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(4, 8),
    },
    formLabel: {
        fontSize: 15,
        textAlign: 'center'
    },
    group: {
        margin: theme.spacing(1, 0),
    },
}));

const QuizOptionSelectionPage = ({ nextPlace, option, setOption, showAnswerSettingAllowed, showAnswer }) => {
    const classes = useStyles()

    const [alertStatus, setAlertStatus] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [triggered, setTriggered]=useState(false)

    useEffect(()=>{
        if(
            showAnswerSettingAllowed === 'No' ||
            (showAnswerSettingAllowed === 'Yes' && showAnswer === 'No')
        ){
            setOption("all")
            setShowOption(false)
            setTriggered(true)
        }
        else{
            setShowOption(true)
        }
    },[])

    useEffect(()=>{
        if(option==='all' && triggered){
            goToNext()
        }
    },[triggered,option])

    const goToNext = () => {
        if (option !== "")
            nextPlace()
        else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setAlertStatus(true);
            setTimeout(() => {
                setAlertStatus(false)
            }, 4000)
        }
    }

    return (
        <div style={style.root}>
            {alertStatus &&
                <Alert severity="error" onClose={() => { setAlertStatus(false) }} className="mb-4">
                    <FormattedMessage id="ALERT.SELECT_OPTION"></FormattedMessage>
                </Alert>
            }
            {showOption &&
            <div className="bg-light rounded">
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend" className={`${classes.formLabel} text-dark h4`}>
                        <FormattedMessage id="PROMPT_SELECT_EXAM_MODE"></FormattedMessage>
                    </FormLabel>
                    <RadioGroup
                        className={classes.group}
                        value={option}
                        onChange={e => setOption(e.target.value)}
                    >
                        <FormControlLabel value="all" control={<Radio color="primary" />} label={<FormattedMessage id="EXAM_MODE_OPTION_ALL"></FormattedMessage>} />
                        <FormControlLabel value="onebyone" control={<Radio color="primary" />} label={<FormattedMessage id="EXAM_MODE_OPTION_ONE"></FormattedMessage>} />
                    </RadioGroup>
                    <Button variant="primary" onClick={() => goToNext()}>
                        <FormattedMessage id="NEXT"></FormattedMessage>
                    </Button>
                </FormControl>
            </div>
            }
        </div>
    )
}

const style = {
    root: {
        width: '90%',
        maxWidth: 400,
        margin: '30px auto'
    }
}

export default QuizOptionSelectionPage
