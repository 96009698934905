import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/_helpers'
import { Icon } from '@material-ui/core'

export const ActionsColumnFormatter = (
	cellContent,
	row,
	rowIndex,
	{
		prepareDeleteQuiz,
		openEditQuizDialog,
		openViewQuizPage,
		openViewStatsPage,
	},
) => {
	return (
		<Fragment>
			<OverlayTrigger
				overlay={
					<Tooltip id='quiz-edit-tooltip'>
						<FormattedMessage id='EDIT_QUIZ'></FormattedMessage>
					</Tooltip>
				}
			>
				<div
					className='btn btn-icon btn-sm btn-light btn-hover-primary'
					onClick={() => openEditQuizDialog(row.quizId, row)}
				>
					<span className='svg-icon svg-icon-md svg-icon-primary'>
						<SVG
							src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
						/>
					</span>
				</div>
			</OverlayTrigger>

			<> </>
			<OverlayTrigger
				overlay={
					<Tooltip id='quiz-view-tooltip'>
						<FormattedMessage id='VIEW_QUIZ'></FormattedMessage>
					</Tooltip>
				}
			>
				<div
					className='btn btn-icon btn-sm btn-light btn-hover-primary text-primary ml-3'
					onClick={() => openViewQuizPage(row.quizId)}
				>
					<Icon className='fas fa-eye w-75' />
				</div>
			</OverlayTrigger>

			<> </>
			<OverlayTrigger
				overlay={
					<Tooltip id='quiz-edit-tooltip'>
						<FormattedMessage id='VIEW_STATS'></FormattedMessage>
					</Tooltip>
				}
			>
				<div
					className='btn btn-icon btn-sm btn-light btn-hover-primary ml-3'
					onClick={() => openViewStatsPage(row.quizId)}
				>
					<span className='svg-icon svg-icon-md svg-icon-primary'>
						<SVG src={toAbsoluteUrl('/media/svg/icons/Media/Equalizer.svg')} />
					</span>
				</div>
			</OverlayTrigger>

			<OverlayTrigger
				overlay={
					<Tooltip id='quiz-delete-tooltip'>
						<FormattedMessage id='DELETE'></FormattedMessage>
					</Tooltip>
				}
			>
				<div
					className='btn btn-icon btn-sm btn-light btn-hover-primary ml-3'
					onClick={() => prepareDeleteQuiz(row.quizId, row)}
				>
					<span className='svg-icon svg-icon-md svg-icon-danger'>
						<SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
					</span>
				</div>
			</OverlayTrigger>
		</Fragment>
	)
}
