import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from './QuizViewStatsUIHelper'

const QuizViewStatsUIContext = createContext();

export function useQuizViewStatsUIContext() {
    return useContext(QuizViewStatsUIContext);
}

export const QuizViewStatsUIConsumer = QuizViewStatsUIContext.Consumer;

export function QuizViewStatsUIProvider({ quizViewStatsUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        downloadCertificateDialog: quizViewStatsUIEvents.downloadCertificateDialog,
        emailQuizDialog: quizViewStatsUIEvents.emailQuizDialog
    };

    return <QuizViewStatsUIContext.Provider value={value}>{children}</QuizViewStatsUIContext.Provider>;
}