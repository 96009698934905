import React, { Fragment } from "react";
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { Icon } from '@material-ui/core'

export const ActionsColumnFormatter = (cellContent,
    row,
    rowIndex,
    { openEditQuestionPage, openViewQuestionDialog, openDeleteQuestionDialog }) => {
    return (
        <Fragment>
            <OverlayTrigger
                overlay={<Tooltip id="question-view-tooltip"><FormattedMessage id="VIEW_QUESTION"></FormattedMessage></Tooltip>}
            >
                <div
                    className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
                    onClick={() => openViewQuestionDialog(row.id)}
                >
                    <Icon className="fas fa-eye w-75" />
                </div>
            </OverlayTrigger>

            <> </>

            <OverlayTrigger
                overlay={<Tooltip id="question-edit-tooltip"><FormattedMessage id="EDIT_QUESTION"></FormattedMessage></Tooltip>}
            >
                <div
                    className="btn btn-icon btn-sm btn-light btn-hover-primary ml-3"
                    onClick={() => openEditQuestionPage(row.id)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                    </span>
                </div>
            </OverlayTrigger>

            <> </>

            <OverlayTrigger
                overlay={<Tooltip id="question-delete-tooltip"><FormattedMessage id="DELETE_QUESTION"></FormattedMessage></Tooltip>}
            >
                <div
                    className="btn btn-icon btn-sm btn-light btn-hover-danger ml-3"
                    onClick={() => openDeleteQuestionDialog(row.id)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                        />
                    </span>
                </div>
            </OverlayTrigger>
        </Fragment>
    )
}