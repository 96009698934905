import axios from 'axios'

export const APIURL = process.env.REACT_APP_API_ENDPOINT

export const SEND_EMAIL_URL = '/api/sendEmail'
export const DOWNLOAD_CSV_URL = '/api/downloadCSV'

export const UPDATE_STATUS_URL = '/api/takenQuiz/updateStatus'
export const ANSWER_ALL_URL = '/api/takeQuiz/answerAll'
export const ANSWER_ONE_URL = '/api/takeQuiz/answerOne'
export const START_QUIZ_URL = '/api/takeQuiz/startQuiz'
export const RATE_QUIZ_URL = '/api/takeQuiz/rateQuiz'
export const GET_TAKEN_QUIZ_BY_STUDENT_ID_URL = '/api/takenQuiz/fetchAllByStudentId'
export const GET_TAKEN_QUIZ_BY_TEACHER_ID_AND_QID_URL = '/api/takenQuiz/fetchAllByQuizId'
export const GET_TAKEN_QUIZ_BY_ID = '/api/takenQuiz/fetchById'
export const GET_LATEST_TAKEN_QUIZ_BY_ID = '/api/takenQuiz/fetchLatestById'
export const GET_ALL_TAKEN_QUIZ = '/api/takenQuiz/fetchAllTakenQuiz'
export const GET_ALL_TAKEN_QUIZ_NAME = '/api/takenQuiz/fetchQuizNames'

export const GET_TAKEN_QUIZ_BY_STUDENT_ID_AND_QID_URL = 'GET_TAKEN_QUIZ_BY_STUDENT_ID_AND_QID_URL'

export function sendEmail(studentName, studentEmail, subject, message) {
    return axios.post(APIURL + SEND_EMAIL_URL, { studentName, studentEmail, subject, message })
}

export function downloadCSV(id) {
    return axios.post(APIURL + DOWNLOAD_CSV_URL, { id })
}

export function startQuiz(quizId) {
    return axios.post(APIURL + START_QUIZ_URL, { quizId })
}

export function rateQuiz(data) {
    return axios.post(APIURL + RATE_QUIZ_URL, data)
}

export function updateStatus(ids,status) {
    return axios.post(APIURL + UPDATE_STATUS_URL, {ids, status})
}

export function answerAllQuestions(answers, quizId, timeTaken) {
    return axios.post(APIURL + ANSWER_ALL_URL, { answers, quizId, timeTaken })
}

export function answerOneQuestions(questionId, optionType, answer) {
    return axios.post(APIURL + ANSWER_ONE_URL, { questionId, optionType, answer })
}

export function getTakenQuizByStudentId(queryParams) {
    return axios.post(APIURL + GET_TAKEN_QUIZ_BY_STUDENT_ID_URL, { queryParams })
}

export function getTakenQuizByTeacherIdAndQId(quizId, queryParams) {
    return axios.post(APIURL + GET_TAKEN_QUIZ_BY_TEACHER_ID_AND_QID_URL, { quizId, queryParams })
}

export function getAllTakenQuiz(queryParams) {
    return axios.post(APIURL + GET_ALL_TAKEN_QUIZ, { queryParams })
}

export function getTakenQuizById(takenQuizId) {
    return axios.post(APIURL + GET_TAKEN_QUIZ_BY_ID, { takenQuizId })
}

export function getLatestTakenQuizById(takenQuizId) {
    return axios.post(APIURL + GET_LATEST_TAKEN_QUIZ_BY_ID, { takenQuizId })
}

export function getTakenQuizByStudentIdAndQId(sId, qId) {
    return axios.post(GET_TAKEN_QUIZ_BY_STUDENT_ID_AND_QID_URL, { sId, qId })
}

export function getAllTakenQuizName() {
    return axios.get(APIURL + GET_ALL_TAKEN_QUIZ_NAME)
}
