import * as requestFromServer from './subscriptionsCrud'
import { subscriptionsSlice, callTypes } from './subscriptionsSlice'

const { actions } = subscriptionsSlice

export const fetchAllSubscriptions = () => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllSubscriptions()
		if (response.status !== 200) throw response
		const { totalCount, entities } = response.data.data
		dispatch(actions.subscriptionsFetched({ totalCount, entities }))
	} catch (response) {
		let error = response?.data?.message || "Can't get subscriptions"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const deleteSubscription = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.deleteSubscription(id)
		if (response.status !== 200) throw response
		dispatch(actions.subscriptionDeleted({ id }))
	} catch (response) {
		let error = response?.data?.message || "Can't delete subscription"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const createSubscription = (subscriptionForCreation) => async (
	dispatch,
) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.createSubscription(
			subscriptionForCreation,
		)
		if (response.status !== 200) throw response
		const { data } = response.data
		dispatch(actions.subscriptionCreated({ data }))
	} catch (response) {
		console.log(response)
		let error = response?.data?.message || "Can't create subscription"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateSubscription = (subscription) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateSubscription(subscription)
		if (response.status !== 200) throw response
		dispatch(actions.subscriptionUpdated({ subscription }))
	} catch (response) {
		let error = response?.data?.message || "Can't update subscription"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateStatus = (data) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateSubscription(data)
		if (response.status !== 200) throw response
		dispatch(actions.statusUpdated(data))
	} catch (response) {
		let error = response?.data?.message || "Can't update subscription"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
export const updateAutoCharge = (data) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateSubscription(data)
		if (response.status !== 200) throw response
		dispatch(actions.autoChargeUpdated(data))
	} catch (response) {
		let error = response?.data?.message || "Can't update subscription"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
