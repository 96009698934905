import React, { Fragment, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"

import { RadioGroup, Radio, Checkbox, FormGroup, FormControlLabel, FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { getSignedUrl } from '../../../../../../../../_metronic/_helpers'

const useStyles = makeStyles((theme) => ({
    group: {
        margin: theme.spacing(1, 0),
    },
}))

const Question = ({ data, current, answerData, answeringRadio, answeringCheckbox }) => {
    const classes = useStyles()

    const [o, setO] = useState({ data: null })

    useEffect(() => {
        setO({ data: answerData.data[current].value })
    }, [answeringRadio])

    return o.data !== null ? (
        <Fragment>
            <label className='font-size-h6 font-weight-bolder text-dark mb-0'>{data.questionText}</label>
            {
              data.questionImage ?
              <div className="col-12 my-2 px-0"><img className="questionImage" alt='Question' src={getSignedUrl(data.questionImage)} /></div>
              : ''
            }
            {data.optionType === "radio" ? (
                <Fragment>
                    <RadioGroup
                        name={`answer${current}`}
                        className={classes.group}
                        value={o.data}
                        onChange={(e) => answeringRadio(e)}
                    >
                        {data.options.map((option, index) => (
                            <FormControlLabel
                                value={option.optionText}
                                key={index}
                                control={<Radio color='primary' />}
                                label={option.optionText}
                            />
                        ))}
                    </RadioGroup>
                </Fragment>
            ) : data.optionType === "checkbox" ? (
                <Fragment>
                    <FormGroup>
                        {data.options.map((option, index) => (
                            <FormControlLabel
                                onChange={(e) => answeringCheckbox(e)}
                                control={<Checkbox color='primary' />}
                                value={option.optionText}
                                checked={o.data.includes(option.optionText)}
                                label={option.optionText}
                                key={index}
                            />
                        ))}
                    </FormGroup>
                    <FormHelperText className='text-dark'>
                        <FormattedMessage id='SELECT_RIGHT_ONES'></FormattedMessage>
                    </FormHelperText>
                </Fragment>
            ) : (
                <Fragment></Fragment>
            )}
        </Fragment>
    ) : (
        <Fragment></Fragment>
    )
}

Question.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Question
