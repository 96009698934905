import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import {
	LayoutSplashScreen,
	ContentRoute,
} from '../../../../../_metronic/layout'

import DashboardPage from './dashboard/DashboardPage'
import FeedbackPage from './feedback/FeedbackPage'
import QuizPoolPage from './quizPool/QuizPoolPage'
// import QuizAddByQuestionPage from './quizPool/quiz-add-by-question/QuizAddByQuestionPage'
// import QuizAddByQuizPage from './quizPool/quiz-add-by-quiz/QuizAddByQuizPage'
// import QuizEditPage from './quizPool/quiz-edit-page/QuizEditPage'
import QuizViewPage from './quizPool/quiz-view-page/QuizViewPage'
import QuizViewStatsPage from './quizPool/quiz-view-stats-page/QuizViewStatsPage'
import QuizStatsDownloadDialog from './quizPool/quiz-stats-download-dialog/QuizStatsDownloadDialog'
import QuizEmailStudentDialog from './quizPool/quiz-email-student-dialog/QuizEmailStudentDialog'

import QuestionPage from './questions/QuestionPage'
import QuestionAddPage from './questions/question-add-page/QuestionAddPage'
import QuestionEditPage from './questions/question-edit-page/QuestionEditPage'

const TeacherPage = () => {
	const { userType } = useSelector(
		(state) => ({
			userType: state.auth.user.userType,
		}),
		shallowEqual,
	)

	return userType !== 'Teacher' ? (
		<Redirect to='/' />
	) : (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact={true} from='/teacher' to='/teacher/quizPool' />}
				<ContentRoute path='/teacher/dashboard' component={DashboardPage} />
				<ContentRoute path='/teacher/feedback' component={FeedbackPage} />
				<ContentRoute path='/teacher/quizPool' component={QuizPoolPage} />

				{/* <ContentRoute path='/teacher/questions/:category/view2' component={QuestionPage} /> */}

				<ContentRoute
					path='/teacher/question/:category/:course/new'
					component={QuestionAddPage}
				/>
				<ContentRoute
					path='/teacher/question/:id/edit'
					component={QuestionEditPage}
				/>
				<ContentRoute path='/teacher/questions' component={QuestionPage} />
				{/* <ContentRoute path='/teacher/quiz/new' component={QuizAddByQuestionPage} />
                    <ContentRoute path='/teacher/quiz/newQuiz' component={QuizAddByQuizPage} />
                    <ContentRoute path='/teacher/quiz/:id/edit' component={QuizEditPage} /> */}
				<ContentRoute path='/teacher/quiz/:id/view' component={QuizViewPage} />
				<ContentRoute
					path='/teacher/quiz/:id/viewStats'
					component={QuizViewStatsPage}
				/>
				<ContentRoute
					path='/teacher/quiz/:id/:qId/download'
					component={QuizStatsDownloadDialog}
				/>
				<ContentRoute
					path='/teacher/quiz/:id/:qId/email'
					component={QuizEmailStudentDialog}
				/>
				<Redirect to='../error/error-v2' />
			</Switch>
		</Suspense>
	)
}

export default TeacherPage
