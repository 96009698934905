import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Swal from 'sweetalert2'
import {
	CardHeader,
	CardHeaderToolbar,
	CardBody,
} from '../../../../../../../_metronic/_partials/controls'
import { Checkbox } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
	Button,
	Modal,
	OverlayTrigger,
	Tooltip,
	Spinner,
} from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'
import _ from 'lodash'
import * as actions from '../../../../_redux/question/questionAction'
import { questionSlice } from '../../../../_redux/question/questionSlice'
import QuestionAddPage from '../../questions/question-add-page/QuestionAddPage'
import Tagify from './tagify'
const question_slice = questionSlice.actions

const QuestionBasedList2 = ({
	pageLoadTime,
	setQuestions,
	selectedQuestions,
	setSelectedQuestions,
	category,
	course,
	totalQuestion,
	saveQuizAllDetails,
}) => {
	const [tags, setTags] = useState()
	useEffect(() => {
		if (tags) {
			const tag_map = JSON.parse(tags)
			const new_tags = tag_map.map((tag) => tag.value)
			fetchRandomQuestions(new_tags)
		}
	}, [tags])
	// Getting curret state of quiz list from store (Redux)
	const { currentState, quizActionLoading } = useSelector(
		(state) => ({
			currentState: state.question,
			quizActionLoading: state.quiz.actionsLoading,
		}),
		shallowEqual,
	)

	const { entities } = currentState

	// quiz Redux state
	const dispatch = useDispatch()

	const [qList, setQList] = useState(null)

	const resetList = () => {
		if (entities !== null) {
			setQList(entities)
		}
	}

	const addquestionparams = { params: { category, course } }

	const [alertStatus, setAlertStatus] = useState(false)
	const [showaddquestionmodal, showAddQuestionModal] = useState(false)
	const [hidePrevious, setHidePrevious] = useState(false)

	const refreshQuestionsList = () => {
		//a new question is added, refresh the questions list
		showAddQuestionModal(false)
		const filter = {
			pageLoadTime,
			hidePrevious,
			category,
			course,
			totalQuestion,
		}
		dispatch(actions.randomQuestion(filter))
	}

	const onQsRemove = (qId) => {
		const newList = qList.filter((q) => q.id !== qId)
		if (newList.length === 0) {
			setAlertStatus(true)
			setTimeout(() => {
				setAlertStatus(false)
			}, 4000)
		} else {
			setQList(newList)
		}
	}

	const setSelectedQuestionsHandler = (event, qid) => {
		let sel = _.cloneDeep(selectedQuestions)

		if (event.target.checked) {
			sel.push(qid)

			if (sel.length > parseInt(totalQuestion)) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Total questions cannot be more than ' + totalQuestion,
				})

				return
			}
		} else {
			sel.splice(sel.indexOf(qid), 1)
		}

		setSelectedQuestions(sel)
	}

	// function shuffleArray(array) {
	//     for (let i = array.length - 1; i > 0; i--) {
	//         const j = Math.floor(Math.random() * (i + 1));
	//         [array[i], array[j]] = [array[j], array[i]];
	//     }
	// }

	useEffect(() => {
		//initially make empty
		let totalCount = 0
		let entities = null
		dispatch(question_slice.questionsFetched({ totalCount, entities }))
		fetchRandomQuestions()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	const fetchRandomQuestions = (tags_filter = null) => {
		const filter = {
			pageLoadTime,
			hidePrevious,
			category,
			course,
			totalQuestion,
		}
		if (tags_filter) filter.tags = tags_filter
		dispatch(actions.randomQuestion(filter))
	}

	useEffect(() => {
		fetchRandomQuestions()
	}, [hidePrevious])

	useEffect(() => {
		if (entities !== null) {
			setQList(entities)
		}
	}, [entities])

	useEffect(() => {
		if (qList) {
			setQuestions(qList)
		}
	}, [qList])

	return (
		<Fragment>
			<Modal
				size='lg'
				show={showaddquestionmodal}
				onHide={showAddQuestionModal}
			>
				<Modal.Body>
					<QuestionAddPage
						refreshQuestionsList={refreshQuestionsList}
						openedinmodal={true}
						match={addquestionparams}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='primary'
						onClick={() => {
							showAddQuestionModal(!showaddquestionmodal)
						}}
					>
						<FormattedMessage id='CLOSE'></FormattedMessage>
					</Button>
				</Modal.Footer>
			</Modal>

			<CardHeader
				title={`Question in Category >> ${category}, Course >> ${course}`}
			>
				<CardHeaderToolbar>
					<Button
						variant='secondary'
						size='sm'
						className='ml-3'
						onClick={() => resetList()}
					>
						<span className='svg-icon svg-icon-md svg-icon-dark'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Media/Repeat.svg')} />
						</span>
						<FormattedMessage id='RESET_LIST'></FormattedMessage>
					</Button>
					<Button
						variant='primary'
						size='sm'
						className='ml-3'
						onClick={() => showAddQuestionModal(!showaddquestionmodal)}
					>
						<span className='svg-icon svg-icon-md svg-icon-white'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
						</span>
						<FormattedMessage id='CREATE_QUESTION'></FormattedMessage>
					</Button>
					<Button
						variant='primary'
						size='sm'
						className='ml-3'
						onClick={() => setHidePrevious(!hidePrevious)}
					>
						{hidePrevious ? (
							<FormattedMessage id='SHOW_PREVIOUS'></FormattedMessage>
						) : (
							<FormattedMessage id='HIDE_PREVIOUS'></FormattedMessage>
						)}
					</Button>
					<Tagify getTags={setTags} />
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody className='d-flex flex-column'>
				{alertStatus && (
					<Alert
						severity='error'
						onClose={() => {
							setAlertStatus(false)
						}}
						className='mb-4'
					>
						Atleast one question should remain!
					</Alert>
				)}
				{qList !== null && qList.length !== 0 ? (
					<Fragment>
						{qList.map((q, index) => (
							<Fragment key={index}>
								<div className='d-flex align-items-center justify-content-between mb-2 pb-2 border-secondary border-bottom'>
									<p style={style.qMaxWidth}>
										<Checkbox
											className='mr-2'
											checked={
												selectedQuestions.indexOf(q.id) > -1 ? true : false
											}
											onChange={(event) =>
												setSelectedQuestionsHandler(event, q.id)
											}
											value='checkedA'
											inputProps={{
												'aria-label': 'Select',
											}}
										/>
										{q.questionText}
									</p>
									<OverlayTrigger
										overlay={
											<Tooltip>
												<FormattedMessage id='REMOVE_QUESTION'></FormattedMessage>
											</Tooltip>
										}
									>
										<div
											className='btn btn-icon btn-sm btn-light btn-hover-danger'
											onClick={() => onQsRemove(q.id)}
										>
											<span className='svg-icon svg-icon-md svg-icon-danger'>
												<SVG
													src={toAbsoluteUrl(
														'/media/svg/icons/General/Trash.svg',
													)}
												/>
											</span>
										</div>
									</OverlayTrigger>
								</div>
							</Fragment>
						))}
						<p className='text-muted my-2'>
							<FormattedMessage id='REQUIRED_NO_OF_QUESTION_ARE_LESS'></FormattedMessage>
							.
						</p>
						<Button
							id='checklast'
							className='d-none'
							variant='primary'
							style={style.btn}
							onClick={() => saveQuizAllDetails()}
						>
							<div className='d-flex align-items-center'>
								<FormattedMessage id='CREATE_QUIZ'></FormattedMessage>
								{quizActionLoading && (
									<Spinner
										animation='border'
										className='ml-2'
										variant='light'
									/>
								)}
							</div>
						</Button>
					</Fragment>
				) : qList !== null && qList.length === 0 ? (
					<Fragment>
						<span className='svg-icon svg-icon-lg svg-icon-dark'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/General/Sad.svg')} />
						</span>
						<h6>
							<FormattedMessage id='NO_QUESTION_FOUND'></FormattedMessage>
						</h6>
					</Fragment>
				) : (
					<Fragment>
						<div className='text-center p-2'>
							<Spinner animation='border' variant='dark' />
						</div>
					</Fragment>
				)}
			</CardBody>
		</Fragment>
	)
}

const style = {
	qMaxWidth: {
		margin: '0 30px 0 0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	btn: {
		width: 'fit-content',
		marginTop: '.5rem',
	},
}

export default QuestionBasedList2
