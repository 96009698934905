import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/_helpers'

export const ActionsColumnFormatter = (
	cellContent,
	row,
	rowIndex,
	{ quizTakePage },
) => {
	return (
		<Fragment>
			<OverlayTrigger
				overlay={
					<Tooltip id='quiz-view-tooltip'>
						<FormattedMessage id='TAKE_QUIZ'></FormattedMessage>
					</Tooltip>
				}
			>
				<div
					className='btn btn-icon btn-sm btn-light btn-hover-primary'
					onClick={() => quizTakePage(row.quizId)}
				>
					<span className='svg-icon svg-icon-md svg-icon-primary'>
						<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Edit.svg')} />
					</span>
				</div>
			</OverlayTrigger>
		</Fragment>
	)
}
