import React, { useState } from "react"
import { useDispatch } from "react-redux"

import * as actions from "../../../.././../_redux/feedback/feedbackAction"
import { useIntl, FormattedMessage } from 'react-intl'
import { Modal, Button } from "react-bootstrap"
import { FormControl, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
}))

const QuizFeedbackModal = ({ showFeedBack, onHide, question,questions }) => {
    const intl = useIntl()
    const classes = useStyles()
    const [feedback, setFeedback] = useState("")

    const [selectedquestionforfeedback, setSelectedQuestionForFeedback] = useState(question.hasOwnProperty("id") ? question.id : '')

    // quiz Redux state
    const dispatch = useDispatch()

    const submitFeedback = () => {
        dispatch(actions.addFeedback(selectedquestionforfeedback, feedback))
        setFeedback("")
        onHide()
    }

    return (
        <Modal
            show={showFeedBack}
            onHide={onHide}
            keyboard={false}
            backdrop='static'
            aria-labelledby='example-modal-sizes-title-lg'
        >
            <Modal.Header closeButton>
                <Modal.Title id='example-modal-sizes-title-lg'>
                    <FormattedMessage id="PROVIDE_FEEDBACK"></FormattedMessage>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <FormattedMessage id="QUESTION"></FormattedMessage>
                </h5>
                <select onChange={e => setSelectedQuestionForFeedback(e.target.value)} value={selectedquestionforfeedback} className="form-control">
                    <option value="">{intl.formatMessage({ id: "SELECT_ONE" })}</option>
                    {
                        questions.map(function(item, i){
                            return <option key={i} value={item.id}>{item.questionText}</option>
                        })
                    }
                </select>
                {/* <h6>Options</h6>
                {
                    question.options.map((o, i) => <p key={i}>{o.optionText}</p>)
                } */}
                <FormControl className={classes.formControl}>
                    <TextField
                        label='Feedback information'
                        variant='outlined'
                        multiline
                        rows={5}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </FormControl>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={() => {
                        setFeedback("")
                        onHide()
                    }}
                >
                    Close
                </Button>
                <Button variant='primary' onClick={() => submitFeedback()}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QuizFeedbackModal
