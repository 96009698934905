import React, { Fragment, useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import {
	FormControl,
	InputLabel,
	MenuItem,
	TextField,
	Select,
	OutlinedInput,
	RadioGroup,
	Radio,
	Checkbox,
	FormGroup,
	FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Button, Spinner } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import Alert from '@material-ui/lab/Alert'
import {
	toAbsoluteUrl,
	getSignedUrl,
} from '../../../../../../../_metronic/_helpers'

import AWS from '../../../../../../../s3config'

import * as actions from '../../../../_redux/question/questionAction'
import * as actions2 from '../../../../_redux/categoryCourse/categoryCourseAction'
import Tagify from '../question-add-page/tagify'

const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		margin: theme.spacing(1, 0),
	},
}))

const QuestionEditPage = ({
	history,
	match: {
		params: { id },
	},
}) => {
	const classes = useStyles()
	const intl = useIntl()
	const [noOfOptions, setNoOfOptions] = useState(5)
	const [tags, setTags] = useState()

	const [questionData, setQuestionData] = useState({
		id: '',
		questionText: '',
		category: '',
		course: '',
		optionType: '',
		options: [],
		answerExplaination: null,
		image: null,
		questionImage: null,
		tags: '',
	})
	const {
		questionText,
		category,
		course,
		optionType,
		options,
		answerExplaination,
		image,
		questionImage,
	} = questionData

	useEffect(() => {
		if (tags) {
			const tag_map = JSON.parse(tags)
			const new_tags = tag_map.map((tag) => tag.value)
			setQuestionData({ ...questionData, tags: new_tags })
		}
	}, [tags])
	const getTags = (tags) => {
		setTags(tags)
	}
	const handleChange = (e) => {
		setQuestionData({ ...questionData, [e.target.name]: e.target.value })
	}

	const handleCKEditorExplainationChange = (data) => {
		setQuestionData({ ...questionData, answerExplaination: data })
	}

	const resetOptions = () => {
		;[...Array(options.length)].map((e, i) => questionData.options.pop())
		;[...Array(5)].map((e, i) =>
			questionData.options.push({
				// text: 'Option ' + (i + 1),
				text: '',
				status: false,
			}),
		)
	}

	const clearOptionChecks = () => {
		setQuestionData({ ...questionData, options: questionData.options })
	}

	// useEffect(() => {
	//     // resetOptions()
	// }, [noOfOptions])

	const handleOptionTextChange = (e, i) => {
		let tempquestionData = questionData
		let options = questionData.options
		options[i].optionText = e.target.value
		tempquestionData.options = options
		setQuestionData({ ...questionData, options: tempquestionData.options })
	}

	const handleOptionStatusChange = (e, i, oT) => {
		if (oT === 'radio') {
			let tempquestionData = questionData
			let options = questionData.options
			options[i].optionStatus = e.target.checked
			tempquestionData.options = options
			setQuestionData({ ...questionData, options: tempquestionData.options })
			questionData.options.map((o, index) => {
				if (index === i)
					questionData.options[index].optionStatus = e.target.checked
				else questionData.options[index].optionStatus = false
			})

			// setQuestions(questions)
		} else {
			let tempquestionData = questionData
			let options = questionData.options
			options[i].optionStatus = e.target.checked
			tempquestionData.options = options
			setQuestionData({ ...questionData, options: tempquestionData.options })
			// questionData.options[i].status = e.target.checked
			// setQuestions(questions)
		}
	}

	const dispatch = useDispatch()

	const { actionsLoading, currentQuestion, categoryList } = useSelector(
		(state) => ({
			actionsLoading: state.question.actionsLoading,
			currentQuestion: state.question.currentQuestion,
			categoryList: state.categoryCourse.entities,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(actions.fetchQuestionById(id, history))
		const queryParams = {
			filter: {
				category: '',
			},
			sortOrder: 'asc', // asc||desc
			sortField: 'category',
			pageNumber: 1,
			pageSize: 100,
		}
		dispatch(actions2.fetchAllCategoryCourse(queryParams))
		// if (categoryList) console.log(categoryList)
	}, [id, dispatch])

	useEffect(() => {
		if (currentQuestion) {
			// console.log(currentQuestion.options)
			const newOptions = _.cloneDeep(currentQuestion.options)
			for (let index = 0; index < 5 - currentQuestion.options.length; index++) {
				newOptions.push({
					// text: 'Option text ' + (currentQuestion.options.length + index + 1),
					text: '',
					status: false,
				})
			}
			// console.log(newOptions)
			setNoOfOptions(currentQuestion.options.length)
			setQuestionData({
				id: currentQuestion.questionId,
				questionText: currentQuestion.questionText,
				category: currentQuestion.questionCategory,
				course: currentQuestion.questionCourse,
				optionType: currentQuestion.optionType,
				answerExplaination: currentQuestion.answerExplaination,
				image: currentQuestion.image,
				questionImage: currentQuestion.questionImage,
				options: newOptions,
			})
			// setOldOptions(currentQuestion)
		}
	}, [currentQuestion])

	useEffect(() => {
		if (categoryList) onCategoryChange(category)
	}, [categoryList, category])

	const [courList, setCourList] = useState({ data: [] })

	const onCategoryChange = (value) => {
		const obj = categoryList.find((c) => c.category === value)
		if (obj) {
			setCourList({ data: obj.course })
		} else {
			setCourList({ data: [] })
		}
	}

	const emptyCourse = (e) => {
		setQuestionData({ ...questionData, category: e.target.value, course: '' })
	}

	// const setOldOptions = (data) => {
	// let newOptions = _.cloneDeep(data.options);
	// console.log(newOptions);
	// for (let index = 0; index < 5 - newOptions.length + 1; index++) {
	//     newOptions.push({
	//         text: 'Option text ' + (newOptions.length + index + 1),
	//         status: false
	//     })
	// }
	// console.log(newOptions)
	// console.log(data)
	// setQuestionData({
	//     id: data.id,
	//     questionText: data.questionText,
	//     category: data.category,
	//     course: data.course,
	//     optionType: data.optionType,
	//     answerExplaination: data.answerExplaination,
	//     options: newOptions
	// })
	// }

	const onSubmit = async (e) => {
		e.preventDefault()
		setProcessing(true)
		questionData.options = questionData.options.splice(0, noOfOptions)
		// questionData.options = currentQuestion.options;
		if (!checkEmpty()) {
			let bool = false
			questionData.options.some((o, i) => {
				if (i < noOfOptions) {
					bool = false
					if (o.optionText === '') {
						bool = false
						return true
					} else {
						bool = true
					}
				}
			})
			if (bool) {
				let checked = false
				let num = 0
				if (optionType === 'radio') {
					questionData.options.map((o) => {
						if (o.optionStatus) {
							checked = true
						}
					})
				}
				if (optionType === 'checkbox') {
					questionData.options.map((o) => {
						if (o.optionStatus) {
							num++
						}
					})
					if (num > 1 && num < questionData.options.length) {
						checked = true
					}
				}
				if (checked) {
					// questionData.options = questionData.options.splice(0, noOfOptions)
					questionData.questionId = questionData.id
					delete questionData.id

					if (photo) {
						//First uploading images
						let extension = photo.type.split('/')[1]
						let key = Math.floor(new Date().getTime()) + '.' + extension
						let path = process.env.REACT_APP_S3_BUCKET_FOLDER_NAME + '/' + key

						let s3 = new AWS.S3()
						let params = {
							Bucket: process.env.REACT_APP_S3_BUCKET,
							Key: path,
							Body: photo,
							ACL: 'public-read',
						}

						let data = await s3.upload(params).promise()
						let completeKey = data.Key
						questionData.image = completeKey
					}

					if (questionPhoto) {
						//First uploading images
						let extension = questionPhoto.type.split('/')[1]
						let key = Math.floor(new Date().getTime()) + '.' + extension
						let path = process.env.REACT_APP_S3_BUCKET_FOLDER_NAME + '/' + key

						let s3 = new AWS.S3()
						let params = {
							Bucket: process.env.REACT_APP_S3_BUCKET,
							Key: path,
							Body: questionPhoto,
							ACL: 'public-read',
						}

						let data = await s3.upload(params).promise()
						let completeKey = data.Key
						questionData.questionImage = completeKey
					}

					dispatch(
						actions.updateQuestion(currentQuestion, history, questionData),
					)
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' })
					setAlertMessage(
						intl.formatMessage({ id: 'ALERT.OPTION_CHECKS_EMPTY' }),
					)
					setAlertStatus(true)
					setTimeout(() => {
						setAlertStatus(false)
					}, 4000)
				}
			} else {
				window.scrollTo({ top: 0, behavior: 'smooth' })
				setAlertMessage(intl.formatMessage({ id: 'ALERT.OPTION_VALUES_EMPTY' }))
				setAlertStatus(true)
				setTimeout(() => {
					setAlertStatus(false)
				}, 4000)
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			setAlertMessage(intl.formatMessage({ id: 'ALERT.FIELDS_EMPTY' }))
			setAlertStatus(true)
			setTimeout(() => {
				setAlertStatus(false)
			}, 4000)
		}
	}

	const onImageChange = (event, type) => {
		if (event.target.files.length) {
			let file = event.target.files[0]
			if (!event.target.files.length) {
				// document.getElementById('top').scrollIntoView(true)
				// setAlert('No file attached', 'danger')
			} else {
				let extension = file.type.split('/')[1]
				if (
					extension === 'jpg' ||
					extension === 'jpeg' ||
					extension === 'png' ||
					extension === 'jfif'
				) {
					if (type === 'question') {
						setQuestionPhoto(file)
					} else {
						setPhoto(file)
					}

					// if (file.size > process.env.REACT_APP_FILE_UPLOAD_SIZE) {
					// 	document.getElementById('top').scrollIntoView(true)
					// 	setAlert('File too large', 'danger')
					// } else {

					// }
				} else {
					// document.getElementById('top').scrollIntoView(true)
					// setAlert('Invalid file type', 'danger')
				}
			}
		} else {
			// document.getElementById('top').scrollIntoView(true)
			// setAlert('No file attached', 'danger')
		}
	}

	const checkEmpty = () => {
		if (
			questionText !== '' &&
			category !== '' &&
			course !== '' &&
			optionType !== '' &&
			answerExplaination !== ''
		)
			return false
		else return true
	}

	const [alertStatus, setAlertStatus] = useState(false)
	const [photo, setPhoto] = useState(null)
	const [questionPhoto, setQuestionPhoto] = useState(null)
	const [processing, setProcessing] = useState(false)
	const [alertMessage, setAlertMessage] = useState('Something wrong!')

	const goBack = () => {
		history.push('/teacher/questions')
	}

	return (
		<Card>
			<CardHeader
				title={<FormattedMessage id='EDIT_QUESTION'></FormattedMessage>}
			>
				<CardHeaderToolbar>
					<Button variant='secondary' size='sm' onClick={() => goBack()}>
						<span className='svg-icon svg-icon-md svg-icon-dark'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Navigation/Arrow-left.svg',
								)}
							/>
						</span>
						<FormattedMessage id='GO_BACK'></FormattedMessage>
					</Button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody className='d-flex flex-column'>
				{actionsLoading && (
					<div className='text-center p-2'>
						<Spinner animation='border' variant='dark' />
					</div>
				)}
				{!actionsLoading && currentQuestion && (
					<Fragment>
						{alertStatus && (
							<Alert
								severity='error'
								onClose={() => {
									setAlertStatus(false)
								}}
								className='mb-4'
							>
								{alertMessage}
							</Alert>
						)}
						{currentQuestion.used && (
							<p className='text text-warning mb-5'>
								<i className='fas fa-exclamation mr-2 text-warning'></i>
								Question has been used in some quizes
							</p>
						)}
						<div className='form-group row mb-0'>
							<div className='col-12'>
								{questionImage && !questionPhoto && (
									<img
										className='questionImage mt-4'
										src={getSignedUrl(questionImage)}
										alt='explaination'
									/>
								)}
								{questionPhoto && (
									<img
										className='questionImage mt-4'
										src={URL.createObjectURL(questionPhoto)}
										alt='explaination'
									/>
								)}
							</div>
							<div className='col-12'>
								{questionImage && !questionPhoto && (
									<Fragment>
										<Button
											variant='primary'
											className='my-4'
											onClick={() =>
												document.getElementById('questionFile').click()
											}
										>
											<span className='svg-icon svg-icon-md'>
												<SVG
													src={toAbsoluteUrl(
														'/media/svg/icons/Design/Image.svg',
													)}
												/>
											</span>
											<FormattedMessage id='CHANGE_IMAGE'></FormattedMessage>
										</Button>
										<input
											accept='.jpg,.jpeg,.png,.jfif'
											onChange={(event) => onImageChange(event, 'question')}
											type='file'
											id='questionFile'
											style={{ display: 'none' }}
										/>
									</Fragment>
								)}
								{questionPhoto && (
									<Button
										variant='primary'
										className='my-4'
										onClick={() => setQuestionPhoto(null)}
									>
										<span className='svg-icon svg-icon-md'>
											<SVG
												src={toAbsoluteUrl(
													'/media/svg/icons/General/Trash.svg',
												)}
											/>
										</span>
										<FormattedMessage id='CLEAR_IMAGE'></FormattedMessage>
									</Button>
								)}
							</div>
							<div className='col-lg-6'>
								<FormControl className={classes.formControl}>
									<TextField
										label={<FormattedMessage id='QUESTION'></FormattedMessage>}
										variant='outlined'
										multiline
										value={questionText}
										onChange={(e) => handleChange(e)}
										name='questionText'
									/>
								</FormControl>
							</div>
							<div className='col-lg-6 mt-2'>
								<Tagify
									getTags={getTags}
									defaultValues={currentQuestion.tags}
								/>
							</div>
						</div>
						<div className='form-group row mb-0'>
							<div className='col-lg-6'>
								<FormControl variant='outlined' className={classes.formControl}>
									<InputLabel>
										<FormattedMessage id='CATEGORY'></FormattedMessage>
									</InputLabel>
									{/* <Select
                                        value={category}
                                        input={<OutlinedInput labelWidth={90} name="category" onChange={e => handleChange(e)} />}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {AVAILABLE_CATEGORIES.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                    </Select> */}
									<Select
										value={category}
										input={
											<OutlinedInput
												labelWidth={90}
												name='category'
												onChange={(e) => {
													handleChange(e)
													emptyCourse(e)
												}}
											/>
										}
									>
										<MenuItem value=''>
											<em>None</em>
										</MenuItem>
										{categoryList &&
											categoryList.map((a) => (
												<MenuItem key={a.id} value={a.category}>
													{a.category}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</div>
							<div className='col-lg-6'>
								<FormControl variant='outlined' className={classes.formControl}>
									<InputLabel>
										<FormattedMessage id='COURSE'></FormattedMessage>
									</InputLabel>
									<Select
										value={course}
										input={
											<OutlinedInput
												labelWidth={50}
												name='course'
												onChange={(e) => handleChange(e)}
											/>
										}
									>
										<MenuItem value=''>
											<em>None</em>
										</MenuItem>
										{courList.data.map((a) => (
											<MenuItem key={a} value={a}>
												{a}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>
						<div className='form-group row mb-0'>
							<div className='col-lg-6'>
								<FormControl variant='outlined' className={classes.formControl}>
									<InputLabel>
										<FormattedMessage id='ANSWER_TYPE'></FormattedMessage>
									</InputLabel>
									<Select
										input={
											<OutlinedInput
												labelWidth={80}
												name='optionType'
												value={optionType}
												onChange={(e) => {
													handleChange(e)
													resetOptions()
												}}
											/>
										}
									>
										<MenuItem value=''>
											<em>None</em>
										</MenuItem>
										<MenuItem value={'radio'}>Radio</MenuItem>
										<MenuItem value={'checkbox'}>Checkbox</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-lg-6'>
								<FormControl variant='outlined' className={classes.formControl}>
									<InputLabel>
										<FormattedMessage id='NUMBER_OPTION'></FormattedMessage>
									</InputLabel>
									<Select
										input={
											<OutlinedInput
												labelWidth={80}
												name='noOfOptions'
												value={noOfOptions}
												onChange={(e) => {
													setNoOfOptions(e.target.value)
													// resetOptions()
													clearOptionChecks()
												}}
											/>
										}
									>
										<MenuItem value={2}>2</MenuItem>
										<MenuItem value={3}>3</MenuItem>
										<MenuItem value={4}>4</MenuItem>
										<MenuItem value={5}>5</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-lg-6'></div>
						</div>
						{optionType === 'radio' ? (
							<Fragment>
								<p className='text-muted'>
									<FormattedMessage id='RADIO_HELPER_TEXT'></FormattedMessage>
								</p>
								<RadioGroup aria-label='quiz'>
									{[...Array(noOfOptions)].map((e, i) => (
										<FormControlLabel
											key={i}
											control={
												<Radio
													checked={questionData.options[i].optionStatus}
													color='primary'
													name='optionText'
													onChange={(e) =>
														handleOptionStatusChange(e, i, optionType)
													}
												/>
											}
											value={questionData.options[i].optionText}
											label={
												<TextField
													label={`${intl.formatMessage({ id: 'ANSWER' })} ${i +
														1}`}
													variant='outlined'
													value={questionData.options[i].optionText}
													onChange={(e) => handleOptionTextChange(e, i)}
												/>
											}
										/>
									))}
								</RadioGroup>
							</Fragment>
						) : optionType === 'checkbox' ? (
							<Fragment>
								<p className='text-muted'>
									<FormattedMessage id='CHECKBOX_HELPER_TEXT'></FormattedMessage>
								</p>
								<FormGroup>
									{[...Array(noOfOptions)].map((e, i) => (
										<FormControlLabel
											key={i}
											control={
												<Checkbox
													checked={questionData.options[i].optionStatus}
													color='primary'
													onChange={(e) =>
														handleOptionStatusChange(e, i, optionType)
													}
												/>
											}
											label={
												<TextField
													label={`${intl.formatMessage({ id: 'ANSWER' })} ${i +
														1}`}
													variant='outlined'
													value={questionData.options[i].optionText}
													onChange={(e) => handleOptionTextChange(e, i)}
												/>
											}
										/>
									))}
								</FormGroup>
							</Fragment>
						) : (
							<></>
						)}
						{answerExplaination !== null && (
							<CKEditor
								editor={ClassicEditor}
								onReady={(editor) => {
									// You can store the "editor" and use when it is needed.
									// console.log('Editor is ready to use!', editor);
									editor.editing.view.change((writer) => {
										writer.setStyle(
											'height',
											'180px',
											editor.editing.view.document.getRoot(),
										)
									})
									// console.log(answerExplaination)
								}}
								data={answerExplaination}
								onChange={(event, editor) => {
									const data = editor.getData()
									handleCKEditorExplainationChange(data)
									// console.log({ event, editor, data });
								}}
							/>
						)}
						<div>
							{image && !photo && (
								<img
									className='questionImage mt-4'
									src={getSignedUrl(image)}
									alt='explaination'
								/>
							)}
							{photo && (
								<img
									className='questionImage mt-4'
									src={URL.createObjectURL(photo)}
									alt='explaination'
								/>
							)}
						</div>
						<div>
							{image && !photo && (
								<Fragment>
									<Button
										variant='primary'
										className='my-4'
										onClick={() => document.getElementById('file').click()}
									>
										<span className='svg-icon svg-icon-md'>
											<SVG
												src={toAbsoluteUrl('/media/svg/icons/Design/Image.svg')}
											/>
										</span>
										<FormattedMessage id='CHANGE_IMAGE'></FormattedMessage>
									</Button>
									<input
										accept='.jpg,.jpeg,.png,.jfif'
										onChange={(event) => onImageChange(event, 'answer')}
										type='file'
										id='file'
										style={{ display: 'none' }}
									/>
								</Fragment>
							)}
							{photo && (
								<Button
									variant='primary'
									className='my-4'
									onClick={() => setPhoto(null)}
								>
									<span className='svg-icon svg-icon-md'>
										<SVG
											src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
										/>
									</span>
									<FormattedMessage id='CLEAR_IMAGE'></FormattedMessage>
								</Button>
							)}
						</div>
						{/* <Fragment>
                <Button
                  variant="primary"
                  className="my-4"
                  onClick={() => document.getElementById('file').click()}
                >
                  <span className="svg-icon svg-icon-md">
                      <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Design/Image.svg")}
                      />
                  </span>
                  <FormattedMessage id="ADD_IMAGE"></FormattedMessage>
                </Button>
                <input
                  accept=".jpg,.jpeg,.png,.jfif"
                  onChange={(event) => onImageChange(event)}
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                />
              </Fragment> */}
						<Button
							variant='primary'
							className='mt-2'
							onClick={(e) => onSubmit(e)}
						>
							<FormattedMessage id='UPDATE_QUESTION'></FormattedMessage>
							{processing && (
								<Spinner animation='border' className='ml-2' variant='light' />
							)}
						</Button>
					</Fragment>
				)}
			</CardBody>
		</Card>
	)
}

export default QuestionEditPage
