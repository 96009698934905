import React, { Fragment } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../../_metronic/_partials/controls";

const DashboardPage = () => {
    return (
        <Fragment>
            <Card>
                <CardHeader title="Dashboard">
                </CardHeader>
                <CardBody>
                    <h4>This is teacher dashboard</h4>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default DashboardPage
