import React, { Fragment, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import {
	Input,
	Select,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'

import { Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'

const QuizSettingSchema = Yup.object().shape({
	time: Yup.number()
		.min(1, '1 is minimum')
		.max(100, '100 is maximum')
		.required('Time is required'),
	attemptsAllowed: Yup.number()
		.min(1, '1 is minimum')
		.max(100, '100 is maximum')
		.required('No of attempts is required'),
})

export function QuizSettingForm({
	quizData,
	saveQuizSetting,
	currentAdminSetting,
}) {
	const intl = useIntl()
	const [alertStatus, setAlertStatus] = useState(false)

	const [custom_errors, setCustomErrors] = useState([])

	return (
		<Fragment>
			<CardBody className='d-flex flex-column'>
				<Formik
					enableReinitialize={true}
					// initialValues={quizData}
					initialValues={{
						...quizData,
						quiztimetype: quizData.quiztimetype ? quizData.quiztimetype : '',
					}}
					onSubmit={(values) => {
						setCustomErrors([])
						if (
							values.allowSkip !== '' &&
							values.takeBestScore !== '' &&
							values.negativeMarking !== '' &&
							values.status !== ''
						) {
							let c_errors = []
							if (values.status === 'DateRange') {
								//check for valid start and end date

								if (!values.startDate) {
									c_errors.push('Start date is required for date range')
								}
								if (!values.endDate) {
									c_errors.push('End date is required for date range')
								}

								//start date must be <= end date
								if (values.startDate && values.endDate) {
									let st = Math.floor(
										new Date(values.startDate).getTime() / 1000,
									)
									let en = Math.floor(new Date(values.endDate).getTime() / 1000)

									values.starttimestamp = st
									values.endtimestamp = en

									if (st > en) {
										c_errors.push(
											'Start date must not be greater than end date',
										)
									}
								}
							}

							if (
								values.negativeMarking === 'Yes' &&
								values.consuctiveWrongAnswersForNegativeMarking <= 0
							) {
								c_errors.push(
									'Please provide number of wrong answers for negative marking',
								)
							}

							if (c_errors.length) {
								setCustomErrors(c_errors)

								setAlertStatus(true)
								setTimeout(() => {
									setAlertStatus(false)
								}, 4000)

								return false
							}

							//no errors, let it go
							saveQuizSetting(values)
						} else {
							let c_errors = []

							if (values.status === '') {
								c_errors.push('Quiz status is required')
							}

							if (values.allowSkip === '') {
								c_errors.push('Allow skip is required')
							}

							if (values.takeBestScore === '') {
								c_errors.push('Take Best Score is required')
							}

							if (values.negativeMarking === '') {
								c_errors.push('Negative Marking is required')
							}

							if (
								currentAdminSetting.questionTimeSettingAllowed === 'Yes' &&
								values.quiztimetype === ''
							) {
								c_errors.push('Quiz time type is required')
							}

							setCustomErrors(c_errors)

							setAlertStatus(true)
							setTimeout(() => {
								setAlertStatus(false)
							}, 4000)
						}
					}}
					validationSchema={QuizSettingSchema}
				>
					{({ handleSubmit, values }) => (
						<>
							<Form className='form form-label-right'>
								{alertStatus && (
									<Alert
										severity='error'
										onClose={() => {
											setAlertStatus(false)
										}}
										className='mb-4'
									>
										{custom_errors.map((e, index) => {
											return (
												<span key={index}>
													{e}
													<br />
												</span>
											)
										})}
									</Alert>
								)}
								<div className='form-group row'>
									<div className='mb-7 col-lg-4'>
										<Select
											name='allowSkip'
											label={
												<FormattedMessage id='ALLOW_SKIP'></FormattedMessage>
											}
										>
											<option value=''>
												{intl.formatMessage({ id: 'SELECT_ONE' })}
											</option>
											<option value='Yes'>Yes</option>
											<option value='No'>No</option>
										</Select>
									</div>
									<div className='mb-7 col-lg-4'>
										<Select
											name='takeBestScore'
											label={
												<FormattedMessage id='TAKE_BEST_SCORE'></FormattedMessage>
											}
										>
											<option value=''>
												{intl.formatMessage({ id: 'SELECT_ONE' })}
											</option>
											<option value='Yes'>Yes</option>
											<option value='No'>No</option>
										</Select>
									</div>

									<div className={`mb-7 col-lg-4`}>
										<Field
											type='number'
											name='attemptsAllowed'
											component={Input}
											placeholder={intl.formatMessage({
												id: 'ATTEMPTS_ALLOWED',
											})}
											label={
												<FormattedMessage id='ATTEMPTS_ALLOWED'></FormattedMessage>
											}
										/>
									</div>

									<div
										className='mb-7 col-lg-4'
										style={{
											display: `${currentAdminSetting.showAnswerSettingAllowed ===
												'No' && 'none'}`,
										}}
									>
										<Select
											name='showAnswer'
											label={
												<FormattedMessage id='SHOW_ANSWERS_ALLOWED'></FormattedMessage>
											}
										>
											<option value='Yes'>
												{intl.formatMessage({ id: 'YES' })}
											</option>
											<option value='No'>
												{intl.formatMessage({ id: 'NO' })}
											</option>
										</Select>
									</div>

									<div
										className='mb-7 col-lg-4'
										style={{
											display: `${currentAdminSetting.questionTimeSettingAllowed ===
												'No' && 'none'}`,
										}}
									>
										<Select
											name='quiztimetype'
											label={
												<FormattedMessage id='QUIZ_TIME_TYPE'></FormattedMessage>
											}
										>
											<option value=''>
												{intl.formatMessage({ id: 'SELECT_ONE' })}
											</option>
											<option value='Quiz'>
												{intl.formatMessage({ id: 'QUIZ' })}
											</option>
											<option value='Question'>
												{intl.formatMessage({ id: 'QUESTION' })}
											</option>
										</Select>
									</div>

									<div
										className='mb-7 col-lg-4'
										style={{
											display: `${currentAdminSetting.quizTimeSettingAllowed ===
												'No' && 'none'}`,
										}}
									>
										<Field
											type='number'
											name='time'
											component={Input}
											placeholder={intl.formatMessage({ id: 'QUIZ_TIME' })}
											label={
												<FormattedMessage id='QUIZ_TIME'></FormattedMessage>
											}
										/>
										<p className='text-muted text-sm mb-0'>
											<FormattedMessage id='TIME_HELPER_TEXT'></FormattedMessage>
											{values.quiztimetype === 'Quiz' ? (
												<FormattedMessage id='TIME_HELPER_TEXT_QUIZ'></FormattedMessage>
											) : values.quiztimetype === 'Question' ? (
												<FormattedMessage id='TIME_HELPER_TEXT_QUESTION'></FormattedMessage>
											) : (
												''
											)}
										</p>
									</div>

									<div
										className={`mb-7 col-lg-4`}
										style={{
											display: `${currentAdminSetting.negativeMarkingSettingAllowed ===
												'No' && 'none'}`,
										}}
									>
										<Select
											name='negativeMarking'
											label={
												<FormattedMessage id='NEGATIVE_MARKING'></FormattedMessage>
											}
										>
											<option value=''>
												{intl.formatMessage({ id: 'SELECT_ONE' })}
											</option>
											<option value='Yes'>Yes</option>
											<option value='No'>No</option>
										</Select>
									</div>
									<div
										className={`mb-7 col-lg-4`}
										style={{
											display: `${
												values.negativeMarking === 'Yes' ? 'block' : 'none'
											}`,
										}}
									>
										<Field
											type='number'
											name='consuctiveWrongAnswersForNegativeMarking'
											component={Input}
											placeholder={intl.formatMessage({
												id: 'CONSUCTIVE_WRONG_ANSWERS_FOR_NEGATIVE_MARKING',
											})}
											label={
												<FormattedMessage id='CONSUCTIVE_WRONG_ANSWERS_FOR_NEGATIVE_MARKING'></FormattedMessage>
											}
										/>
									</div>
									{/* <div
                                        className={`mb-7 col-lg-6`}
                                        style={{ display: `${currentAdminSetting.negativeMarkingSettingAllowed === 'No' && 'none'}` }}
                                    >   
                                        <Field
                                            type="number"
                                            name="consuctiveWrongAnswersForNegativeMarking"
                                            component={Input}
                                            placeholder={intl.formatMessage({ id: "CONSUCTIVE_WRONG_ANSWERS_FOR_NEGATIVE_MARKING" })}
                                            label={<FormattedMessage id="CONSUCTIVE_WRONG_ANSWERS_FOR_NEGATIVE_MARKING"></FormattedMessage>}
                                        />
                                    </div> */}
									<div
										className={`mb-7 col-lg-4`}
										style={{
											display: `${currentAdminSetting.passwordQuizSettingAllowed ===
												'No' && 'none'}`,
										}}
									>
										<Field
											name='password'
											// type='password'
											component={Input}
											placeholder={intl.formatMessage({ id: 'QUIZ_PASSWORD' })}
											label={
												<FormattedMessage id='QUIZ_PASSWORD'></FormattedMessage>
											}
											withFeedbackLabel={false}
										/>
										<p className='text-muted text-sm'>
											<FormattedMessage id='PASSWORD_HELPER_TEXT'></FormattedMessage>
										</p>
									</div>

									<div className={`mb-7 col-lg-4`}>
										<Select
											name='status'
											label={<FormattedMessage id='STATUS'></FormattedMessage>}
										>
											<option value=''>
												{intl.formatMessage({ id: 'SELECT_ONE' })}
											</option>
											<option value='Draft'>Draft</option>
											<option value='Published'>Published</option>
											<option value='DateRange'>Date range</option>
										</Select>
									</div>
								</div>

								{values.status === 'DateRange' ? (
									<div className='form-group row'>
										<div className={`mb-7 col-lg-4`}>
											<Field
												name='startDate'
												type={'date'}
												component={Input}
												label={<FormattedMessage id='FROM'></FormattedMessage>}
											/>
										</div>
										<div className={`mb-7 col-lg-4`}>
											<Field
												name='endDate'
												type={'date'}
												component={Input}
												label={<FormattedMessage id='TO'></FormattedMessage>}
											/>
										</div>
									</div>
								) : (
									''
								)}

								<Button
									type='submit'
									variant='primary'
									onSubmit={handleSubmit}
									className='d-none'
									id='check3'
								>
									<FormattedMessage id='NEXT'></FormattedMessage>
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</CardBody>
		</Fragment>
	)
}
