import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch, useStore } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls'
import {
	fetchSettings,
	updateSettings,
} from '../../../_redux/settings/settingsActions'
import { SubscriptionTable } from './SubscriptionTable'
import swal from 'sweetalert2'

const init = {
	CURRENCY: '',
	PAYSTACK_PRIVATE_KEY: '',
	PAYSTACK_PUBLIC_KEY: '',
	STRIPE_PUBLISHABLE_KEY: '',
	STRIPE_SECRET_KEY: '',
}
export function PaymentSettings() {
	const store = useStore()
	// UI Helpers
	// Fields
	const [loading, setloading] = useState(false)
	const dispatch = useDispatch()
	// const user = useSelector((state) => state.auth.user, shallowEqual);
	const { settings } = useSelector(
		(state) => ({
			settings: state.settings.entities,
		}),
		shallowEqual,
	)

	useEffect(() => {
		dispatch(fetchSettings())
	}, [dispatch])
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}

	// Methods
	const saveSettings = (values, setStatus, setSubmitting) => {
		setloading(true)
		dispatch(updateSettings(values))
			.then(() => {
				setloading(false)
				setSubmitting(false)
				let error_state = store.getState().settings.error
				if (error_state !== null) {
					swal.fire('Oops', error_state, 'error')
				} else {
					swal.fire('success', 'settings updated successfully', 'success')
				}
			})
			.catch((error) => {
				setloading(false)
				setSubmitting(false)
				setStatus(error)
				swal.fire('Oops', error, 'error')
			})
	}
	const Schema = Yup.object().shape({
		// payment_mode: Yup.string().required('Required'),
		// credit_system: Yup.bool(),
	})

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: settings || init,
		validationSchema: Schema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			saveSettings(values, setStatus, setSubmitting)
		},
	})

	return (
		<>
			<form className='card card-custom' onSubmit={formik.handleSubmit}>
				{loading && <ModalProgressBar />}

				{/* begin::Header */}
				<div className='card-header py-3'>
					<div className='card-title align-items-start flex-column'>
						<h3 className='card-label font-weight-bolder text-dark'>
							Payment Settings
						</h3>
						<span className='text-muted font-weight-bold font-size-sm mt-1'>
							Change your payment settings
						</span>
					</div>
					<div className='card-toolbar'>
						<button
							type='submit'
							className='btn btn-success mr-2'
							disabled={
								formik.isSubmitting || (formik.touched && !formik.isValid)
							}
						>
							{loading && <span className='spinner spinner-white mr-8'></span>}
							Save Changes
							{formik.isSubmitting}
						</button>
						<Link
							to='/settings/personal-information'
							className='btn btn-secondary'
						>
							Cancel
						</Link>
					</div>
				</div>
				{/* end::Header */}
				{/* begin::Form */}
				<div className='form'>
					<div className='card-body'>
						{/* begin::Form Group */}
						<div className='row'>
							<label className='col-xl-4'></label>
							<div className='col-xl-6'>
								<h5 className='font-weight-bold mb-6'>Business Settings:</h5>
							</div>
						</div>
						<div className='form-group row'>
							<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
								Currency
							</label>
							<div className='col-xl-6'>
								<input
									type='text'
									placeholder='e.g USD, NGN, CAD'
									className={`form-control form-control-lg form-control-solid ${getInputClasses(
										'CURRENCY',
									)}`}
									name='CURRENCY'
									{...formik.getFieldProps('CURRENCY')}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
								Stripe Publishable Key
							</label>
							<div className='col-xl-6'>
								<input
									type='text'
									placeholder='Stripe Publishable key'
									className={`form-control form-control-lg form-control-solid ${getInputClasses(
										'STRIPE_PUBLISHABLE_KEY',
									)}`}
									name='STRIPE_PUBLISHABLE_KEY'
									{...formik.getFieldProps('STRIPE_PUBLISHABLE_KEY')}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
								Stripe Secret Key
							</label>
							<div className='col-xl-6'>
								<input
									type='text'
									placeholder='Stripe secret key'
									className={`form-control form-control-lg form-control-solid ${getInputClasses(
										'STRIPE_SECRET_KEY',
									)}`}
									name='STRIPE_SECRET_KEY'
									{...formik.getFieldProps('STRIPE_SECRET_KEY')}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
								Paystack Public Key
							</label>
							<div className='col-xl-6'>
								<input
									type='text'
									placeholder='Paystack public key'
									className={`form-control form-control-lg form-control-solid ${getInputClasses(
										'PAYSTACK_PUBLIC_KEY',
									)}`}
									name='PAYSTACK_PUBLIC_KEY'
									{...formik.getFieldProps('PAYSTACK_PUBLIC_KEY')}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
								Paystack Secret Key
							</label>
							<div className='col-xl-6'>
								<input
									type='text'
									placeholder='Paystack secret key'
									className={`form-control form-control-lg form-control-solid ${getInputClasses(
										'PAYSTACK_PRIVATE_KEY',
									)}`}
									name='PAYSTACK_PRIVATE_KEY'
									{...formik.getFieldProps('PAYSTACK_PRIVATE_KEY')}
								/>
							</div>
						</div>
						<div className='separator separator-dashed my-5'></div>
						<SubscriptionTable currency={settings?.CURRENCY} />
					</div>
					{/* <div className='card-footer d-flex justify-content-between'>
						<span></span>
						<button
							type='submit'
							className='btn btn-success mr-2'
							disabled={
								formik.isSubmitting || (formik.touched && !formik.isValid)
							}
						>
							{loading && <span className='spinner spinner-white mr-8'></span>}
							Save Changes
							{formik.isSubmitting}
						</button>
					</div> */}
				</div>
				{/* end::Form */}
			</form>
		</>
	)
}

// export default connect(null, auth.actions)(EmailSettings);
