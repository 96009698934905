import React, { Fragment, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import { useDispatch } from 'react-redux'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import {
	FormControl,
	InputLabel,
	MenuItem,
	TextField,
	Select,
	OutlinedInput,
	RadioGroup,
	Radio,
	Checkbox,
	FormGroup,
	FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Button, Spinner } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import Alert from '@material-ui/lab/Alert'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'
import Tagify from './tagify'
import AWS from '../../../../../../../s3config'
import * as actions from '../../../../_redux/question/questionAction'

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1, 0),
		minWidth: 120,
	},
}))

const QuestionAddPage = ({
	refreshQuestionsList,
	openedinmodal,
	history,
	match: { params },
}) => {
	const classes = useStyles()
	const intl = useIntl()
	const [noOfOptions, setNoOfOptions] = useState(4)
	const [tags, setTags] = useState()

	const [questionData, setQuestionData] = useState({
		questionText: '',
		category: '',
		course: '',
		optionType: '',
		options: [],
		tags: '',
		image: '',
		questionImage: '',
		answerExplaination:
			'<p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>',
	})

	const {
		questionText,
		category,
		course,
		optionType,
		answerExplaination,
	} = questionData

	const handleChange = (e) => {
		setQuestionData({ ...questionData, [e.target.name]: e.target.value })
	}
	useEffect(() => {
		if (tags) {
			const tag_map = JSON.parse(tags)
			const new_tags = tag_map.map((tag) => tag.value)
			setQuestionData({ ...questionData, tags: new_tags })
		}
	}, [tags])
	const getTags = (tags) => {
		setTags(tags)
	}
	// const setTags = (val) => {
	// 	setQuestionData({ ...questionData, tags: val })
	// }

	const setCATCO = () => {
		if (params && params.category && params.course) {
			setQuestionData({
				...questionData,
				category: params.category,
				course: params.course,
			})
		}
	}

	const [photo, setPhoto] = useState(null)
	const [questionPhoto, setQuestionPhoto] = useState(null)

	const onImageChange = (event, type) => {
		if (event.target.files.length) {
			let file = event.target.files[0]
			if (!event.target.files.length) {
				// document.getElementById('top').scrollIntoView(true)
				// setAlert('No file attached', 'danger')
			} else {
				let extension = file.type.split('/')[1]
				if (
					extension === 'jpg' ||
					extension === 'jpeg' ||
					extension === 'png' ||
					extension === 'jfif'
				) {
					if (type === 'question') {
						setQuestionPhoto(file)
					} else {
						setPhoto(file)
					}

					// if (file.size > process.env.REACT_APP_FILE_UPLOAD_SIZE) {
					// 	document.getElementById('top').scrollIntoView(true)
					// 	setAlert('File too large', 'danger')
					// } else {

					// }
				} else {
					// document.getElementById('top').scrollIntoView(true)
					// setAlert('Invalid file type', 'danger')
				}
			}
		} else {
			// document.getElementById('top').scrollIntoView(true)
			// setAlert('No file attached', 'danger')
		}
	}

	useEffect(() => {
		setCATCO()
	}, [])

	const handleCKEditorExplainationChange = (data) => {
		setQuestionData({ ...questionData, answerExplaination: data })
	}

	const handleOptionTextChange = (e, i) => {
		let tempquestionData = questionData
		let options = questionData.options
		options[i].text = e.target.value
		tempquestionData.options = options
		setQuestionData({ ...questionData, options: tempquestionData.options })
	}

	const handleOptionStatusChange = (e, i, oT) => {
		if (oT === 'radio') {
			let tempquestionData = questionData
			let options = questionData.options
			options[i].status = e.target.checked
			tempquestionData.options = options
			setQuestionData({ ...questionData, options: tempquestionData.options })
			questionData.options.map((o, index) => {
				if (index === i) questionData.options[index].status = e.target.checked
				else questionData.options[index].status = false
			})

			// setQuestions(questions)
		} else {
			let tempquestionData = questionData
			let options = questionData.options
			options[i].status = e.target.checked
			tempquestionData.options = options
			setQuestionData({ ...questionData, options: tempquestionData.options })
			// questionData.options[i].status = e.target.checked
			// setQuestions(questions)
		}
	}

	const handleOptionChange = (e) => {
		setQuestionData({ ...questionData, optionType: e.target.value })
		// setTimeout(() => {
		//     clearOptions()
		// }, 2000)
	}

	// const clearOptions = () => {
	//     let tempquestionData = questionData
	//     let options = questionData.options
	//     options.map(o => o.status = false)
	//     tempquestionData.options = options
	//     setQuestionData({ options: tempquestionData.options })
	// }

	const resetOptions = () => {
		for (let i = 0; i < 5; i++) {
			questionData.options.pop()
		}
		for (let i = 0; i < 5; i++) {
			questionData.options.push({
				// text: 'Option ' + (i + 1),
				text: '',
				status: false,
			})
		}

		// ;[...Array(5)].map((e, i) => questionData.options.pop())
		// ;[...Array(5)].map((e, i) =>
		//   questionData.options.push({
		//     // text: 'Option ' + (i + 1),
		//     text: '',
		//     status: false,
		//   })
		// )
	}

	useEffect(() => {
		resetOptions()
	}, [noOfOptions])

	// quiz Redux state
	const dispatch = useDispatch()

	const [processing, setProcessing] = useState(false)

	const onSubmit = async (e) => {
		e.preventDefault()
		if (!checkEmpty()) {
			let bool = false
			questionData.options.some((o, i) => {
				if (i < noOfOptions) {
					bool = false
					if (o.text === '') {
						bool = false
						return true
					} else {
						bool = true
					}
				}
			})
			if (bool) {
				let checked = false
				let num = 0
				if (optionType === 'radio') {
					questionData.options.map((o) => {
						if (o.status) {
							checked = true
						}
					})
				}
				if (optionType === 'checkbox') {
					questionData.options.map((o) => {
						if (o.status) {
							num++
						}
					})
					if (num > 1 && num < questionData.options.length) {
						checked = true
					}
				}
				if (checked) {
					questionData.options = questionData.options.splice(0, noOfOptions)

					setProcessing(true)

					if (photo) {
						//First uploading images
						let extension = photo.type.split('/')[1]
						let key = Math.floor(new Date().getTime()) + '.' + extension
						let path = process.env.REACT_APP_S3_BUCKET_FOLDER_NAME + '/' + key

						let params = {
							Bucket: process.env.REACT_APP_S3_BUCKET,
							Key: path,
							Body: photo,
							ACL: 'public-read',
						}

						const s3 = new AWS.S3()
						let data = await s3.upload(params).promise()
						let completeKey = data.Key
						questionData.image = completeKey
					}

					if (questionPhoto) {
						//First uploading images
						let extension = questionPhoto.type.split('/')[1]
						let key = Math.floor(new Date().getTime()) + '.' + extension
						let path = process.env.REACT_APP_S3_BUCKET_FOLDER_NAME + '/' + key

						let params = {
							Bucket: process.env.REACT_APP_S3_BUCKET,
							Key: path,
							Body: questionPhoto,
							ACL: 'public-read',
						}

						const s3 = new AWS.S3()
						let data = await s3.upload(params).promise()
						let completeKey = data.Key
						questionData.questionImage = completeKey
					}

					dispatch(
						actions.addQuestion(questionData, history, openedinmodal),
					).then((response) => {
						//this page can be opened from two places
						//1. from questions list as a page (we need to redirect back to questions page. Already being done from action method)
						//2. from add quiz as a modal (close the modal and refresh the list in the add quiz page)
						if (openedinmodal && response) {
							let { data } = response
							if (data.success) {
								refreshQuestionsList(data.question)
							}
						}
						setProcessing(false)
					})
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' })
					setAlertMessage(
						intl.formatMessage({ id: 'ALERT.OPTION_CHECKS_EMPTY' }),
					)
					setAlertStatus(true)
					setTimeout(() => {
						setAlertStatus(false)
					}, 4000)
				}
			} else {
				window.scrollTo({ top: 0, behavior: 'smooth' })
				setAlertMessage(intl.formatMessage({ id: 'ALERT.OPTION_VALUES_EMPTY' }))
				setAlertStatus(true)
				setTimeout(() => {
					setAlertStatus(false)
				}, 4000)
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			setAlertMessage(intl.formatMessage({ id: 'ALERT.FIELDS_EMPTY' }))
			setAlertStatus(true)
			setTimeout(() => {
				setAlertStatus(false)
			}, 4000)
		}
	}

	const checkEmpty = () => {
		if (
			questionText !== '' &&
			category !== '' &&
			course !== '' &&
			optionType !== '' &&
			answerExplaination !== ''
		)
			return false
		else return true
	}

	const [alertStatus, setAlertStatus] = useState(false)
	const [alertMessage, setAlertMessage] = useState('Something wrong!')

	const goBack = () => {
		history.push('/teacher/questions')
	}
	return (
		<Fragment>
			<Card>
				<CardHeader
					title={`Create Question in Category>> ${category}, Course>> ${course}`}
				>
					<CardHeaderToolbar>
						{!openedinmodal && (
							<Button variant='secondary' size='sm' onClick={() => goBack()}>
								<span className='svg-icon svg-icon-md svg-icon-dark'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Navigation/Arrow-left.svg',
										)}
									/>
								</span>
								<FormattedMessage id='GO_BACK'></FormattedMessage>
							</Button>
						)}
					</CardHeaderToolbar>
				</CardHeader>

				<CardBody className='d-flex flex-column'>
					{alertStatus && (
						<Alert
							severity='error'
							onClose={() => {
								setAlertStatus(false)
							}}
							className='mb-4'
						>
							{alertMessage}
						</Alert>
					)}
					<div className='row'>
						<FormControl className='col-6'>
							<TextField
								label={<FormattedMessage id='QUESTION'></FormattedMessage>}
								variant='outlined'
								multiline
								value={questionText}
								onChange={(e) => handleChange(e)}
								name='questionText'
							/>
						</FormControl>
						<div className='col-6'>
							<Tagify getTags={getTags} />
						</div>
						{/* <div className='col-12'>
							{questionPhoto && (
								<img
									className='questionImage mt-4'
									src={
										questionPhoto
											? URL.createObjectURL(questionPhoto)
											: toAbsoluteUrl('/media/svg/icons/Design/Image.svg')
									}
									alt='question'
								/>
							)}
							<div>
								{questionPhoto ? (
									<Button
										variant='primary'
										className='my-4'
										onClick={() => setQuestionPhoto(null)}
									>
										<span className='svg-icon svg-icon-md'>
											<SVG
												src={toAbsoluteUrl(
													'/media/svg/icons/General/Trash.svg',
												)}
											/>
										</span>
										<FormattedMessage id='CLEAR_IMAGE'></FormattedMessage>
									</Button>
								) : (
									<Fragment>
										<Button
											variant='primary'
											className='my-4'
											onClick={() =>
												document.getElementById('questionFile').click()
											}
										>
											<span className='svg-icon svg-icon-md'>
												<SVG
													src={toAbsoluteUrl(
														'/media/svg/icons/Design/Image.svg',
													)}
												/>
											</span>
											<FormattedMessage id='ADD_IMAGE'></FormattedMessage>
										</Button>
										<input
											accept='.jpg,.jpeg,.png,.jfif'
											onChange={(event) => onImageChange(event, 'question')}
											type='file'
											id='questionFile'
											style={{ display: 'none' }}
										/>
									</Fragment>
								)}
							</div>
						</div> */}
						<FormControl
							variant='outlined'
							className={`${classes.formControl} d-none col-6`}
						>
							<InputLabel>
								<FormattedMessage id='CATEGORY'></FormattedMessage>
							</InputLabel>
							<Select
								value={category}
								disabled
								input={
									<OutlinedInput
										labelWidth={90}
										name='category'
										onChange={(e) => handleChange(e)}
									/>
								}
							>
								<MenuItem value=''>
									<em>None</em>
								</MenuItem>
								<MenuItem value='Calculation'>Calculation</MenuItem>
								<MenuItem value='Literature'>Literature</MenuItem>
							</Select>
						</FormControl>
						<FormControl
							variant='outlined'
							className={`${classes.formControl} d-none col-6`}
						>
							<InputLabel>
								<FormattedMessage id='COURSE'></FormattedMessage>
							</InputLabel>
							<Select
								value={course}
								disabled
								input={
									<OutlinedInput
										labelWidth={50}
										name='course'
										onChange={(e) => handleChange(e)}
									/>
								}
							>
								<MenuItem value=''>
									<em>None</em>
								</MenuItem>
								<MenuItem value='Math'>Math</MenuItem>
								<MenuItem value='Urdu'>Urdu</MenuItem>
								<MenuItem value='English'>English</MenuItem>
								<MenuItem value='Stats'>Stats</MenuItem>
							</Select>
						</FormControl>
						<FormControl
							variant='outlined'
							className={`${classes.formControl} col-6 p-2`}
						>
							<InputLabel>
								<FormattedMessage id='ANSWER_TYPE'></FormattedMessage>
							</InputLabel>
							<Select
								input={
									<OutlinedInput
										labelWidth={80}
										name='optionType'
										value={optionType}
										onChange={(e) => {
											handleOptionChange(e)
											resetOptions()
										}}
									/>
								}
							>
								<MenuItem value=''>
									<em>None</em>
								</MenuItem>
								<MenuItem value={'radio'}>Radio</MenuItem>
								<MenuItem value={'checkbox'}>Checkbox</MenuItem>
							</Select>
						</FormControl>
						<FormControl
							variant='outlined'
							className={`${classes.formControl} col-6 p-2`}
						>
							<InputLabel>
								<FormattedMessage id='NUMBER_OPTION'></FormattedMessage>
							</InputLabel>
							<Select
								input={
									<OutlinedInput
										labelWidth={80}
										name='noOfOptions'
										value={noOfOptions}
										onChange={(e) => {
											setNoOfOptions(e.target.value)
											// resetOptions()
										}}
									/>
								}
							>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
							</Select>
						</FormControl>
						{/* <FormControl
						variant='outlined'
						className={`${classes.formControl} d-none`}
					>
						<InputLabel>
							<FormattedMessage id='CATEGORY'></FormattedMessage>
						</InputLabel>
						<Tagify getTags={setTags} />
					</FormControl> */}
						<div className='col-12'>
							{optionType === 'radio' ? (
								<Fragment>
									<p className='text-muted'>
										<FormattedMessage id='RADIO_HELPER_TEXT'></FormattedMessage>
									</p>
									<RadioGroup aria-label='quiz'>
										{[...Array(noOfOptions)].map((e, i) => (
											<FormControlLabel
												key={i}
												control={
													<Radio
														checked={questionData.options[i].status}
														color='primary'
														name='option'
														onChange={(e) =>
															handleOptionStatusChange(e, i, optionType)
														}
													/>
												}
												value={questionData.options[i].text}
												label={
													<TextField
														label={`${intl.formatMessage({
															id: 'ANSWER',
														})} ${i + 1}`}
														variant='outlined'
														value={questionData.options[i].text}
														onChange={(e) => handleOptionTextChange(e, i)}
													/>
												}
											/>
										))}
									</RadioGroup>
								</Fragment>
							) : optionType === 'checkbox' ? (
								<Fragment>
									<p className='text-muted'>
										<FormattedMessage id='CHECKBOX_HELPER_TEXT'></FormattedMessage>
									</p>
									<FormGroup>
										{[...Array(noOfOptions)].map((e, i) => (
											<FormControlLabel
												key={i}
												control={
													<Checkbox
														checked={questionData.options[i].status}
														color='primary'
														onChange={(e) =>
															handleOptionStatusChange(e, i, optionType)
														}
													/>
												}
												label={
													<TextField
														label={`${intl.formatMessage({
															id: 'ANSWER',
														})} ${i + 1}`}
														variant='outlined'
														value={questionData.options[i].text}
														onChange={(e) => handleOptionTextChange(e, i)}
													/>
												}
											/>
										))}
									</FormGroup>
								</Fragment>
							) : (
								<></>
							)}
						</div>
						{/* <FormControl className={classes.formControl}>
                        <TextField
                            label="Answer exlaination"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={answerExplaination}
                            onChange={e => handleChange(e)}
                            name="answerExplaination" />
                    </FormControl> */}
						<div className='col-12'>
							<CKEditor
								editor={ClassicEditor}
								onReady={(editor) => {
									// You can store the "editor" and use when it is needed.
									// console.log('Editor is ready to use!', editor);
									editor.editing.view.change((writer) => {
										writer.setStyle(
											'height',
											'180px',
											editor.editing.view.document.getRoot(),
										)
									})
								}}
								data={answerExplaination}
								onChange={(event, editor) => {
									const data = editor.getData()
									handleCKEditorExplainationChange(data)
									// console.log({ event, editor, data });
								}}
							/>
						</div>
						{/* <div className='col-6'>
							{photo && (
								<img
									className='questionImage'
									src={
										photo
											? URL.createObjectURL(photo)
											: toAbsoluteUrl('/media/svg/icons/Design/Image.svg')
									}
									alt='explaination'
								/>
							)}
							<div>
								{photo ? (
									<Button
										variant='primary'
										className='my-4'
										onClick={() => setPhoto(null)}
									>
										<span className='svg-icon svg-icon-md'>
											<SVG
												src={toAbsoluteUrl(
													'/media/svg/icons/General/Trash.svg',
												)}
											/>
										</span>
										<FormattedMessage id='CLEAR_IMAGE'></FormattedMessage>
									</Button>
								) : (
									<Fragment>
										<Button
											variant='primary'
											className='my-4'
											onClick={() => document.getElementById('file').click()}
										>
											<span className='svg-icon svg-icon-md'>
												<SVG
													src={toAbsoluteUrl(
														'/media/svg/icons/Design/Image.svg',
													)}
												/>
											</span>
											<FormattedMessage id='ADD_IMAGE'></FormattedMessage>
										</Button>
										<input
											accept='.jpg,.jpeg,.png,.jfif'
											onChange={(event) => onImageChange(event, 'answer')}
											type='file'
											id='file'
											style={{ display: 'none' }}
										/>
									</Fragment>
								)}
							</div>
						</div> */}
						<Button
							variant='primary'
							onClick={(e) => onSubmit(e)}
							className='m-4'
						>
							{/* <FormattedMessage id='CREATE'></FormattedMessage> */}
							Create Question
							{processing && (
								<Spinner animation='border' className='ml-2' variant='light' />
							)}
						</Button>
					</div>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default QuestionAddPage
