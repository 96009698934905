import * as requestFromServer from './tagsCrud'
import { tagsSlice, callTypes } from './tagsSlice'

const { actions } = tagsSlice

export const fetchTags = (queryParams) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	return requestFromServer
		.findTags(queryParams)
		.then((response) => {
			const { entities } = response.data
			dispatch(actions.tagsFetched({ entities }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find tags"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
