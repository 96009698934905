import React, { Fragment, useMemo, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Formik, Form } from 'formik'
import { isEqual } from 'lodash'

import { useLeaderBoardUIContext } from '../LeaderBoardUIContext'

import * as actions from '../../../../_redux/takenQuiz/takenQuizAction'
import * as categoryCourseCrud from '../../../../_redux/categoryCourse/categoryCourseCrud'

const prepareFilter = (queryParams, values) => {
	const newQueryParams = { ...queryParams }
	const filter = {}
	filter.studentName = values.searchText
	filter.studentEmail = values.searchText
	filter.quizName = values.quizName
	filter.quizCategory = values.quizCategory
	newQueryParams.filter = filter
	return newQueryParams
}

export const LeaderBoardFilter = () => {
	const [categories, setCategories] = useState([])

	const dispatch = useDispatch()

	const { nameList } = useSelector(
		(state) => ({
			nameList: state.takenQuiz.nameList,
		}),
		shallowEqual,
	)

	const do_things_on_load = async () => {
		const queryParams = {
			filter: {
				category: '',
			},
			sortOrder: 'asc', // asc||desc
			sortField: 'category',
			pageNumber: 1,
			pageSize: 100,
		}
		let response = await categoryCourseCrud.getAllCategoryCourse(queryParams)
		response = response.data
		if (response && response.success) {
			setCategories(response.entities)
		}
	}

	useEffect(() => {
		dispatch(actions.fetchTakenQuizNames())
		do_things_on_load()
	}, [dispatch])

	// LeaderBoard UI Context
	const leaderBoardUIContext = useLeaderBoardUIContext()
	const leaderBoardUIProps = useMemo(() => {
		return {
			setQueryParams: leaderBoardUIContext.setQueryParams,
			queryParams: leaderBoardUIContext.queryParams,
		}
	}, [leaderBoardUIContext])

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(leaderBoardUIProps.queryParams, values)
		if (!isEqual(newQueryParams, leaderBoardUIProps.queryParams)) {
			newQueryParams.pageNumber = 1
			leaderBoardUIProps.setQueryParams(newQueryParams)
		}
	}

	return (
		<Fragment>
			<Formik
				initialValues={{
					quizName: '',
					searchText: '',
					quizCategory: '',
				}}
				onSubmit={(values) => {
					applyFilter(values)
				}}
			>
				{({ values, handleSubmit, setFieldValue }) => (
					<Form onSubmit={handleSubmit} className='form form-label-right'>
						<div className='form-group row'>
							<div className='col-md-3'>
								<select
									className='form-control'
									name='quizName'
									placeholder='Filter by Quiz name'
									// TODO: Change this code
									onChange={(e) => {
										setFieldValue('quizName', e.target.value)
										handleSubmit()
									}}
									// onBlur={handleBlur}
									value={values.quizName}
								>
									<option value=''>All</option>
									{nameList !== null &&
										nameList.map((n) => (
											<option key={n} value={n}>
												{n}
											</option>
										))}
								</select>
								<small className='form-text text-muted'>
									<FormattedMessage id='FILTER.SEARCH_BY_QUIZ_NAME'></FormattedMessage>
								</small>
							</div>
							<div className='col-md-3'>
								<select
									className='form-control'
									name='quizCategory'
									placeholder='Filter by Quiz category'
									// TODO: Change this code
									onChange={(e) => {
										setFieldValue('quizCategory', e.target.value)
										handleSubmit()
									}}
									// onBlur={handleBlur}
									value={values.quizCategory}
								>
									<option value=''>All</option>
									{categories &&
										categories.map((n) => (
											<option key={n.id} value={n.category}>
												{n.category}
											</option>
										))}
								</select>
								<small className='form-text text-muted'>
									<FormattedMessage id='FILTER.SEARCH_BY_QUIZ_CATEGORY'></FormattedMessage>
								</small>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Fragment>
	)
}
