import { createSlice } from "@reduxjs/toolkit";

const initialAdminSettingState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    currentAdminSetting: undefined,
    lastError: null
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const adminSettingSlice = createSlice({
    name: "adminSetting",
    initialState: initialAdminSettingState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getAdminSetting
        adminSettingFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.currentAdminSetting = action.payload;
        }
    }
})