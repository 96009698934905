import axios from 'axios'

export const SETTINGS_URL =
	process.env.REACT_APP_API_ENDPOINT + '/api/admin/settings'

// CREATE =>  POST: add a new setting to the server
export function createSetting(setting) {
	return axios.post(SETTINGS_URL, { setting })
}

// READ
export function getAllSettings() {
	return axios.get(SETTINGS_URL)
}

export function getSettingById(settingId) {
	return axios.get(`${SETTINGS_URL}/${settingId}`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettings(queryParams) {
	if (queryParams) {
		return axios.get(`${SETTINGS_URL}/?filter=${JSON.stringify(queryParams)}`)
	} else {
		return axios.get(`${SETTINGS_URL}`)
	}
}

// UPDATE => PUT: update the procuct on the server
export function updateSettings(settings) {
	return axios.put(`${SETTINGS_URL}`, settings)
}

// UPDATE Status
export function updateStatusForSettings(ids, status) {
	return axios.post(`${SETTINGS_URL}/updateStatusForSettings`, {
		ids,
		status,
	})
}

// DELETE => delete the setting from the server
export function deleteSetting(settingId) {
	return axios.delete(`${SETTINGS_URL}/${settingId}`)
}

// DELETE Settings by ids
export function deleteSettings(ids) {
	return axios.post(`${SETTINGS_URL}/deleteSettings`, { ids })
}
