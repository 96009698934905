import React from 'react'
import { Route } from 'react-router-dom'

import { FeedbackUIProvider } from './FeedbackUIContext'
import FeedbackCard from './FeedbackCard'

import FeedbackViewDialog from './feedback-view-dialog/FeedbackViewDialog'

const FeedbackPage = ({ history }) => {
    const feedbackUIEvents = {
        openViewFeedbackDialog: (id) => {
            history.push(`/teacher/feedback/${id}/view`)
        },
    }

    return (
        <FeedbackUIProvider feedbackUIEvents={feedbackUIEvents}>
            <FeedbackCard />
            <Route path='/teacher/feedback/:id/view'>
                {({ history, match }) => (
                    <FeedbackViewDialog
                        show={match != null}
                        id={match && match.params.id}
                        history={history}
                        onHide={() => {
                            history.push('/teacher/feedback')
                        }}
                    />
                )}
            </Route>
        </FeedbackUIProvider>
    )
}

export default FeedbackPage
