import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from "formik";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import { Input } from '../../../../../../../_metronic/_partials/controls'
import { Button } from 'react-bootstrap'

const QuizEmailStudentSchema = Yup.object().shape({
    studentName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Student name is required"),
    studentEmail: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Student email is required"),
    emailSubject: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email subject is required")
});

export function QuizEmailStudentForm({ formData, saveEmailInfo, btnRef }) {
    const intl = useIntl();
    const [alertStatus, setAlertStatus] = useState(false)
    const [data, setData]=useState("")

    const handleCKEditorMessageChange = (data) => {
        setData(data)
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(values) => {
                    if (data !== '') {
                        values.emailMessage = data
                        saveEmailInfo(values)
                    }
                    else {
                        setAlertStatus(true);
                        setTimeout(() => {
                            setAlertStatus(false)
                        }, 4000)
                    }
                }}
                validationSchema={QuizEmailStudentSchema}
            >
                {({ handleSubmit }) => (
                    <>
                        <Form className="form form-label-right">
                            {
                                alertStatus &&
                                <Alert severity="error" onClose={() => { setAlertStatus(false) }} className="mb-4">
                                    <FormattedMessage id="ALERT.EMAIL_MESSAGE_EMPTY"></FormattedMessage>
                                </Alert>
                            }
                            <div className="form-group row mb-4">
                                <div className="col-lg-12">
                                    <Field
                                        name="studentName"
                                        component={Input}
                                        placeholder={intl.formatMessage({ id: "STUDENT_NAME" })}
                                        label={<FormattedMessage id="STUDENT_NAME"></FormattedMessage>}
                                        disabled={true}
                                        withFeedbackLabel={false}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <div className="col-lg-12">
                                    <Field
                                        name="studentEmail"
                                        component={Input}
                                        placeholder={intl.formatMessage({ id: "STUDENT_EMAIL" })}
                                        label={<FormattedMessage id="STUDENT_EMAIL"></FormattedMessage>}
                                        disabled={true}
                                        withFeedbackLabel={false}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <div className="col-lg-12">
                                    <Field
                                        name="emailSubject"
                                        component={Input}
                                        placeholder={intl.formatMessage({ id: "EMAIL_SUBJECT" })}
                                        label={<FormattedMessage id="EMAIL_SUBJECT"></FormattedMessage>}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <div className="col-lg-12">
                                    <label>
                                        <FormattedMessage id="EMAIL_MESSAGE"></FormattedMessage>
                                    </label>
                                    {/* <Field
                                        name="emailMessage"
                                        as="textarea"
                                        className="form-control"
                                        style={style.textarea}
                                        placeholder={intl.formatMessage({ id: "EMAIL_MESSAGE" })}
                                    /> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log('Editor is ready to use!', editor);
                                            editor.editing.view.change((writer) => {
                                                writer.setStyle('height', '180px', editor.editing.view.document.getRoot())
                                            })
                                        }}
                                        data={data}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            handleCKEditorMessageChange(data)
                                            // console.log({ event, editor, data });
                                        }}
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                className="d-none"
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            >
                                <FormattedMessage id="SEND_EMAIL"></FormattedMessage>
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    )
}

const style = {
    textarea: {
        minHeight: 100
    }
}