import axios from 'axios'

const APIURL = process.env.REACT_APP_API_ENDPOINT

export const RATE_UNRATE_QUESTION_URL = '/api/question/rate_unrate'
export const ADD_QUESTION_URL = '/api/question'
export const UPDATE_QUESTION_URL = '/api/question/update'
export const GET_ALL_QUESTION_URL = '/api/question/findAll'
export const RANDOM_QUESTION_URL = '/api/question/findAllRandom'
export const GET_QUESTION_BY_ID_URL = '/api/question/findOne'
export const DELETE_QUESTION_URL = '/api/question/delete'

export function addQuestion(questionData) {
	return axios.post(APIURL + ADD_QUESTION_URL, questionData)
}

export function addQuestionFromCSV(csv) {
	return axios.post(APIURL + '/api/csvquestion', csv)
}

export function updateQuestion(questionData) {
	return axios.post(APIURL + UPDATE_QUESTION_URL, questionData)
}

export function getAllQuestion(queryParams, category, course) {
	return axios.post(APIURL + GET_ALL_QUESTION_URL, {
		queryParams,
		category,
		course,
	})
}

export function randomQuestion(filter) {
	return axios.post(APIURL + RANDOM_QUESTION_URL, filter)
}

export function getQuestionById(questionId) {
	return axios.post(APIURL + GET_QUESTION_BY_ID_URL, { questionId })
}

export function deleteQuestion(questionId) {
	return axios.post(APIURL + DELETE_QUESTION_URL, { questionId })
}

export function rateUnrateQuestion(data) {
	return axios.post(APIURL + RATE_UNRATE_QUESTION_URL, data)
}
