/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch, useStore } from 'react-redux'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers/AssetsHelpers'
import { fetchAllSubscriptions } from '../../../_redux/subscriptions/subscriptionsActions'
import { fetchSettings } from '../../../_redux/settings/settingsActions'
import swal from 'sweetalert2'
import {
	createSubscriber,
	manageBilling,
	openCheckSection,
} from '../../../_redux/subscriptions/subscriptionsCrud'
import queryString from 'query-string'
import { Modal } from 'react-bootstrap'
import * as auth from '../../../../Auth/_redux/authRedux'

export function SubscriptionTable() {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [interac, setInterac] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState()
	const { subscriptions, subscriptionId, settings } = useSelector(
		(state) => ({
			subscriptionId: state.auth.user.subscriptionId,
			subscriptions: state.subscriptions.entities,
			settings: state.settings.entities,
		}),
		shallowEqual,
	)
	let queries = queryString.parse(window.location.search)

	useEffect(() => {
		queries.session_id &&
			createSubscriber(queries.session_id).then((result) => {
				if (result.status === 200) {
					let user = result.data.data
					dispatch(auth.actions.fulfillUser(user))
				}
			})
	}, [queries.session_id])
	useEffect(() => {
		dispatch(fetchSettings())
		dispatch(fetchAllSubscriptions())
	}, [dispatch])

	const stripeCheckout = (priceId) => {
		openCheckSection({ priceId })
			.then((response) => {
				window.location.href = response.data.data.url
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const manageSub = () => {
		setLoading(true)
		manageBilling()
			.then((response) => {
				window.location.href = response.data.data.url
			})
			.catch((error) => {
				console.log(error)
			})
	}
	return (
		<>
			{subscriptions.map((subscription) => (
				<div key={subscription.id} className='card card-custom gutter-b'>
					<div className='card-body'>
						<div className='d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap'>
							<div className='d-flex flex-column mr-5'>
								<div className='h4 text-dark text-hover-primary mb-5'>
									{subscription.title}
								</div>
								<p className='h5'>
									{settings?.CURRENCY} {subscription.price}/
									{subscription.interval}
								</p>
								<p className='text-dark-50'>{subscription.description}</p>
							</div>
							<div className='ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0'>
								{subscriptionId === subscription.id ? (
									<button
										onClick={() => manageSub()}
										className='btn btn-primary btn-block mt-6 mb-3'
									>
										Manage Subscription
										{loading && (
											<div
												className='spinner-border spinner-border-sm ml-2'
												role='status'
											>
												<span className='sr-only'>Loading...</span>
											</div>
										)}
									</button>
								) : (
									settings?.STRIPE_PUBLISHABLE_KEY && (
										<button
											type='button'
											className='btn btn-dark btn-block mt-6 mb-3'
											disabled={loading}
											onClick={() => setOpenModal(true)}
										>
											Subscribe
											{loading && (
												<div
													className='spinner-border spinner-border-sm ml-2'
													role='status'
												>
													<span className='sr-only'>Loading...</span>
												</div>
											)}
										</button>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			))}
			{/* <!-- Modal --> */}
			<Modal
				show={openModal}
				onHide={() => setOpenModal(false)}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Choose a Payment Method
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row justify-content-center align-items-center my-5'>
						{settings?.STRIPE_PUBLISHABLE_KEY && (
							<div className='col-md-6 text-center'>
								<img
									onClick={() => {
										setPaymentMethod('stripe')
										setLoading(true)
										stripeCheckout(subscriptions[0].stripe_price_code)
									}}
									style={{
										width: '15rem',
										cursor: 'pointer',
										opacity: paymentMethod === 'stripe' ? 0.5 : 1,
									}}
									alt='Stripe'
									src='/media/card.jpeg'
								></img>
								<br />
								Pay with Stripe
							</div>
						)}
						{/* {role <= 4 && ( */}
						<div className='col-md-6 text-center'>
							<img
								onClick={() => {
									setPaymentMethod('interac')
									setInterac(true)
								}}
								style={{
									width: '10rem',
									cursor: 'pointer',
									opacity: paymentMethod === 'interac' ? 0.5 : 1,
								}}
								alt='Stripe'
								src='/media/interac.jpg'
							></img>
							<br />
							Pay Offline
						</div>
						{/* )} */}
					</div>
					<p>
						{interac && (
							<center>
								<div className='m-3'>
									<b>
										Please send offline interac payments to -
										<a href='mailto:manifestandcompany@gmail.com'>
											{' '}
											manifestandcompany@gmail.com
										</a>
									</b>
									<br />
									Send a confirmation email with your full name and SingleLogin
									username after payment
									<br />
								</div>
							</center>
						)}
					</p>
				</Modal.Body>
				{/* <Modal.Footer>
					<Button onClick={this.props.onHide}>Close</Button>
				</Modal.Footer> */}
			</Modal>
		</>
	)
}
