import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import * as actions from '../../../../_redux/quiz/quizAction'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import { Button, Spinner } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import {
	toAbsoluteUrl,
	getSignedUrl,
} from '../../../../../../../_metronic/_helpers'

import { Grid, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: theme.spacing(1),
		width: 'fit-content',
	},
}))

const QuizViewPage = ({
	history,
	match: {
		params: { id },
	},
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	// const layoutDispatch = useContext(LayoutContext.Dispatch);
	const { actionsLoading, currentQuiz } = useSelector(
		(state) => ({
			actionsLoading: state.quiz.actionsLoading,
			currentQuiz: state.quiz.currentQuiz,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(actions.fetchQuiz(id, history))
	}, [id, dispatch])

	const goBack = () => {
		history.push('/teacher/quizPool')
	}
	const [more, setMore] = useState({
		key: -1,
		open: false,
	})
	return (
		<Fragment>
			<Card>
				<CardHeader
					title={<FormattedMessage id='VIEW_QUIZ'></FormattedMessage>}
				>
					<CardHeaderToolbar>
						<Button variant='secondary' size='sm' onClick={goBack}>
							<span className='svg-icon svg-icon-md svg-icon-dark'>
								<SVG
									src={toAbsoluteUrl(
										'/media/svg/icons/Navigation/Arrow-left.svg',
									)}
								/>
							</span>
							<FormattedMessage id='GO_BACK'></FormattedMessage>
						</Button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					{actionsLoading && (
						<div className='text-center p-2'>
							<Spinner animation='border' variant='dark' />
						</div>
					)}
					{!actionsLoading && currentQuiz && (
						<Fragment>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} style={style.gridItem}>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='QUIZ_NAME'></FormattedMessage>:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.quizName}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='QUIZ_DESCRIPTION'></FormattedMessage>
											:
										</span>
										<span
											className='text-muted text-hover-primary'
											style={style.moreData}
										>
											{currentQuiz.quizDescription}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='DIFFICULTY_LEVEL'></FormattedMessage>
											:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.level}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='TOTAL_QUESTION'></FormattedMessage>:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.totalQuestion}
										</span>
									</div>
								</Grid>
								<Grid item xs={12} md={6} style={style.gridItem}>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='CATEGORY'></FormattedMessage>:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.category}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='COURSE'></FormattedMessage>:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.course}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>
											<FormattedMessage id='QUIZ_TIME'></FormattedMessage>:
										</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.time === -1
												? 'No time limit'
												: currentQuiz.time + ' minutes'}
										</span>
									</div>
									<div className='d-flex justify-content-between'>
										<span className='font-weight-bold'>isPremium:</span>
										<span className='text-muted text-hover-primary'>
											{currentQuiz.isPremium ? 'true' : 'false'}
										</span>
									</div>
								</Grid>
							</Grid>
							<hr className='bg-secondary my-8'></hr>
							<h5>
								<FormattedMessage id='QUESTION_LIST'></FormattedMessage>
							</h5>
							{currentQuiz.questions.map((q, i) => (
								<div key={i} className='border border-dark-75 rounded mt-6 p-4'>
									<div style={style.detail}>
										<div>
											<div className='d-flex'>
												<p>
													<strong>
														<FormattedMessage id='QUESTION'></FormattedMessage>
													</strong>
												</p>
												<div className='ml-8'>
													<i className='fas fa-thumbs-up mr-2 text-dark'></i>
													{q.thumbsUpCount}

													<i className='fas fa-thumbs-down ml-4 mr-2 text-dark'></i>
													{q.thumbsDownCount}
												</div>
											</div>
											<p>{q.questionText}</p>
										</div>
										{more.key !== i && !more.open ? (
											<Button
												variant='secondary'
												size='sm'
												style={style.moreButton}
												onClick={() => setMore({ key: i, open: true })}
											>
												<span className='svg-icon svg-icon-md svg-icon-dark'>
													<SVG
														src={toAbsoluteUrl(
															'/media/svg/icons/Navigation/Arrow-down.svg',
														)}
													/>
												</span>
											</Button>
										) : (
											<Button
												variant='secondary'
												size='sm'
												style={style.moreButton}
												onClick={() => setMore({ key: -1, open: false })}
											>
												<span className='svg-icon svg-icon-md svg-icon-dark'>
													<SVG
														src={toAbsoluteUrl(
															'/media/svg/icons/Navigation/Arrow-up.svg',
														)}
													/>
												</span>
											</Button>
										)}
									</div>
									{more.key === i && more.open && (
										<div>
											<p>
												<strong>
													<FormattedMessage id='OPTION_LIST'></FormattedMessage>
												</strong>
											</p>
											<div className='d-flex flex-column mb-2'>
												{q.options.map((o, i) => (
													<Chip
														key={i}
														label={o.optionText}
														color={o.optionStatus ? 'primary' : 'default'}
														className={classes.chip}
													/>
												))}
											</div>
											<p>
												<strong>
													<FormattedMessage id='ANSWER_EXPLAINATION'></FormattedMessage>
												</strong>
											</p>
											<div
												dangerouslySetInnerHTML={{
													__html: q.answerExplaination,
												}}
											></div>
											<div>
												{q.image && (
													<img
														className='questionImage my-4'
														src={getSignedUrl(q.image)}
														alt='explaination'
													/>
												)}
											</div>
										</div>
									)}
								</div>
							))}
						</Fragment>
					)}
				</CardBody>
			</Card>
		</Fragment>
	)
}

const style = {
	gridItem: {
		paddingTop: '0',
		paddingBottom: '0',
	},
	moreData: {
		width: '100%',
		maxWidth: '55%',
		wordBreak: 'normal',
		textAlign: 'right',
	},
	moreButton: {
		height: '100%',
	},
	detail: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'top',
	},
}

export default QuizViewPage
