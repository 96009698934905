import { createSlice } from '@reduxjs/toolkit'

const initialTagsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const tagsSlice = createSlice({
	name: 'tags',
	initialState: initialTagsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// findTags
		tagsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			// state.totalCount = totalCount;
		},
	},
})
