import * as requestFromServer from './feedbackCrud'

import { feedbackSlice, callTypes } from './feedbackSlice'

import Swal from 'sweetalert2'

const { actions } = feedbackSlice

export const fetchAllFeedbacks = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return requestFromServer
        .getAllFeedback(queryParams)
        .then((response) => {
            const { totalCount, entities } = response.data
            dispatch(actions.feedbacksFetched({ totalCount, entities }))
        })
        .catch((error) => {
            error.clientMessage = "Can't find feedbacks"
            dispatch(actions.catchError({ error, callType: callTypes.list }))
        })
}

export const addFeedback = (questionId, feedbackText) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .addFeedback(questionId, feedbackText)
        .then((response) => {
            if (response.status === 200) {
                let { message } = response.data
                Swal.fire({
                    icon: 'success',
                    title: 'Feedback submitted',
                    text: message,
                    confirmButtonColor: '#1BC5BD',
                })
                dispatch(actions.endCall({ callType: callTypes.action }))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't add feedback"
            dispatch(actions.catchError({ error, callType: callTypes.action }))
        })
}

export const fetchFeedbackById = (id, history) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }))
    return requestFromServer
        .getFeedbackById(id)
        .then((response) => {
            if (response.status === 200) {
                const { feedbackObject } = response.data
                dispatch(actions.feedbackFetched({ feedbackObject }))
            } else {
                let { message } = response.data
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: `<strong>${message}</strong>`,
                }).then(() => {
                    history.push('/teacher/feedback')
                })
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find feedbacks"
            dispatch(actions.catchError({ error, callType: callTypes.actrion }))
        })
}
