import React from 'react'
import { Route } from 'react-router-dom'
import { LeaderBoardUIProvider } from './LeaderBoardUIContext'

import LeaderBoardTable from './leader-board-table/LeaderBoardTable'
import LeaderBoardEmailStudentDialog from './leader-board-email-student-dialog/LeaderBoardEmailStudentDialog'
import LeaderBoardDownloadCertificateDialog from './leader-board-download-certificate-dialog/LeaderBoardDownloadCertificateDialog'

const LeaderBoardPage = ({ history }) => {
    const leaderBoardUIEvents = {
        downloadCertificateDialog: (id) => {
            history.push(`/leaderBoard/${id}/download`);
        },
        emailStudentDialog: (id) => {
            history.push(`/leaderBoard/${id}/email`);
        }
    }

    return (
        <LeaderBoardUIProvider leaderBoardUIEvents={leaderBoardUIEvents}>
            <Route path="/leaderBoard/:id/email">
                {({ history, match }) => (
                    <LeaderBoardEmailStudentDialog
                        show={match != null}
                        id={match && match.params.id}
                        history={history}
                        onHide={() => {
                            history.push("/leaderBoard");
                        }}
                    />
                )}
            </Route>
            <Route path="/leaderBoard/:id/download">
                {({ history, match }) => (
                    <LeaderBoardDownloadCertificateDialog
                        show={match != null}
                        id={match && match.params.id}
                        history={history}
                        onHide={() => {
                            history.push("/leaderBoard");
                        }}
                    />
                )}
            </Route>
            <LeaderBoardTable />
        </LeaderBoardUIProvider>
    )
}

export default LeaderBoardPage
