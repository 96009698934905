import React, { Fragment, useEffect } from 'react'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { Icon, Chip } from '@material-ui/core'

import * as actions from '../../../../_redux/takenQuiz/takenQuizAction'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: theme.spacing(1),
		width: 'fit-content',
	},
}))

const TakenQuizDownloadDialog = ({ show, onHide, history, id, intl }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { currentTakenQuiz, actionsLoading } = useSelector(
		(state) => ({
			currentTakenQuiz: state.takenQuiz.currentTakenQuiz,
			actionsLoading: state.takenQuiz.actionsLoading,
		}),
		shallowEqual,
	)

	useEffect(() => {
		if (show) dispatch(actions.fetchTakenQuizById(id, history))
	}, [dispatch, id])

	const printDocument = () => {
		const input = document.getElementById('certificate')
		document.body.innerHTML = input.innerHTML
		window.print()
		window.location.href = '/student/takenQuiz'
	}

	return (
		<Fragment>
			<Modal
				show={show}
				onHide={onHide}
				keyboard={false}
				size='xl'
				backdrop='static'
				aria-labelledby='example-modal-sizes-title-lg'
			>
				{/* <Modal.Header title={`${currentTakenQuiz}`}>
                    <Modal.Title id='example-modal-sizes-title-lg'>
                        <FormattedMessage id='DOWNLOAD_CERTIFICATE'></FormattedMessage>
                    </Modal.Title>
                    <CardHeaderToolbar>
                            <Button variant='secondary' size='sm' onClick={goBack}>
                                <span className='svg-icon svg-icon-md svg-icon-dark'>
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
                                </span>
                                <FormattedMessage id='GO_BACK'></FormattedMessage>
                            </Button>
                        </CardHeaderToolbar>
                </Modal.Header> */}
				<Modal.Body className='p-0'>
					<Card>
						<CardHeader
							title={`${intl.formatMessage({ id: 'DOWNLOAD_CERTIFICATE' })}`}
						>
							<CardHeaderToolbar>
								{/* <Button variant='primary' className='mr-4' onClick={() => printDocument()}>
                                    <FormattedMessage id='SAVE'></FormattedMessage>
                                </Button> */}
								<Button variant='secondary' onClick={onHide}>
									<FormattedMessage id='CLOSE'></FormattedMessage>
								</Button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody id='certificate' className='p-0'>
							{actionsLoading && (
								<div className='text-center p-2'>
									<Spinner animation='border' variant='dark' />
								</div>
							)}
							{currentTakenQuiz &&
								currentTakenQuiz !== null &&
								!actionsLoading && (
									<div className='m-8'>
										{/* <FormattedMessage id='PROMPT_DOWNLOAD_CERTIFICATE'></FormattedMessage>{" "}
                            <strong>{currentTakenQuiz.quize.quizName}</strong> */}
										<p>
											<strong className='mr-4'>
												<FormattedMessage id='STUDENT_NAME'></FormattedMessage>
											</strong>
											{currentTakenQuiz.user.fullname}
										</p>

										<p>
											<strong className='mr-4'>
												<FormattedMessage id='QUIZ_NAME'></FormattedMessage>
											</strong>
											{currentTakenQuiz.quize.quizName}
										</p>

										<p>
											<FormattedMessage id='PROMPT_TIME_TO_COMPLETE_QUIZ'></FormattedMessage>
											<strong className='ml-2'>
												{Math.floor(currentTakenQuiz.timeTaken / 60)} min{' '}
												{currentTakenQuiz.timeTaken % 60} sec
											</strong>
										</p>

										{/* <h4 className='mb-8'>Quiz result</h4> */}

										<p>
											<FormattedMessage id='PROMPT_YOU_HAVE_SCORED'></FormattedMessage>{' '}
											<strong>{currentTakenQuiz.percentage}%</strong>
										</p>

										<p>
											<FormattedMessage id='CORRECT_ANSWERED'></FormattedMessage>
											{': '}
											<strong>{currentTakenQuiz.correctAnswered}</strong>
										</p>

										<p>
											<FormattedMessage id='NEGATIVE_POINTS'></FormattedMessage>
											{': '}
											<strong>{currentTakenQuiz.negativePoints}</strong>
										</p>

										{/* <div className='d-flex'>
                                        <strong className='mr-4'>
                                            <FormattedMessage id='PERCENTAGE'></FormattedMessage>
                                        </strong>
                                        <p>
                                            {
                                                currentTakenQuiz.percentage
                                            }
                                        </p>
                                    </div> */}
										{currentTakenQuiz.quize.showAnswer === 'Yes' && (
											<div>
												<h6>
													<FormattedMessage id='QUESTION_LIST'></FormattedMessage>
												</h6>
												{currentTakenQuiz.takenQuizAnswers.map(
													(takenQuestion, j) => (
														<div
															key={j}
															className='my-4 border border-dark rounded p-4'
														>
															<strong>
																<FormattedMessage id='QUESTION'></FormattedMessage>
															</strong>
															<p>{takenQuestion.question.questionText}</p>
															<div style={style.box}>
																<strong>
																	<FormattedMessage id='GIVEN_ANSWERS'></FormattedMessage>
																</strong>
																<span className='text-success ml-4'>
																	{true && (
																		<span className='d-flex align-items-baseline'>
																			{takenQuestion.answerStatus ? (
																				<span className='text-success ml-4'>
																					<span className='d-flex align-items-baseline'>
																						<Icon className='fas fa-check mr-2' />
																						<span>
																							<FormattedMessage id='CORRECT'></FormattedMessage>
																						</span>
																					</span>
																				</span>
																			) : (
																				<span className='text-danger ml-4'>
																					<span className='d-flex align-items-center'>
																						<Icon className='fas fa-times mr-2' />
																						<span>
																							<FormattedMessage id='WRONG'></FormattedMessage>
																						</span>
																					</span>
																				</span>
																			)}
																		</span>
																	)}
																</span>
															</div>
															{takenQuestion.answerFurthers.length > 0 ? (
																takenQuestion.answerFurthers.map(
																	(answer, index) => (
																		<Chip
																			key={index}
																			label={answer.optionText}
																			className={classes.chip}
																		/>
																	),
																)
															) : (
																<h6>
																	<FormattedMessage id='NO_ANSWER_GIVEN'></FormattedMessage>
																</h6>
															)}
															<p>
																<strong>
																	<FormattedMessage id='ANSWER_EXPLAINATION'></FormattedMessage>
																</strong>
															</p>
															<div
																dangerouslySetInnerHTML={{
																	__html:
																		takenQuestion.question.answerExplaination,
																}}
															></div>
														</div>
													),
												)}
											</div>
										)}
									</div>
								)}
						</CardBody>
					</Card>
				</Modal.Body>
				{/* <Modal.Footer>
                    <Button variant='primary' onClick={onHide}>
                        <FormattedMessage id='SAVE'></FormattedMessage>
                    </Button>
                    <Button variant='secondary' onClick={onHide}>
                        <FormattedMessage id='CLOSE'></FormattedMessage>
                    </Button>
                </Modal.Footer> */}
			</Modal>
		</Fragment>
	)
}

const style = {
	btnGroup: {
		width: '100%',
		maxWidth: 300,
		margin: '30px auto',
		display: 'flex',
		justifyContent: 'space-between',
	},
	box: {
		display: 'flex',
		alignItems: 'baseline',
		marginBottom: 10,
	},
}

export default injectIntl(TakenQuizDownloadDialog)
