import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./QuestionUIHelper";

const QuestionUIContext = createContext();

export function useQuestionUIContext() {
    return useContext(QuestionUIContext);
}

export const QuestionUIConsumer = QuestionUIContext.Consumer;

export function QuestionUIProvider({ questionUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }
            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const initQuestion = {
        id: undefined,
        questionText: ""
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initQuestion,
        openViewQuestionDialog: questionUIEvents.openViewQuestionDialog,
        openDeleteQuestionDialog: questionUIEvents.openDeleteQuestionDialog,
        openEditQuestionPage: questionUIEvents.openEditQuestionPage,
    };

    return <QuestionUIContext.Provider value={value}>{children}</QuestionUIContext.Provider>;
}