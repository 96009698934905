import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import * as actions from '../../../../_redux/quiz/quizAction'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'

import { quizSlice } from '../../../../_redux/quiz/quizSlice'
const quiz_slice = quizSlice.actions

const QuizBasedList = ({ category, course, proceedToQuestionList }) => {
	// Getting curret state of quiz list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({
			currentState: state.quiz,
		}),
		shallowEqual,
	)

	const { entities } = currentState

	// quiz Redux state
	const dispatch = useDispatch()
	useEffect(() => {
		const filter = {
			category,
			course,
		}
		//initially make empty
		let totalCount = 0
		let entities = null
		dispatch(quiz_slice.quizesFetched({ totalCount, entities }))
		dispatch(actions.randomQuiz(filter))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		if (entities !== null) {
			setQuizList(entities)
		}
	}, [entities])

	const [quizList, setQuizList] = useState(null)

	const [alertStatus, setAlertStatus] = useState(false)

	const onQuizRemove = (qId) => {
		const newList = quizList.filter((q) => q.quizId !== qId)
		if (newList.length === 0) {
			setAlertStatus(true)
			setTimeout(() => {
				setAlertStatus(false)
			}, 4000)
		} else {
			setQuizList(newList)
		}
	}

	const handleNext = () => {
		proceedToQuestionList(quizList)
	}

	const resetList = () => {
		if (entities !== null) {
			setQuizList(entities)
		}
	}

	return (
		<Card>
			{' '}
			<CardHeader>
				<div className='card-title'></div>
				<CardHeaderToolbar>
					<Button
						variant='secondary'
						size='sm'
						className='ml-3'
						onClick={() => resetList()}
					>
						<span className='svg-icon svg-icon-md svg-icon-dark'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Media/Repeat.svg')} />
						</span>
						<FormattedMessage id='RESET_LIST'></FormattedMessage>
					</Button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody className='d-flex flex-column'>
				{alertStatus && (
					<Alert
						severity='error'
						onClose={() => {
							setAlertStatus(false)
						}}
						className='mb-4'
					>
						<FormattedMessage id='ALERT.ONE_QUIZ_SHOULD_REMAIN'></FormattedMessage>
					</Alert>
				)}
				{quizList !== null && quizList.length !== 0 ? (
					<Fragment>
						{quizList.map((e, index) => (
							<Fragment key={index}>
								<div className='row d-flex align-items-center border-secondary border-bottom py-2'>
									<div className='col-md-8'>
										<p style={style.qMaxWidth}>{e.quizName}</p>
									</div>
									<div className='col-md-3 text-center'>{e.level}</div>
									<div className='col-md-1 text-right'>
										<OverlayTrigger
											overlay={
												<Tooltip>
													<FormattedMessage id='REMOVE_QUIZ'></FormattedMessage>
												</Tooltip>
											}
										>
											<div
												className='btn btn-icon btn-sm btn-light btn-hover-danger'
												onClick={() => onQuizRemove(e.quizId)}
											>
												<span className='svg-icon svg-icon-md svg-icon-danger'>
													<SVG
														src={toAbsoluteUrl(
															'/media/svg/icons/General/Trash.svg',
														)}
													/>
												</span>
											</div>
										</OverlayTrigger>
									</div>
								</div>

								{/* <div className="d-flex align-items-center justify-content-between mb-2 pb-2 border-secondary border-bottom">
                                            
                                            
                                        </div> */}
							</Fragment>
						))}
						{/* <div className="btn-group flex-wrap mt-2">
                                    <Button variant="secondary" onClick={() => prevPlace()}>Back</Button>
                                </div> */}
						<Button
							className='d-none'
							id='check5'
							variant='primary'
							style={style.btn}
							onClick={() => handleNext()}
						>
							<FormattedMessage id='NEXT'></FormattedMessage>
						</Button>
					</Fragment>
				) : quizList !== null && quizList.length === 0 ? (
					<Fragment>
						<span className='svg-icon svg-icon-lg svg-icon-dark'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/General/Sad.svg')} />
						</span>
						<h6>
							<FormattedMessage id='NO_QUIZ_FOUND'></FormattedMessage>
						</h6>
					</Fragment>
				) : (
					<Fragment>
						<div className='text-center p-2'>
							<Spinner animation='border' variant='dark' />
						</div>
					</Fragment>
				)}
			</CardBody>
		</Card>
	)
}

const style = {
	qMaxWidth: {
		margin: '0 30px 0 0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	btn: {
		width: 'fit-content',
		marginTop: '.5rem',
	},
}

export default QuizBasedList

// <Fragment>
//     {
//         questions.length !== 0 ?
//             <Fragment>
//                 {
//                     questions.map((q, i) => (
//                         <Fragment key={i}>
//                             <div className="d-flex align-items-center justify-content-between mb-2 ml-4 pb-2 border-secondary border-bottom">
//                                 <p style={qMaxWidth}>{q.questionText}</p>
//                                 <OverlayTrigger overlay={<Tooltip id="quiz-view-tooltip">Remove question</Tooltip>} >
//                                     <div className="btn btn-icon btn-sm btn-light btn-hover-danger" onClick={() => onQsRemove(q.questionText)}>
//                                         <span className="svg-icon svg-icon-md svg-icon-danger">
//                                             <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
//                                         </span>
//                                     </div>
//                                 </OverlayTrigger>
//                             </div>
//                         </Fragment>
//                     ))
//                 }
//             </Fragment>
//             : <Fragment></Fragment>
//     }
// </Fragment>

// <Fragment key={index}>
//     <h5>{e.quizName}</h5>
//     {
//         e.questions.map((q, index) =>
//             <Fragment key={index}>
//                 <div className="d-flex align-items-center justify-content-between mb-2 ml-4 pb-2 border-secondary border-bottom">
//                     <p style={qMaxWidth}>{q.questionText}</p>
//                     <OverlayTrigger overlay={<Tooltip id="quiz-view-tooltip">Remove question</Tooltip>} >
//                         <div className="btn btn-icon btn-sm btn-light btn-hover-danger">
//                             <span className="svg-icon svg-icon-md svg-icon-danger">
//                                 <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
//                             </span>
//                         </div>
//                     </OverlayTrigger>
//                 </div>
//             </Fragment>
//         )
//     }
// </Fragment>
