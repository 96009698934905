/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'
import { FormattedMessage } from 'react-intl'

export function HeaderMenu({ layoutProps }) {
    const location = useLocation()
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? 'menu-item-active' : ''
    }

    const { userType } = useSelector(
        (state) => ({
            userType: state.auth.user.userType,
        }),
        shallowEqual
    )

    return (
        <div
            id='kt_header_menu'
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {userType === 'Student' ? (
                    <Fragment>
                        {/*begin::1 Level*/}
                        {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/student/dashboard')}`}>
                            <NavLink className="menu-link" to="/student/dashboard">
                                <span className="menu-text">Dashboard</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li> */}
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/student/quizPool')}`}>
                            <NavLink className='menu-link' to='/student/quizPool'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/student/takenQuiz')}`}>
                            <NavLink className='menu-link' to='/student/takenQuiz'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.TAKEN_QUIZ'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}
                    </Fragment>
                ) : userType === 'Teacher' ? (
                    <Fragment>
                        {/*begin::1 Level*/}
                        {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/teacher/dashboard')}`}>
                                <NavLink className="menu-link" to="/teacher/dashboard">
                                    <span className="menu-text">Dashboard</span>
                                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                                </NavLink>
                            </li> */}
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/teacher/quizPool')}`}>
                            <NavLink className='menu-link' to='/teacher/quizPool'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/teacher/questions')}`}>
                            <NavLink className='menu-link' to='/teacher/questions'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.QUESTIONS'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/teacher/feedback')}`}>
                            <NavLink className='menu-link' to='/teacher/feedback'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.FEEDBACK'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}
                    </Fragment>
                ) : userType === 'Admin' ? (
                    <Fragment>
                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/admin/leaderBoard')}`}>
                            <NavLink className='menu-link' to='/admin/leaderBoard'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.LEADER_BOARD'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}

                        {/*begin::1 Level*/}
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/admin/quizSetting')}`}>
                            <NavLink className='menu-link' to='/admin/quizSetting'>
                                <span className='menu-text'>
                                    <FormattedMessage id='NAV.QUIZ_SETTING'></FormattedMessage>
                                </span>
                                {layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
                            </NavLink>
                        </li>
                        {/*end::1 Level*/}
                    </Fragment>
                ) : (
                    <Fragment></Fragment>
                )}
            </ul>
            {/*end::Header Nav*/}
        </div>
    )
}
