import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
import { initialFilter } from "./TakenQuizUIHelper"

const TakenQuizUIContext = createContext()

export function useTakenQuizUIContext() {
    return useContext(TakenQuizUIContext)
}

export const TakenQuizUIConsumer = TakenQuizUIContext.Consumer

export function TakenQuizUIProvider({ takenQuizUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])

    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }

            return nextQueryParams
        })
    }, [])

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        // initQuiz,
        downloadCertificateDialog: takenQuizUIEvents.downloadCertificateDialog,
        retakeQuizDialog: takenQuizUIEvents.retakeQuizDialog,
        openResultPage: takenQuizUIEvents.openResultPage,
    }

    return <TakenQuizUIContext.Provider value={value}>{children}</TakenQuizUIContext.Provider>
}
