import React, { useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import KTWizard from '../../../../../../../_metronic/_assets/js/components/wizard'
import './wizard-3.css'
import { QuizInformationForm } from './QuizInformationForm'
import { QuizSettingForm } from './QuizSettingForm'
import QuizBasedList from './QuizBasedList'
import * as actions from '../../../../_redux/quiz/quizAction'
import * as actions2 from '../../../../_redux/adminSetting/adminSettingAction'
import ScrollArea from 'react-scrollbar'
import QuestionBasedList from './QuestionBasedList'
import { useHistory } from 'react-router'
import QuestionBasedList2 from './QuestionBasedList2'
import { CircularProgress, colors } from '@material-ui/core'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core/styles'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		color: colors.green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))
export function QuizEditForm({ id }) {
	const [questions, setQuestions] = useState([])
	const classes = useStyles()
	const history = useHistory()
	const wizardControl = () => KTWizard('kt_wizard_v3', {})

	
	useEffect(() => {
		wizardControl()
	}, [])



	const [loading, setLoading] = useState(true)
	const [pageLoadTime, setPageLoadTime] = useState(null)
	const [type, setType] = useState('question')
	const [profiles, setProfiles] = useState([])
	const [departments, setDepartments] = useState([])
	const goToNext = () => {
		wizardControl().goNext()
	}
	const { user, currentAdminSetting, currentQuiz } = useSelector(
		(state) => ({
			user: state.auth.user,
			currentAdminSetting: state.adminSetting.currentAdminSetting,
			currentQuiz: state.quiz.currentQuiz,
		}),
		shallowEqual,
	)
	
	const [quizData, setQuizData] = useState({
		teacherId: '',
		quizName: '',
		quizDescription: '',
		course: '',
		category: '',
		level: '',
		totalQuestion: '',
		selectedQuestions: [],
		time: '1',
		quiztimetype: '',
		allowSkip: '',
		attemptsAllowed: '',
		negativeMarking: 'No',
		showAnswer: 'Yes',
		consuctiveWrongAnswersForNegativeMarking: 0,
		takeBestScore: '',
		password: '',
		status: 'Draft',
		startDate: '',
		endDate: '',
		isPremium: true,
		profile: [],
		department: [],
		tags:[]
	})
	const { course, category, totalQuestion, selectedQuestions } = quizData

	const setSelectedQuestions = (qs) => {
		setQuizData({ ...quizData, selectedQuestions: qs })
	}
	const setSelectedProfile = (p, status) => {
		const pro = quizData.profile
		if (status) pro.push(p)
		else {
			for (var i = 0; i < pro.length; i++) {
				if (pro[i] === p) {
					pro.splice(i, 1)
				}
			}
		}
		setQuizData({ ...quizData, profile: pro })
	}
	const setSelectedDepartment = (d, status) => {
		const dep = quizData.department
		if (status) dep.push(d)
		else {
			for (var i = 0; i < dep.length; i++) {
				if (dep[i] === d) {
					dep.splice(i, 1)
				}
			}
		}
		setQuizData({ ...quizData, department: dep })
	}
	const saveQuizInfo = (values) => {
		setQuizData({
			...quizData,
			quizName: values.quizName,
			quizDescription: values.quizDescription,
			category: values.category,
			course: values.course,
			level: values.level,
			totalQuestion: values.totalQuestion,
			isPremium: values.isPremium,
			tags: values.tags
		})
		goToNext()
	}
	const saveQuizSetting = (values) => {
		if (currentAdminSetting.quizTimeSettingAllowed === 'No') values.time = -1
		setQuizData({
			...quizData,
			teacherId: user.userid,
			// totalQuestion: quizData.questions.length,
			time: values.time,
			allowSkip: values.allowSkip,
			attemptsAllowed: values.attemptsAllowed,
			negativeMarking: values.negativeMarking,
			takeBestScore: values.takeBestScore,
			showAnswer: values.showAnswer,
			password: values.password,
			quiztimetype: values.quiztimetype ? values.quiztimetype : null,
			status: values.status,
			startDate: values.startDate,
			endDate: values.endDate,
			starttimestamp: values.starttimestamp,
			endtimestamp: values.endtimestamp,
			consuctiveWrongAnswersForNegativeMarking:
			values.consuctiveWrongAnswersForNegativeMarking,
		})
		goToNext()
		// setLoading(false)
	}
	const proceedToQuestionList = (quizList) => {
		//clear the array
		;[...Array(questions.length)].map((a) => questions.pop())

		//we will get all questions from all quizes

		quizList.map((qL) => {
			qL.questions.map((q) => {
				//a question might exist in more than one quiz so we will make sure that we don't put the question that is already added in the list
				if (!questions.find((obj) => obj.id === q.id)) {
					questions.push(q)
				}
			})
		})
		setQuestions(questions)
		// setQuestions(shuffleArray(questions))
		goToNext()
	}
	const saveQuizAllDetails = () => {
		if (!quizData.totalQuestion)
			setQuizData({
				...quizData,
				totalQuestion: quizData.selectedQuestions.length,
			})
		setLoading(false)
	}
	const dispatch = useDispatch()
	useEffect(() => {
		if (!loading) {
			if (selectedQuestions.length === 0) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<p>You did not select any questions. You need to select atleast one question.</p>`,
				})
				setLoading(true)
			} else if (selectedQuestions.length !== totalQuestion) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<p>You set total questions : <strong>${totalQuestion}</strong> but you have selected only <strong>${selectedQuestions.length}</strong>. Please select accordingly.</p>`,
				})
				setLoading(true)
			} else {
				if (id) {
					if (!quizData.quiztimetype) {
						quizData.time = -1
					}
					dispatch(actions.updateQuiz(quizData, history))
				} else {
					dispatch(actions.addQuiz(quizData, history)).then((response) => {
						if (response) {
							let res = response.data
							if (!res.status) {
								setLoading(true)
							}
						}
					})
				}
			}
		}
	}, [loading])

	useEffect(() => {
		id && dispatch(actions.fetchQuiz(id))
		dispatch(actions2.fetchAdminSetting())
		Axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/get_profiles')
			.then((res) => {
				if (res.status === 200) setProfiles(res.data.data)
			})
			.catch((e) => console.log(e))
		Axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/get_departments')
			.then((res) => {
				if (res.status === 200) setDepartments(res.data.data)
			})
			.catch((e) => console.log(e))
	}, [dispatch, id])

	useEffect(() => {
		if (id && currentQuiz) {
			let selectedQuestions = []
			for (let i = 0; i < currentQuiz.questions.length; i++) {
				selectedQuestions.push(currentQuiz.questions[i].id)
			}

			let c_quiz = {
				id: currentQuiz.quizId,
				teacherId: currentQuiz.userid,
				quizName: currentQuiz.quizName,
				quizDescription: currentQuiz.quizDescription,
				course: currentQuiz.course,
				category: currentQuiz.category,
				level: currentQuiz.level,
				totalQuestion: '',
				questions: currentQuiz.questions,
				selectedQuestions: selectedQuestions,
				time: currentQuiz.time,
				quiztimetype: currentQuiz.timeType ? currentQuiz.timeType : '',
				allowSkip: currentQuiz.allowSkip,
				attemptsAllowed: currentQuiz.attemptsAllowed,
				negativeMarking: currentQuiz.negativeMarking,
				takeBestScore: currentQuiz.takeBestScore,
				showAnswer: currentQuiz.showAnswer,
				password: currentQuiz.password,
				takenQuizes: currentQuiz.takenQuizes,
				status: currentQuiz.status,
				starttimestamp: currentQuiz.startDate,
				endtimestamp: currentQuiz.endDate,
				isPremium: currentQuiz.isPremium,
				tags: currentQuiz.tags,
				profile: currentQuiz.profiles ? JSON.parse(currentQuiz.profiles) : [],
				department: currentQuiz.departments
					? JSON.parse(currentQuiz.departments)
					: [],
				consuctiveWrongAnswersForNegativeMarking:
					currentQuiz.consuctiveWrongAnswersForNegativeMarking || '',
			}

			//we have put starttimestamp and endtimestamp values from DB, as they require timestamps
			//but the date fields which are binded with startDate and endDate, they don't have values yet, so lets calculate their values from timestamps

			if (
				currentQuiz.status === 'DateRange' &&
				currentQuiz.startDate &&
				currentQuiz.endDate
			) {
				let s_date = new Date(currentQuiz.startDate * 1000)
				let e_date = new Date(currentQuiz.endDate * 1000)

				let st_month_raw = s_date.getMonth() + 1
				let st_month = ''
				if (st_month_raw < 10) st_month = '0' + st_month_raw
				else st_month = st_month_raw

				let st = s_date.getFullYear() + '-' + st_month + '-' + s_date.getDate()
				c_quiz.startDate = st

				let e_month_raw = s_date.getMonth() + 1
				let e_month = ''
				if (e_month_raw < 10) e_month = '0' + e_month_raw
				else e_month = e_month_raw

				let e = e_date.getFullYear() + '-' + e_month + '-' + e_date.getDate()
				c_quiz.endDate = e
			}

			console.log(c_quiz)
			setQuizData(c_quiz)
		}
	}, [id, currentQuiz])
	useEffect(() => {
		if (currentAdminSetting) {
			if (currentAdminSetting.negativeMarkingSettingAllowed === 'No')
				setQuizData({ ...quizData, negativeMarking: 'No' })
		}
	}, [currentAdminSetting])
	useEffect(() => {
		setPageLoadTime(new Date())
	}, [])
	// console.log(quizData)
	return (
		<Modal.Body>
			{/* <WizardControl /> */}
			<div className='d-flex flex-column-fluid'>
				{/* <!--begin::Container--> */}
				<div className='container'>
					<div className='card card-custom'>
						<div className='card-body p-0'>
							{/* <!--begin: Wizard--> */}
							<div
								className='wizard wizard-3'
								id='kt_wizard_v3'
								data-wizard-state='step-first'
								data-wizard-clickable='true'
							>
								{/* <!--begin: Wizard Nav--> */}
								<div className='wizard-nav'>
									<div className='wizard-steps px-8 py-8 px-lg-15 py-lg-3'>
										{/* <!--begin::Wizard Step 1 Nav--> */}
										{!id && (
											// <div
											// 	className='wizard-step'
											// 	data-wizard-type='step'
											// 	data-wizard-state='current'
											// >
											// 	<div className='wizard-label'>
											// 		<h3 className='wizard-title'>
											// 			<span></span>Quiz Type
											// 		</h3>
											// 		<div className='wizard-bar'></div>
											// 	</div>
											// </div>
											<></>
										)}
										{/* <!--end::Wizard Step 1 Nav--> */}
										{/* <!--begin::Wizard Step 2 Nav--> */}
										<div className='wizard-step' data-wizard-type='step'>
											<div className='wizard-label'>
												<h3 className='wizard-title'>
													<span></span>Quiz Details
												</h3>
												<div className='wizard-bar'></div>
											</div>
										</div>
										{/* <!--end::Wizard Step 2 Nav--> */}
										{/* <!--begin::Wizard Step 3 Nav--> */}
										<div className='wizard-step' data-wizard-type='step'>
											<div className='wizard-label'>
												<h3 className='wizard-title'>
													<span></span>Quiz Settings
												</h3>
												<div className='wizard-bar'></div>
											</div>
										</div>
										{/* <!--end::Wizard Step 3 Nav--> */}
										{/* <!--begin::Wizard Step 5 Nav--> */}



										{/* <div className='wizard-step' data-wizard-type='step'>
											<div className='wizard-label'>
												<h3 className='wizard-title'>
													<span></span>Visibility
												</h3>
												<div className='wizard-bar'></div>
											</div>
										</div>
 */}


										{/* <!--end::Wizard Step 5 Nav--> */}
										{/* <!--begin::Wizard Step 4 Nav--> */}
										{type === 'quiz' && (
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span></span>Pick Quizes
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
										)}
										{/* <!--end::Wizard Step 4 Nav--> */}
										{/* <!--begin::Wizard Step 4 Nav--> */}
										<div className='wizard-step' data-wizard-type='step'>
											<div className='wizard-label'>
												<h3 className='wizard-title'>
													<span></span>Pick Questions
												</h3>
												<div className='wizard-bar'></div>
											</div>
										</div>
										{/* <!--end::Wizard Step 4 Nav--> */}
									</div>
								</div>
								{/* <!--end: Wizard Nav--> */}
								{/* <!--begin: Wizard Body--> */}
								<div className='row justify-content-center py-5 px-8 px-lg-10'>
									<div className='col-xl-12 col-xxl-12'>
										{/* <!--begin: Wizard Form--> */}
										{/* <!--begin: Wizard Step 1--> */}
							
										{/* <!--end: Wizard Step 1--> */}
										{/* <!--begin: Wizard Step 2--> */}
										<div className='pb-5' data-wizard-type='step-content'>
											<h4 className='mb-5 font-weight-bold text-dark'>
												Enter Quiz Details
											</h4>
											<QuizInformationForm
												quizData={quizData}
												saveQuizInfo={saveQuizInfo}
												type={type}
											/>
										</div>
										{/* <!--end: Wizard Step 2--> */}
										{/* <!--begin: Wizard Step 3--> */}
										<div className='pb-5' data-wizard-type='step-content'>
											<h4 className='mb-5 font-weight-bold text-dark'>
												Enter Quiz Settings
											</h4>
											{currentAdminSetting && (
												<QuizSettingForm
													quizData={quizData}
													currentAdminSetting={currentAdminSetting}
													saveQuizSetting={saveQuizSetting}
												/>
											)}
										</div>
										{/* <!--end: Wizard Step 3--> */}
										{/* <!--begin: Wizard Step 5--> */}



										{/* <div className='pb-5' data-wizard-type='step-content'>
											<h4 className='mb-10 font-weight-bold text-dark'>
												Accessibility Settings
											</h4>
											<ScrollArea style={{ maxHeight: '300px' }}>
												<div className='form-group row'>
													<div className='col-6'>
														<div className='form-group row'>
															<label className='col-3 col-form-label'>
																Profiles
															</label>
															<div className='col-9 col-form-label'>
																<div className='checkbox-list'>
																	{profiles.map((p, i) => (
																		<label key={i} className='checkbox'>
																			<input
																				type='checkbox'
																				name='profile'
																				value={p.id}
																				id={`profile${p.id}`}
																				checked={quizData.profile?.includes(
																					p.id,
																				)}
																				onChange={() => {
																					const cb = document.querySelector(
																						'#profile' + p.id,
																					)
																					setSelectedProfile(p.id, cb.checked)
																				}}
																			/>
																			<span></span>
																			{p.Name}
																		</label>
																	))}
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='form-group row'>
															<label className='col-3 col-form-label'>
																Departments
															</label>
															<div className='col-9 col-form-label'>
																<div className='checkbox-list'>
																	{departments.map((p, i) => (
																		<label key={i} className='checkbox'>
																			<input
																				type='checkbox'
																				name='department[]'
																				value={p.id}
																				id={`department${p.id}`}
																				checked={quizData.department?.includes(
																					p.id,
																				)}
																				onChange={() => {
																					const cb = document.querySelector(
																						'#department' + p.id,
																					)
																					setSelectedDepartment(
																						p.id,
																						cb.checked,
																					)
																				}}
																			/>
																			<span></span>
																			{p.Name}
																		</label>
																	))}
																</div>
															</div>
														</div>
													</div>
												</div>
												<Button
													className='d-none'
													id='check4'
													variant='primary'
													onClick={() => goToNext()}
												></Button>
											</ScrollArea>
										</div> */}




										{/* <!--end: Wizard Step 5--> */}
										{/* <!--begin: Wizard Step 4--> */}
										{/* {type === 'quiz' && (
											<div className='pb-5' data-wizard-type='step-content'>
												<h4 className='mb-10 font-weight-bold text-dark'>
													Select Your Preferred Quizzes
												</h4>
												{category && course && (
													<ScrollArea style={{ maxHeight: '300px' }}>
														<QuizBasedList
															category={category}
															course={course}
															proceedToQuestionList={proceedToQuestionList}
														/>
													</ScrollArea>
												)}
											</div>
										)} */}
										{/* <!--end: Wizard Step 4--> */}
										{/* <!--begin: Wizard Step 5--> */}
										<div className='pb-5' data-wizard-type='step-content'>
											<h4 className='mb-10 font-weight-bold text-dark'>
												Select Your Preferred Questions
												{/* {settings?.TIMEZONE && `(${settings.TIMEZONE})`} */}
											</h4>
											{category && course && (
												<ScrollArea style={{ maxHeight: '300px' }}>
													{type === 'quiz' ? (
														<QuestionBasedList
															category={category}
															course={course}
															selectedQuestions={selectedQuestions}
															setSelectedQuestions={setSelectedQuestions}
															questions={questions}
															saveQuizAllDetails={saveQuizAllDetails}
															totalQuestion={totalQuestion}
															loading={loading}
														/>
													) : (
														<QuestionBasedList2
															pageLoadTime={pageLoadTime}
															selectedQuestions={selectedQuestions}
															setSelectedQuestions={setSelectedQuestions}
															setQuestions={setQuestions}
															category={category}
															course={course}
															totalQuestion={totalQuestion}
															saveQuizAllDetails={saveQuizAllDetails}
														/>
													)}
												</ScrollArea>
											)}
										</div>
										{/* <!--end: Wizard Step 5--> */}
										{/* <!--begin: Wizard Actions--> */}
										<div className='d-flex justify-content-between border-top mt-5 pt-10'>
											<div className='mr-2'>
												<button
													type='button'
													// onClick={() => setNextButton(false)}
													className='btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4'
													data-wizard-type='action-prev'
												>
													Previous
												</button>
											</div>
											<div>
												{/* <div className={classes.root}>
                                                    <div className={classes.wrapper}> */}
												<button
													type='button'
													// disabled={loading}
													className='btn btn-success font-weight-bolder text-uppercase px-9 py-4'
													data-wizard-type='action-submit'
													onClick={() =>
														document.getElementById(`checklast`).click()
													}
												>
													Submit
												</button>
												{!loading && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
												{/* </div>
                                                </div> */}
												{/* <div className={classes.root}>
                                                    <div className={classes.wrapper}> */}
												<button
													type='button'
													// disabled={nextButton}
													onClick={() =>
														document
															.getElementById(
																!id
																	? `check${wizardControl().getStep()+1}`
																	: `check${wizardControl().getStep() + 1}`,
															)
															.click()
													}
													className='btn btn-primary font-weight-bolder text-uppercase px-9 py-4'
													data-wizard-type='action-next'
												>
													Next
												</button>
												{/* {nextLoading && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.nextButtonProgress}
                                                            />
                                                        )} */}
												{/* </div>
                                                </div> */}
											</div>
										</div>
										{/* <!--end: Wizard Actions--> */}
										{/* <!--end: Wizard Form--> */}
									</div>
								</div>
								{/* <!--end: Wizard Body--> */}
							</div>
							{/* <!--end: Wizard--> */}
						</div>
					</div>
				</div>
				{/* <!--end::Container--> */}
			</div>
		</Modal.Body>
	)
}
