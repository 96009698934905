export const defaultSorted = [{ dataField: "quizName", order: "asc" }];
export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 }
];
export const initialFilter = {
    filter: {
        quizName: "",
        quizDescription: ""
    },
    sortOrder: "asc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
};