import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import LeaderBoardPage from './modules/Application/pages/Admin/leaderBoard/LeaderBoardPage'

import StudentPage from './modules/Application/pages/Student/StudentPage'
import TeacherPage from './modules/Application/pages/Teacher/TeacherPage'
import AdminPage from './modules/Application/pages/Admin/AdminPage'

export default function BasePage() {
	// useEffect(() => {
	//   console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	const { userType } = useSelector(
		(state) => ({
			userType: state.auth.user.userType,
		}),
		shallowEqual,
	)

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{userType && userType === 'Student' ? (
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from='/' to='/student' />
				) : userType === 'Teacher' ? (
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from='/' to='/teacher' />
				) : userType === 'Admin' ? (
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from='/' to='/admin' />
				) : (
					<Redirect to='error/error-v2' />
				)}
				<ContentRoute path='/student' component={StudentPage} />
				<ContentRoute path='/teacher' component={TeacherPage} />
				<ContentRoute path='/admin' component={AdminPage} />
				<ContentRoute path='/leaderBoard' component={LeaderBoardPage} />
				<Redirect to='error/error-v2' />
			</Switch>
		</Suspense>
	)
}
