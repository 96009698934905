import React, { Fragment, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'

import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../../../_metronic/_helpers'

import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { useQuestionUIContext } from '../QuestionUIContext'
import * as uiHelpers from '../QuestionUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/question/questionAction'
import { questionSlice } from '../../../../_redux/question/questionSlice'

import { QuestionFilter } from './question-filter/QuestionFilter'

const question_slice = questionSlice.actions

const QuestionList = ({ category, course, intl }) => {
  const questionUIContext = useQuestionUIContext()
  const questionUIProps = useMemo(() => {
    return {
      ids: questionUIContext.ids,
      setIds: questionUIContext.setIds,
      queryParams: questionUIContext.queryParams,
      setQueryParams: questionUIContext.setQueryParams,
      newQuestionButtonClick: questionUIContext.newQuestionButtonClick,
      openViewQuestionDialog: questionUIContext.openViewQuestionDialog,
      openDeleteQuestionDialog: questionUIContext.openDeleteQuestionDialog,
      openEditQuestionPage: questionUIContext.openEditQuestionPage,
    }
  }, [questionUIContext])

  // Getting curret state of quiz list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.question,
    }),
    shallowEqual
  )

  const { totalCount, entities, listLoading } = currentState

  // question Redux state
  const dispatch = useDispatch()
  useEffect(() => {
    // clear selections list
    questionUIProps.setIds([])
    // server call by queryParams

    //initially make empty
    let totalCount = 0
    let entities = []
    dispatch(question_slice.questionsFetched({ totalCount, entities }))
    dispatch(actions.fetchQuestions(questionUIProps.queryParams, category, course))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionUIProps.queryParams, dispatch])

  // Table columns
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: 'questionText',
      text: `${intl.formatMessage({ id: 'QUESTION' })}`,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openViewQuestionDialog: questionUIProps.openViewQuestionDialog,
        openDeleteQuestionDialog: questionUIProps.openDeleteQuestionDialog,
        openEditQuestionPage: questionUIProps.openEditQuestionPage,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: 125,
      },
    },
  ]

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: questionUIProps.queryParams.pageSize,
    page: questionUIProps.queryParams.pageNumber,
  }

  return (
    <Fragment>
      <QuestionFilter />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps} variant="primary">
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center text-dark"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(questionUIProps.setQueryParams)}
                selectRow={{ mode: 'radio', hideSelectColumn: true }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </Fragment>
  )
}

export default injectIntl(QuestionList)
