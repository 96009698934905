import React, { Fragment, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import ReactStars from "react-rating-stars-component";
import BootstrapTable from 'react-bootstrap-table-next'
import { Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../.././../_metronic/_partials/controls'
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from '../../../../../../../../_metronic/_helpers'
import { toAbsoluteUrl, formatTime } from '../../../../../../../../_metronic/_helpers'
import { Pagination } from '../../../../../../../../_metronic/_partials/controls'

import { useQuizViewStatsUIContext } from '../QuizViewStatsUIContext'
import * as uiHelpers from '../QuizViewStatsUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../../_redux/takenQuiz/takenQuizAction'
import { takenQuizSlice } from '../../../../../_redux/takenQuiz/takenQuizSlice'

import { QuizViewStatsFilter } from '../quiz-view-stats-filter/QuizViewStatsFilter'
import {QuizViewStatsGrouping} from '../quiz-view-stats-grouping/QuizViewStatsGrouping';

const takenQuiz_slice = takenQuizSlice.actions

const QuizViewStatsTable = ({ qId, history, intl }) => {
    const quizViewStatsUIContext = useQuizViewStatsUIContext()
    const quizViewStatsUIProps = useMemo(() => {
        return {
            ids: quizViewStatsUIContext.ids,
            setIds: quizViewStatsUIContext.setIds,
            queryParams: quizViewStatsUIContext.queryParams,
            setQueryParams: quizViewStatsUIContext.setQueryParams,
            downloadCertificateDialog: quizViewStatsUIContext.downloadCertificateDialog,
            emailQuizDialog: quizViewStatsUIContext.emailQuizDialog,
        }
    }, [quizViewStatsUIContext])

    // Getting curret state of quiz list from store (Redux)
    const { user, currentState } = useSelector(
        (state) => ({
            user: state.auth.user,
            currentState: state.takenQuiz,
        }),
        shallowEqual
    )

    const { totalCount, entities, entityType, listLoading } = currentState

    // taken quiz Redux state
    const dispatch = useDispatch()
    useEffect(() => {
        // clear selections list
        quizViewStatsUIProps.setIds([])
        // server call by queryParams

        //initially make empty
        let totalCount = 0
        let entities = []
        dispatch(takenQuiz_slice.takenQuizFetched({ totalCount, entities, entityType: "" }))
        dispatch(actions.fetchTakenQuizByTeacherIdAndQId(qId, quizViewStatsUIProps.queryParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizViewStatsUIProps.queryParams, dispatch])

    // const downloadCSV = () => {
    //     dispatch(actions.downloadCSV(user.userid))
    // }

    // Table columns
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: 'studentName',
            text: `${intl.formatMessage({ id: 'STUDENT_NAME' })}`,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: {
                minWidth: 135,
            },
        },
        {
            dataField: 'studentEmail',
            text: `${intl.formatMessage({ id: 'STUDENT_EMAIL' })}`,
            style: {
                minWidth: 135,
            },
        },
        {
            dataField: 'percentage',
            text: `${intl.formatMessage({ id: 'SCORE' })}`,
            classes: 'text-center',
            headerClasses: 'text-center',
        },
        {
            dataField: 'totalAttempts',
            text: `${intl.formatMessage({ id: 'ATTEMPTS' })}`,
            classes: 'text-center',
            headerClasses: 'text-center',
        },
        {
            dataField: 'timeTaken',
            text: `${intl.formatMessage({ id: 'TIME_MM:SS' })}`,
            formatter:(cell,row) => {
                return formatTime(row.timeTaken)
            },
            classes: 'text-center',
            headerClasses: 'text-center',
        },
        {
            dataField: "rating",
            text: `${intl.formatMessage({ id: "RATING" })}`,
            headerClasses: "text-center",
            formatter:(cell,row) => {
                return <div className="d-flex justify-content-center">
                    <ReactStars
                        count={5}
                        value={cell ? parseInt(cell) : 0}
                        size={24}
                        edit={false}
                        activeColor="#ffd700"
                    />
                </div>
            }
        },
        {
            dataField: 'status',
            text: `${intl.formatMessage({ id: 'STATUS' })}`,
        },
        {
            dataField: 'action',
            text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                downloadCertificateDialog: quizViewStatsUIProps.downloadCertificateDialog,
                emailQuizDialog: quizViewStatsUIProps.emailQuizDialog,
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: 90,
            },
        },
    ]

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: quizViewStatsUIProps.queryParams.pageSize,
        page: quizViewStatsUIProps.queryParams.pageNumber,
    }

    return (
        <Fragment>
            <Card>
                <CardHeader title={<FormattedMessage id='VIEW_STATS'></FormattedMessage>}>
                    <CardHeaderToolbar>
                        <Button
                            variant='secondary'
                            size='sm'
                            onClick={() => {
                                history.push('/teacher/quizPool')
                            }}
                        >
                            <span className='svg-icon svg-icon-md svg-icon-dark'>
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-left.svg')} />
                            </span>
                            <FormattedMessage id='GO_BACK'></FormattedMessage>
                        </Button>
                        {/* <Button variant='primary' size='sm' className='ml-3' onClick={() => downloadCSV()}>
                            <span className='svg-icon svg-icon-md svg-icon-white'>
                                <SVG src={toAbsoluteUrl('/media/svg/icons/Files/DownloadedFile.svg')} />
                            </span>
                            <FormattedMessage id='DOWNLOAD_CSV'></FormattedMessage>
                        </Button> */}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <QuizViewStatsFilter />
                    {quizViewStatsUIProps && quizViewStatsUIProps.ids.length > 0 && <QuizViewStatsGrouping qId={qId} />}
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => {
                            return (
                                <Pagination isLoading={listLoading || entities === null || entityType !=="stats"} paginationProps={paginationProps} variant='primary'>
                                    <BootstrapTable
                                        wrapperClasses='table-responsive'
                                        bordered={false}
                                        classes='table table-head-custom table-vertical-center'
                                        bootstrap4
                                        remote
                                        keyField='id'
                                        data={entities === null || entityType !=="stats" ? [] : entities}
                                        columns={columns}
                                        defaultSorted={uiHelpers.defaultSorted}
                                        onTableChange={getHandlerTableChange(quizViewStatsUIProps.setQueryParams)}
                                        //For selecting columns
                                        selectRow={getSelectRow({
                                            entities,
                                            ids: quizViewStatsUIProps.ids,
                                            setIds: quizViewStatsUIProps.setIds,
                                        })}
                                        {...paginationTableProps}
                                    >
                                        <PleaseWaitMessage entities={entities} />
                                        <NoRecordsFoundMessage entities={entities} />
                                    </BootstrapTable>
                                </Pagination>
                            )
                        }}
                    </PaginationProvider>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default injectIntl(QuizViewStatsTable)
