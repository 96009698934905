import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import {
	LayoutSplashScreen,
	ContentRoute,
} from '../../../../../_metronic/layout'

// import DashboardPage from './dashboard/DashboardPage'
import QuizPoolPage from './quizPool/QuizPoolPage'
import TakenQuizPage from './takenQuiz/TakenQuizPage'
import QuizTakePage from './quizPool/quiz-take-page/QuizTakePage'
import ResultPage from './takenQuiz/taken-quiz-result/TakenQuizResultPage'
import HistoryResultPage from './takenQuiz/history-quiz-result/HistoryQuizResultPage'
import { SubscriptionTable } from './subscription/SubscriptionTable'
// import QuizTakePage from './quizPool/quiz-take/QuizTakePage'

// import QuizProcess1 from './quizPool/quiz-take/quiz-process/QuizProcess1'
// import QuizProcess2 from './quizPool/quiz-take/quiz-process/QuizProcess2'

const StudentPage = () => {
	const { userType } = useSelector(
		(state) => ({
			userType: state.auth.user.userType,
		}),
		shallowEqual,
	)

	return userType !== 'Student' ? (
		<Redirect to='/' />
	) : (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact={true} from='/student' to='/student/quizPool' />}
				{/* <ContentRoute path='/student/dashboard' component={DashboardPage} /> */}
				<ContentRoute
					path='/student/quizPool/:id/take'
					component={QuizTakePage}
				/>
				<ContentRoute path='/student/quizPool' component={QuizPoolPage} />
				<ContentRoute
					path='/student/takenQuiz/:id/result'
					component={ResultPage}
				/>
				<ContentRoute
					path='/student/takenQuiz/:id/newResult'
					component={HistoryResultPage}
				/>
				<ContentRoute path='/student/takenQuiz' component={TakenQuizPage} />
				<ContentRoute
					path='/student/subscription'
					component={SubscriptionTable}
				/>
				{/* <ContentRoute path='/student/quiz/:id/take' component={QuizTakePage} />
                    <ContentRoute path='/student/quiz/:id/take1' component={QuizProcess1} />
                    <ContentRoute path='/student/quiz/:id/take2' component={QuizProcess2} /> */}
				<Redirect to='../error/error-v2' />
			</Switch>
		</Suspense>
	)
}

export default StudentPage
