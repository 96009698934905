import React from 'react'
// import { Route } from 'react-router-dom'


import { QuizUIProvider } from './QuizUIContext'
import QuizTable from './quiz-table/QuizTable'

// import QuizTakeOption from './quiz-take/quiz-take-option/QuizTakeOption'

const QuizPoolPage = ({ history }) => {
    const quizUIEvents = {
        quizTakePage: (id) => {
            history.push(`/student/quizPool/${id}/take`);
        }
    }

    return (
        <QuizUIProvider quizUIEvents={quizUIEvents}>
            {/* <Route path="/student/quizPool/:id/option">
                {({ history, match }) => (
                    <QuizTakeOption
                        show={match != null}
                        id={match && match.params.id}
                        history={history}
                        onHide={() => {
                            history.push("/student/quizPool");
                        }}
                    />
                )}
            </Route> */}
            <QuizTable />
        </QuizUIProvider>
    )
}

export default QuizPoolPage
