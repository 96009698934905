import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Spinner } from 'react-bootstrap'
import { Formik, Form } from "formik";
import { Select } from "../../../../../../../_metronic/_partials/controls";
import Alert from "@material-ui/lab/Alert";
import { Button } from "react-bootstrap";

import * as actions from "../../../../_redux/categoryCourse/categoryCourseAction";

export function QuestionCategoryCourseForm({ formData, saveFormData }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { listLoading, entities } = useSelector(
    (state) => ({
      listLoading: state.categoryCourse.listLoading,
      entities: state.categoryCourse.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    const queryParams = {
      filter: {
        category: "",
      },
      sortOrder: "asc", // asc||desc
      sortField: "category",
      pageNumber: 1,
      pageSize: 100,
    };
    dispatch(actions.fetchAllCategoryCourse(queryParams));
  }, [dispatch]);

  const [courList, setCourList] = useState({ data: [] });

  const onCategoryChange = (e) => {
    const obj = entities.find((c) => c.category === e.target.value);
    if (obj) {
      setCourList({ data: obj.course });
    }
  };
  const [cat, setCat] = useState("");
  const [cour, setCour] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);

  return (
    <Fragment>
      {alertStatus && (
        <Alert
          severity="error"
          onClose={() => {
            setAlertStatus(false);
          }}
          className="mb-4"
        >
          <FormattedMessage id="ALERT.REQUIRED_CATEGORY & COURSE"></FormattedMessage>
        </Alert>
      )}
      {listLoading &&(
        <div className="text-center p-2">
        <Spinner animation="border" variant="dark" />
        </div>
      )}
      {!listLoading && entities && entities.length > 0 && (
        <Formik
          enableReinitialize={true}
          initialValues={formData}
          onSubmit={() => {
            if (cat !== "" && cour !== "") {
              saveFormData(cat, cour);
            } else {
              setAlertStatus(true);
              setTimeout(() => {
                setAlertStatus(false);
              }, 4000);
            }
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Form className="form form-label-right">
                <div className="form-group">
                  <Select
                    name="category"
                    label={<FormattedMessage id="CATEGORY"></FormattedMessage>}
                    value={cat}
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      onCategoryChange(e);
                      setCat(e.target.value);
                      setCour("");
                    }}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "SELECT_ONE" })}
                    </option>
                    {entities.map((e) => (
                      <option key={e.id} value={e.category}>
                        {e.category}
                      </option>
                    ))}
                  </Select>
                  <div className="mt-4"></div>
                  <Select
                    name="course"
                    label={<FormattedMessage id="COURSE"></FormattedMessage>}
                    value={cour}
                    withFeedbackLabel={false}
                    onChange={(e) => setCour(e.target.value)}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "SELECT_ONE" })}
                    </option>
                    {courList.data.map((c) => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
                  </Select>
                </div>
                <Button type="submit" variant="primary" onSubmit={handleSubmit}>
                  <FormattedMessage id="NEXT"></FormattedMessage>
                </Button>
              </Form>
            </>
          )}
        </Formik>
      )}
      {!listLoading && entities && entities.length === 0 && (
        <p>No categories and courses found. Please create one first</p>
      )}
    </Fragment>
  );
}
