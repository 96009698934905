import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
// import { quizesSlice } from '../app/modules/Application/redux/Quiz/quizSlice'
import { quizSlice } from '../app/modules/Application/_redux/quiz/quizSlice'
import { questionSlice } from '../app/modules/Application/_redux/question/questionSlice'
import { feedbackSlice } from '../app/modules/Application/_redux/feedback/feedbackSlice'
import { takenQuizSlice } from '../app/modules/Application/_redux/takenQuiz/takenQuizSlice'
import { categoryCourseSlice } from '../app/modules/Application/_redux/categoryCourse/categoryCourseSlice'
import { adminSettingSlice } from '../app/modules/Application/_redux/adminSetting/adminSettingSlice'
import { tagsSlice } from '../app/modules/Application/_redux/tags/tagsSlice'
import { subscriptionsSlice } from '../app/modules/Application/_redux/subscriptions/subscriptionsSlice'
import { settingsSlice } from '../app/modules/Application/_redux/settings/settingsSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	quiz: quizSlice.reducer,
	question: questionSlice.reducer,
	feedback: feedbackSlice.reducer,
	takenQuiz: takenQuizSlice.reducer,
	categoryCourse: categoryCourseSlice.reducer,
	adminSetting: adminSettingSlice.reducer,
	tags: tagsSlice.reducer,
	subscriptions: subscriptionsSlice.reducer,
	settings: settingsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
