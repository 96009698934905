import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'

import Swal from 'sweetalert2'

import { startQuiz } from '../../../../../_redux/takenQuiz/takenQuizCrud'

const QuizInstructionPage = ({
	setShowRatingControls,
	nextPlace,
	history,
	quizId,
	goBack,
}) => {
	const startingQuiz = async () => {
		let response = await startQuiz(quizId)
		if (response.status === 200) {
			nextPlace()
			setShowRatingControls(true)
		} else {
			await goBack()
			let { message } = response.data
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: `<strong>${message}</strong>`,
			}).then(async () => {
				history.push('/student/quizPool')
			})
		}
	}

	return (
		<div style={style.root} className='bg-light rounded'>
			<h4 className='text-center'>
				<FormattedMessage id='QUIZ_GUIDLINES'></FormattedMessage>
			</h4>
			<p className='text-justify mb-8'>
				<p>
					This Salesforce Certification Preparation Quiz has been designed to
					enhance your learning experience with BlackForce. Please note that the
					questions provided in this quiz may resemble the actual certification
					exam, but they do not accurately replicate the exam questions or its
					level of difficulty. Additionally, some questions have been gathered
					from various online sources, and they have been verified to the best
					of our knowledge.
				</p>{' '}
				<p>
					Disclaimer: The answers given in this quiz are based on our collective
					understanding of the Salesforce Platform. For the best chance of
					passing the Salesforce Administrator exam, we strongly suggest that
					you supplement your studies with other resources such as Class Videos,
					Tutorial Sessions, Clinic Hours, and Trailhead activities.
				</p>{' '}
				Good Luck!
			</p>
			<Button variant='primary' block onClick={() => startingQuiz()}>
				<FormattedMessage id='START'></FormattedMessage>
			</Button>
		</div>
	)
}

const style = {
	root: {
		width: '90%',
		maxWidth: 400,
		margin: '30px auto',
		padding: '30px 50px',
	},
}

export default QuizInstructionPage
