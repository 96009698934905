import Swal from 'sweetalert2'

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				auth: { authToken },
			} = store.getState()

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`
				config.headers.fullurl = window.location.href
			}

			return config
		},
		(err) => Promise.reject(err),
	)
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (error.response.status === 400 || error.response.status === 500) {
				return {
					...error.response,
				}
			} else if (error.response.status === 401) {
				Swal.fire({
					icon: 'error',
					title: 'Session expired',
					html: `Your session has expired. Login again`,
				}).then(() => {
					window.location.href = '/logout'
				})
			}
			return error
		},
	)
}
