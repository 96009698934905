import React, { useMemo, useEffect } from 'react'
import objectPath from 'object-path'
// import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from '../../_core/MetronicLayout'
// import {SearchDropdown} from "../extras/dropdowns/search/SearchDropdown";
// import {UserNotificationsDropdown} from "../extras/dropdowns/UserNotificationsDropdown";
// import {QuickActionsDropdown} from "../extras/dropdowns/QuickActionsDropdown";
// import {MyCartDropdown} from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown'
import { QuickUserToggler } from '../extras/QuiclUserToggler'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as authRedux from '../../../../app/modules/Auth/_redux/authRedux'
import * as actions from '../../../../app/modules/Application/_redux/adminSetting/adminSettingAction'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_helpers'

export function Topbar() {
	const uiService = useHtmlClassService()
	const history = useHistory()
	const dispatch = useDispatch()

	const { microsolution } = useSelector(
		(state) => ({
			microsolution: state.auth.microsolution,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(actions.fetchAdminSetting())
	}, [])

	const { user, adminSetting } = useSelector(
		(state) => ({
			user: state.auth.user,
			adminSetting: state.adminSetting.currentAdminSetting,
		}),
		shallowEqual,
	)

	const { userType, role_level, profile } = user

	const switchToOtherUser = (current_user_type) => {
		//if user is student, switch to teacher, if teacher then switch to student
		//and load their pages
		let new_user_type = current_user_type === 'Student' ? 'Teacher' : 'Student'
		let new_user = _.cloneDeep(user)
		new_user.userType = new_user_type
		dispatch(authRedux.actions.fulfillUser(new_user))
		history.push('/' + new_user_type.toLowerCase() + '/quizPool')
	}

	const layoutProps = useMemo(() => {
		return {
			// viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
			// viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
			// viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
			// viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
			viewQuickPanelDisplay: objectPath.get(
				uiService.config,
				'extras.quick-panel.display',
			),
			viewLanguagesDisplay: objectPath.get(
				uiService.config,
				'extras.languages.display',
			),
			viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
		}
	}, [uiService])

	return (
		<div className='topbar'>
			{layoutProps.viewQuickPanelDisplay && microsolution && (
				<OverlayTrigger
					placement='bottom'
					overlay={<Tooltip id='quick-panel-tooltip'>View Apps</Tooltip>}
				>
					<div
						className='topbar-item'
						data-toggle='tooltip'
						title='Quick panel'
						data-placement='right'
					>
						<div
							className='btn btn-icon btn-clean btn-lg mr-1'
							id='kt_quick_panel_toggle'
						>
							<span className='svg-icon svg-icon-xl svg-icon-primary'>
								<SVG
									src={toAbsoluteUrl(
										'/media/svg/icons/Layout/Layout-4-blocks.svg',
									)}
								/>
							</span>
						</div>
					</div>
				</OverlayTrigger>
			)}
			{/* {layoutProps.viewSearchDisplay && (<SearchDropdown/>)}

            {layoutProps.viewNotificationsDisplay && (<UserNotificationsDropdown/>)}

            {layoutProps.viewQuickActionsDisplay && (<QuickActionsDropdown/>)}

    {layoutProps.viewCartDisplay && (<MyCartDropdown/>)}*/}

{/*
			{adminSetting &&
				role_level <= adminSetting.maximumRoleLevelForTeacher &&
				layoutProps.viewQuickPanelDisplay &&
				(userType === 'Student' || userType === 'Teacher') && (
					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip id='quick-panel-tooltip'>
								Switch to {userType === 'Student' && <span>Teacher</span>}
								{userType === 'Teacher' && <span>Student</span>}
							</Tooltip>
						}
					>
						<div
							className='topbar-item'
							data-toggle='tooltip'
							title='Quick panel'
							data-placement='right'
						>
							<div
								style={style.box}
								className='btn btn-icon btn-clean btn-lg mr-1'
								onClick={() => switchToOtherUser(userType)}
							>
								<span
									style={style.switch}
									className='text-dark-50 font-weight-bold'
								>
									Switch to {userType === 'Student' && <span>Teacher</span>}
									{userType === 'Teacher' && <span>Student</span>}
								</span>
							</div>
						</div>
					</OverlayTrigger>
				)}
				*/}

			{adminSetting &&
				// adminSetting.internalizationSettingAllowed === 'Yes' &&
				(adminSetting.internalizationSettingAllowed === 'Yes' ||
					profile === parseInt(process.env.REACT_APP_OWNER_PROFILE_ID)) &&
				layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
			{layoutProps.viewUserDisplay && <QuickUserToggler />}
		</div>
	)
}

const style = {
	box: {
		width: 'fit-content',
	},
	switch: {
		fontSize: 12,
	},
}
