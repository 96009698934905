import React, { Fragment, useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Spinner } from 'react-bootstrap'
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../../../_metronic/_partials/controls'

import Swal from 'sweetalert2'

import * as actions from '../../../_redux/adminSetting/adminSettingAction'
import { updateAdminSetting } from '../../../_redux/adminSetting/adminSettingCrud'

import { QuizSettingForm } from './QuizSettingForm'

const QuizSettingPage = () => {
	const dispatch = useDispatch()
	const { currentState } = useSelector(
		(state) => ({
			currentState: state.adminSetting,
		}),
		shallowEqual,
	)

	const { currentAdminSetting, actionsLoading } = currentState

	useEffect(() => {
		dispatch(actions.fetchAdminSetting())
	}, [dispatch])

	useEffect(() => {
		if (currentAdminSetting)
			setFormData({
				quizTimeAllowed: currentAdminSetting.quizTimeSettingAllowed,
				questionTimeAllowed: currentAdminSetting.questionTimeSettingAllowed,
				negativeMarking: currentAdminSetting.negativeMarkingSettingAllowed,
				showAnswerSettingAllowed: currentAdminSetting.showAnswerSettingAllowed,
				passwordQuiz: currentAdminSetting.passwordQuizSettingAllowed,
				internalization: currentAdminSetting.internalizationSettingAllowed,
				maximumRoleLevelForTeacher:
					currentAdminSetting.maximumRoleLevelForTeacher,
				enableSubscription: currentAdminSetting.enableSubscription,
			})
	}, [currentAdminSetting])

	const [formData, setFormData] = useState({
		quizTimeAllowed: '',
		questionTimeAllowed: '',
		negativeMarking: '',
		showAnswerSettingAllowed: '',
		passwordQuiz: '',
		internalization: '',
		maximumRoleLevelForTeacher: 4,
		enableSubscription: '',
	})

	const [loading, setLoading] = useState('')

	useEffect(() => {
		if (!actionsLoading) {
			setLoading('')
		}
	}, [actionsLoading])

	const saveSetting = async () => {
		setLoading('saving')

		let response = await updateAdminSetting({
			quizTimeSettingAllowed: formData.quizTimeAllowed,
			questionTimeSettingAllowed: formData.questionTimeAllowed,
			negativeMarkingSettingAllowed: formData.negativeMarking,
			showAnswerSettingAllowed: formData.showAnswerSettingAllowed,
			passwordQuizSettingAllowed: formData.passwordQuiz,
			internalizationSettingAllowed: formData.internalization,
			maximumRoleLevelForTeacher: formData.maximumRoleLevelForTeacher,
			enableSubscription: formData.enableSubscription,
		})

		if (response.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'Admin setting updated',
				text: 'Your new admin setting has been updated',
				confirmButtonColor: '#1BC5BD',
			})
			dispatch(actions.fetchAdminSetting())
		} else {
			let { message } = response.data
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: `<strong>${message}</strong>`,
			})
			dispatch(actions.fetchAdminSetting())
		}
		setLoading('')
	}

	return (
		<Fragment>
			<Card>
				<CardHeader
					title={<FormattedMessage id='NAV.QUIZ_SETTING'></FormattedMessage>}
				></CardHeader>
				<CardBody>
					{currentAdminSetting ? (
						<QuizSettingForm
							formData={formData}
							setFormData={setFormData}
							saveSetting={saveSetting}
							loading={loading}
						/>
					) : (
						<div className='text-center p-2'>
							<Spinner animation='border' variant='dark' />
						</div>
					)}
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default QuizSettingPage
