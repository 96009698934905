/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch, useStore } from 'react-redux'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers/AssetsHelpers'
import { NewSubscriptionModal } from './NewSubscriptionModal'
import {
	fetchAllSubscriptions,
	updateStatus,
	deleteSubscription,
	updateAutoCharge,
} from '../../../_redux/subscriptions/subscriptionsActions'
import swal from 'sweetalert2'

export function SubscriptionTable({ currency }) {
	const store = useStore()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const [modalShow, setModalShow] = useState(false)
	const [editData, setEditData] = useState()
	const { subscriptions } = useSelector(
		(state) => ({
			subscriptions: state.subscriptions.entities,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(fetchAllSubscriptions())
	}, [dispatch])
	const updateSubscriptionStatus = (id, status) => {
		setLoading(true)
		dispatch(updateStatus({ id, status })).then(() => setLoading(false))
	}
	const updateAutoSub = (id, auto_charge) => {
		setLoading(true)
		dispatch(updateAutoCharge({ id, auto_charge })).then(() =>
			setLoading(false),
		)
	}
	const deleteSub = (id) => {
		dispatch(deleteSubscription(id))
			.then(() => {
				let error_state = store.getState().subscriptions.error
				if (error_state !== null) {
					swal.fire('Oops', error_state, 'error')
				} else {
					swal.fire('success', 'Subscription deleted successfully', 'success')
				}
			})
			.catch((error) => {
				swal.fire('Oops', error, 'error')
			})
	}
	return (
		<>
			<div id='something' className={`card card-custom card-stretch gutter-b`}>
				{/* Head */}
				<div className='card-header border-0 py-5'>
					<h3 className='card-title align-items-start flex-column'>
						<span className='card-label font-weight-bolder text-dark'>
							Subscription Table
						</span>
						<span className='text-muted mt-3 font-weight-bold font-size-sm'>
							List of available subscription(s) in business
						</span>
					</h3>
					{!subscriptions?.length > 0 && (
						<div className='card-toolbar'>
							<button
								type='button'
								onClick={() => setModalShow(true)}
								className='btn btn-danger font-weight-bolder font-size-sm'
							>
								New Subscription
							</button>
						</div>
					)}
				</div>
				{/* Body */}
				<div className='card-body pt-0 pb-3'>
					<div className='tab-content'>
						<div className='table-responsive'>
							<table className='table table-head-custom table-head-bg table-borderless table-vertical-center'>
								<thead>
									<tr className='text-left text-uppercase'>
										<th style={{ minWidth: '100px' }}>title</th>
										<th style={{ minWidth: '120px' }}>price ({currency})</th>
										<th style={{ minWidth: '100px' }}>intervals</th>
										<th style={{ minWidth: '50px' }}>Auto Charge</th>
										<th style={{ minWidth: '50px' }}>Status</th>
										<th style={{ minWidth: '50px' }} />
									</tr>
								</thead>
								<tbody>
									{subscriptions.map((subscription, index) => (
										<tr key={index}>
											<td>
												<span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
													{subscription.title}
												</span>
											</td>
											<td>
												<span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
													{subscription.price}
												</span>
											</td>
											<td>
												<span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
													{subscription.interval}
												</span>
											</td>
											<td>
												<span className='text-dark-75 font-weight-bolder d-block font-size-sm'>
													<span className='switch switch-sm'>
														<label>
															<input
																type='checkbox'
																name='status'
																checked={subscription.auto_charge}
																onChange={(e) =>
																	updateAutoSub(
																		subscription.id,
																		e.target.checked,
																	)
																}
																disabled={loading}
															/>
															<span></span>
														</label>
													</span>
												</span>
											</td>
											<td>
												<span className='text-dark-75 font-weight-bolder d-block font-size-sm'>
													<span className='switch switch-sm'>
														<label>
															<input
																type='checkbox'
																name='status'
																checked={subscription.status}
																onChange={(e) =>
																	updateSubscriptionStatus(
																		subscription.id,
																		e.target.checked,
																	)
																}
																disabled={loading}
															/>
															<span></span>
														</label>
													</span>
												</span>
											</td>
											<td className='pr-0 text-right'>
												{/* <button
													className='btn btn-icon btn-light btn-sm mx-1'
													type='button'
													onClick={() => {
														setEditData(subscription)
														setModalShow(true)
													}}
													// onClick={() => props.edit(entity.id)}
												>
													<span className='svg-icon svg-icon-md svg-icon-warning'>
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Communication/Write.svg',
															)}
														></SVG>
													</span>
												</button> */}
												<button
													className='btn btn-icon btn-light btn-sm'
													type='button'
													onClick={() => deleteSub(subscription.id)}
													// onClick={() => props.delete(entity.id)}
												>
													<span className='svg-icon svg-icon-md svg-icon-danger'>
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/General/Trash.svg',
															)}
														></SVG>
													</span>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<NewSubscriptionModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				editData={editData}
			/>
		</>
	)
}
