import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_ENDPOINT + '/api/subscriptions'

// CREATE =>  POST: add a new subscription to the server
export function createSubscription(subscription) {
	return axios.post(API_URL, subscription)
}

// READ
export function getAllSubscriptions() {
	return axios.get(API_URL)
}

// UPDATE => PUT: update the procuct on the server
export function updateSubscription(subscription) {
	return axios.put(`${API_URL}`, subscription)
}

// UPDATE => PUT: update the procuct on the server
export function updateStatus(subscription) {
	return axios.put(`${API_URL}/updateStatus`, { subscription })
}

// DELETE => delete the subscription from the server
export function deleteSubscription(subscriptionId) {
	return axios.delete(`${API_URL}/${subscriptionId}`)
}

export function openCheckSection(data) {
	return axios.post(API_URL + '/create-checkout-session', data)
}

export function manageBilling() {
	return axios.get(API_URL + '/manage_billing')
}

export function createSubscriber(data) {
	return axios.get(API_URL + '/subscriber?sessionId=' + data)
}
