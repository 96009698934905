import axios from 'axios'

const APIURL = process.env.REACT_APP_API_ENDPOINT

export const GET_ALL_CATEGORY_COURSE_URL = '/api/getAllCategoryCourse'
export const ADD_CATEGORY_COURSE_URL = '/api/categoryCourse'

export function getAllCategoryCourse(queryParams) {
  return axios.post(APIURL + GET_ALL_CATEGORY_COURSE_URL, { queryParams })
}

export function addCategoryCourse(categoryCourseObject) {
  return axios.post(APIURL + ADD_CATEGORY_COURSE_URL, categoryCourseObject)
}
