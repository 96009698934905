import React, { Fragment, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEqual } from 'lodash'
import { useQuizUIContext } from '../QuizUIContext'

import { makeStyles } from '@material-ui/core'

const prepareFilter = (queryParams, searchText, mode) => {
	const newQueryParams = { ...queryParams }
	const filter = { ...newQueryParams.filter }
	if (mode === 'search') {
		filter.quizName = searchText
		filter.quizDescription = searchText
	}
	if (mode === 'status')
		filter.status = searchText !== '' ? searchText : undefined
	newQueryParams.filter = filter
	return newQueryParams
}

export const QuizFilter = () => {
	// Quiz UI Context
	const quizUIContext = useQuizUIContext()
	const quizUIProps = useMemo(() => {
		return {
			setQueryParams: quizUIContext.setQueryParams,
			queryParams: quizUIContext.queryParams,
		}
	}, [quizUIContext])

	const [searchText, setSearchText] = useState('')
	const [statusText, setStatusText] = useState('')

	const setSearchTextHandler = (value, mode) => {
		mode === 'status' ? setStatusText(value) : setSearchText(value)
		applyFilter(value, mode)
	}

	const applyFilter = (values, mode) => {
		const newQueryParams = prepareFilter(quizUIProps.queryParams, values, mode)
		if (!isEqual(newQueryParams, quizUIProps.queryParams)) {
			newQueryParams.pageNumber = 1
			quizUIProps.setQueryParams(newQueryParams)
		}
	}

	const useStyles = makeStyles((theme) => ({
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '100%',
			maxWidth: 250,
		},
		button: {
			margin: theme.spacing(1),
			padding: theme.spacing(1),
			textTransform: 'none',
		},
	}))
	const classes = useStyles()

	return (
		<Fragment>
			<div className='row'>
				<div className='col-lg-3'>
					<input
						type='text'
						className='form-control'
						name='searchText'
						placeholder='Search'
						value={searchText}
						onChange={(e) => setSearchTextHandler(e.target.value, 'search')}
					/>
					<small className='form-text text-muted'>
						<FormattedMessage id='FILTER.SEARCH_BY_QUIZ_NAME | DESCRIPTION'></FormattedMessage>
					</small>
				</div>
				<div className='col-lg-3'>
					<select
						className='form-control'
						name='status'
						placeholder='Filter by Status'
						onChange={(e) => setSearchTextHandler(e.target.value, 'status')}
						value={statusText}
					>
						<option value=''>All</option>
						<option value='Published'>Published</option>
						<option value='Draft'>Draft</option>
					</select>
					<small className='form-text text-muted'>
						<b>Filter</b> by Status
					</small>
				</div>
				{/* <TextField
					id='outlined-search'
					label={<FormattedMessage id='FILTER.SEARCH'></FormattedMessage>}
					className={classes.textField}
					value={searchText}
					onChange={(e) => setSearchTextHandler(e.target.value)}
					margin='dense'
					variant='outlined'
					color='primary'
					helperText={
						<FormattedMessage id='FILTER.SEARCH_BY_QUIZ_NAME | DESCRIPTION'></FormattedMessage>
					}
				/> */}
			</div>
		</Fragment>
	)
}
