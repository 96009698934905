import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useStore } from 'react-redux'
import {
	createSubscription,
	updateSubscription,
} from '../../../_redux/subscriptions/subscriptionsActions'
import swal from 'sweetalert2'

export function NewSubscriptionModal(props) {
	const store = useStore()
	const dispatch = useDispatch()
	// UI Helpers
	const initialValues = {
		id: props.editData?.id,
		title: props.editData?.title || '',
		price: props.editData?.price || '',
		description: props.editData?.description || '',
		interval: props.editData?.interval || '',
		status: props.editData?.status || undefined,
		auto_charge: props.editData?.auto_charge || true,
	}
	const Schema = Yup.object().shape({
		title: Yup.string()
			.min(2, 'Minimum 2 characters')
			.max(40, 'Maximum 40 characters')
			.required('Title is required'),
		description: Yup.string().max(255, 'Maximum 255 symbols'),
		price: Yup.number().required('Price is required'),
		interval: Yup.string().required('Interval is required'),
	})
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: Schema,
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true)
			if (props.editData?.id) {
				dispatch(updateSubscription(values))
					.then(() => {
						setSubmitting(false)
						let error_state = store.getState().subscriptions.error
						if (error_state !== null) {
							swal.fire('Oops', error_state, 'error')
						} else {
							swal.fire(
								'success',
								'Subscription updated successfully',
								'success',
							)
							props.onHide()
							resetForm()
						}
					})
					.catch((error) => {
						setSubmitting(false)
						swal.fire('Oops', error, 'error')
					})
			} else {
				dispatch(createSubscription(values))
					.then(() => {
						setSubmitting(false)
						let error_state = store.getState().subscriptions.error
						if (error_state !== null) {
							swal.fire('Oops', error_state, 'error')
						} else {
							swal.fire(
								'success',
								'Subscription created successfully',
								'success',
							)
							props.onHide()
							resetForm()
						}
					})
					.catch((error) => {
						setSubmitting(false)
						swal.fire('Oops', error, 'error')
					})
			}
		},
	})
	return (
		<>
			<Modal
				show={props.show}
				onHide={props.onHide}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Create subscription
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className='form'>
							{/* begin::Body */}
							<div className='card-body'>
								<div className='form-group row'>
									<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
										Title
									</label>
									<div className='col-lg-9 col-xl-6'>
										<div className='input-group input-group-lg input-group-solid'>
											<input
												placeholder='Subscription Title'
												className={`form-control form-control-lg form-control-solid ${getInputClasses(
													'title',
												)}`}
												name='title'
												{...formik.getFieldProps('title')}
											/>
										</div>
										{formik.touched.title && formik.errors.title ? (
											<div className='invalid-feedback display-block'>
												{formik.errors.title}
											</div>
										) : null}
									</div>
								</div>
								<div className='form-group row'>
									<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
										Price
									</label>
									<div className='col-lg-9 col-xl-6'>
										<div className='input-group input-group-lg input-group-solid'>
											<input
												type='number'
												placeholder='Subscription Price'
												className={`form-control form-control-lg form-control-solid ${getInputClasses(
													'price',
												)}`}
												{...formik.getFieldProps('price')}
											/>
										</div>
										{formik.touched.price && formik.errors.price ? (
											<div className='invalid-feedback display-block'>
												{formik.errors.price}
											</div>
										) : null}
									</div>
								</div>
								<div className='form-group row'>
									<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
										Interval
									</label>
									<div className='col-lg-9 col-xl-6'>
										<div className='input-group input-group-lg input-group-solid'>
											<select
												{...formik.getFieldProps('interval')}
												className={`form-control form-control-lg form-control-solid ${getInputClasses(
													'interval',
												)}`}
											>
												<option>--Select One--</option>
												<option value='day'>Daily</option>
												<option value='week'>Weekly</option>
												<option value='month'>Monthly</option>
												<option value='quarterly'>Quarterly</option>
												<option value='year'>Annually</option>
											</select>
											{/* <input
												type='number'
												placeholder='Duration of the Subscription'
												className={`form-control form-control-lg form-control-solid ${getInputClasses(
													'duration',
												)}`}
												{...formik.getFieldProps('duration')}
											/> */}
										</div>
										{formik.touched.duration && formik.errors.duration ? (
											<div className='invalid-feedback display-block'>
												{formik.errors.duration}
											</div>
										) : null}
									</div>
								</div>
								<div className='form-group row'>
									<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
										Auto Charge
									</label>
									<div className='col-lg-9 col-xl-6'>
										<span className='switch switch-sm'>
											<label>
												<input
													type='checkbox'
													name='auto_charge'
													checked={formik.values.auto_charge}
													onChange={formik.handleChange}
												/>
												<span></span>
											</label>
										</span>
										{formik.touched.duration && formik.errors.duration ? (
											<div className='invalid-feedback display-block'>
												{formik.errors.duration}
											</div>
										) : null}
									</div>
								</div>
								<div className='form-group row'>
									<label className='col-xl-4 col-form-label font-weight-bold text-left text-xl-right'>
										Description
									</label>
									<div className='col-lg-9 col-xl-6'>
										<textarea
											className='form-control form-control-solid'
											rows='3'
											{...formik.getFieldProps('description')}
										></textarea>
									</div>
								</div>
							</div>
							{/* end::Body */}
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={props.onHide}>Close</Button>
					<Button
						disabled={formik.isSubmitting}
						variant='success'
						onClick={formik.handleSubmit}
					>
						Submit
						{formik.isSubmitting && (
							<span className='spinner spinner-white ml-3 mr-3'></span>
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
