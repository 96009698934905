import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers"
import { Icon } from "@material-ui/core"

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { retakeQuizDialog, downloadCertificateDialog, openResultPage }
) => {
    return (
        <Fragment>
            <OverlayTrigger
                overlay={
                    <Tooltip id='quiz-view-tooltip'>
                        <FormattedMessage id='QUIZ_RESULT'></FormattedMessage>
                    </Tooltip>
                }
            >
                <div
                    className='btn btn-icon btn-sm btn-light btn-hover-primary text-primary'
                    onClick={() => openResultPage(row.id)}
                >
                    {/* <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-download.svg")} />
                    </span> */}
                    <Icon className='fas fa-eye w-75' />
                </div>
            </OverlayTrigger>
            <></>
            {row.remainingAttempts > 0 ? (
                <OverlayTrigger
                    overlay={
                        <Tooltip id='quiz-view-tooltip'>
                            <FormattedMessage id='RETAKE_ALLOWED'></FormattedMessage>
                        </Tooltip>
                    }
                >
                    <div
                        className='btn btn-icon btn-sm btn-light btn-hover-primary ml-3'
                        onClick={() => retakeQuizDialog(row.quizId)}
                    >
                        <span className='svg-icon svg-icon-md svg-icon-primary'>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                        </span>
                    </div>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    overlay={
                        <Tooltip id='quiz-view-tooltip'>
                            <FormattedMessage id='RETAKE_NOT_ALLOWED'></FormattedMessage>
                        </Tooltip>
                    }
                >
                    <div className='btn btn-icon btn-sm btn-light btn-hover-danger ml-3'>
                        <span className='svg-icon svg-icon-lg svg-icon-danger'>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                        </span>
                    </div>
                </OverlayTrigger>
            )}
            <></>
            <OverlayTrigger
                overlay={
                    <Tooltip id='quiz-view-tooltip'>
                        <FormattedMessage id='DOWNLOAD_CERTIFICATE'></FormattedMessage>
                    </Tooltip>
                }
            >
                <div
                    className='btn btn-icon btn-sm btn-light btn-hover-primary ml-3'
                    onClick={() => downloadCertificateDialog(row.id)}
                >
                    <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-download.svg")} />
                    </span>
                </div>
            </OverlayTrigger>
        </Fragment>
    )
}
