export default
    [
        {
            id: '72f874f237847',
            studentId: 1,
            teacherId: 2,
            studentName: 'Student one',
            studentEmail: 'student1@quizapp.com',
            quizId: 'fde91c61-8207-4791-9e5e-3badfffecf1d',
            quizName: 'Quiz Three name',
            quizDescription: 'Quiz three description',
            totalQuestion: 2,
            percentage: '80%',
            attemptDate: '11 / Dec / 2020',
            remainingAttempt: 1,
            totalAttempts: 2,
            timeTaken: 150,
            status: 'Pending',
        },
        {
            id: '742364877248',
            studentId: 1,
            teacherId: 2,
            studentName: 'Student one',
            studentEmail: 'student1@quizapp.com',
            quizId: '278404cd-d212-4ee6-a130-b6dafe77c111',
            quizName: 'Quiz One name',
            quizDescription: 'Quiz one description',
            totalQuestion: 3,
            percentage: '60%',
            attemptDate: '13 / Nov / 2020',
            remainingAttempt: 0,
            totalAttempts: 2,
            timeTaken: 120,
            status: 'Accepted',
        },
        {
            id: '748278428748',
            studentId: 5,
            teacherId: 2,
            studentName: 'Student two',
            studentEmail: 'student2@quizapp.com',
            quizId: '278404cd-d212-4ee6-a130-b6dafe77c111',
            quizName: 'Quiz One name',
            quizDescription: 'Quiz one description',
            totalQuestion: 3,
            percentage: '60%',
            attemptDate: '13 / Nov / 2020',
            remainingAttempt: 0,
            totalAttempts: 2,
            timeTaken: 115,
            status: 'Accepted',
        }
    ];