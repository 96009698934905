import React from 'react'
import { Modal } from 'react-bootstrap'
import { QuizEditDialogHeader } from './QuizEditDialogHeader'
import { QuizEditForm } from './QuizEditForm'

export function QuizEditDialog({ id, show, onHide }) {
	const hide = () => {
		onHide()
	}

	return (
		<>
			<Modal
				size='xl'
				show={show}
				onHide={hide}
				aria-labelledby='example-modal-sizes-title-lg'
				centered
			>
				<QuizEditDialogHeader id={id} />
				<QuizEditForm id={id} />
			</Modal>
		</>
	)
}
