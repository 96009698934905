import React, { Fragment, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Swal from 'sweetalert2'
import {
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import { Checkbox } from '@material-ui/core'
import {
	Button,
	Modal,
	OverlayTrigger,
	Tooltip,
	Spinner,
} from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import Alert from '@material-ui/lab/Alert'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'
import _ from 'lodash'
import QuestionAddPage from '../../questions/question-add-page/QuestionAddPage'

const QuestionBasedList = ({
	questions,
	category,
	course,
	selectedQuestions,
	setSelectedQuestions,
	totalQuestion,
	saveQuizAllDetails,
	loading,
}) => {
	const [qList, setQList] = useState({ data: [] })
	const [newlyAddedQuestions, setNewlyAddedQuestions] = useState({ data: [] })

	const [hidePrevious, setHidePrevious] = useState(false)
	const [alertStatus, setAlertStatus] = useState(false)
	const [showaddquestionmodal, showAddQuestionModal] = useState(false)

	const addquestionparams = { params: { category, course } }

	const onQsRemove = (index) => {
		if (qList.data.length === 1) {
			setAlertStatus(true)
			setTimeout(() => {
				setAlertStatus(false)
			}, 4000)
		} else {
			let newList = _.cloneDeep(qList.data)
			newList.splice(index, 1)
			setQList({ data: newList })
		}
	}

	const refreshQuestionsList = (question) => {
		let well_formed_data = {
			id: question.questionId,
			questionText: question.questionText,
		}

		//a new question is added, refresh the questions list
		showAddQuestionModal(false)

		let temp = _.cloneDeep(qList.data)
		temp.unshift(well_formed_data)
		setQList({ data: temp })

		//also keep track of the questions we have added newly
		let tempnewly = _.cloneDeep(newlyAddedQuestions.data)
		tempnewly.unshift(well_formed_data)
		setNewlyAddedQuestions({ data: tempnewly })
	}

	useEffect(() => {
		if (hidePrevious) {
			//only show new questions
			setQList({ data: newlyAddedQuestions.data })
		} else {
			//show newly created + previous ones
			let all_questions = newlyAddedQuestions.data.concat(questions)
			setQList({ data: all_questions })
		}
	}, [hidePrevious])

	const setSelectedQuestionsHandler = (event, qid) => {
		let sel = _.cloneDeep(selectedQuestions)

		if (event.target.checked) {
			sel.push(qid)

			if (sel.length > parseInt(totalQuestion)) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Total questions cannot be more than ' + totalQuestion,
				})

				return
			}
		} else {
			sel.splice(sel.indexOf(qid), 1)
		}

		setSelectedQuestions(sel)
	}

	const resetList = () => {
		if (questions !== null) {
			setQList({ data: questions })
		}
	}

	useEffect(() => {
		setQList({ data: questions })
	}, [questions])

	return (
		<Fragment>
			<Modal
				size='lg'
				show={showaddquestionmodal}
				onHide={showAddQuestionModal}
			>
				<Modal.Body>
					<QuestionAddPage
						refreshQuestionsList={refreshQuestionsList}
						openedinmodal={true}
						match={addquestionparams}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='primary'
						onClick={() => {
							showAddQuestionModal(!showaddquestionmodal)
						}}
					>
						<FormattedMessage id='CLOSE'></FormattedMessage>
					</Button>
				</Modal.Footer>
			</Modal>

			<CardHeader>
				<CardHeaderToolbar>
					<Button
						variant='secondary'
						size='sm'
						className='ml-3'
						onClick={resetList}
					>
						<span className='svg-icon svg-icon-md svg-icon-dark'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Media/Repeat.svg')} />
						</span>
						<FormattedMessage id='RESET_LIST'></FormattedMessage>
					</Button>
					<Button
						variant='primary'
						size='sm'
						className='ml-3'
						onClick={() => showAddQuestionModal(!showaddquestionmodal)}
					>
						<span className='svg-icon svg-icon-md svg-icon-white'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
						</span>
						<FormattedMessage id='CREATE_QUESTION'></FormattedMessage>
					</Button>
					<Button
						variant='primary'
						size='sm'
						className='ml-3'
						onClick={() => setHidePrevious(!hidePrevious)}
					>
						{hidePrevious ? (
							<FormattedMessage id='SHOW_PREVIOUS'></FormattedMessage>
						) : (
							<FormattedMessage id='HIDE_PREVIOUS'></FormattedMessage>
						)}
					</Button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody className='d-flex flex-column'>
				{alertStatus && (
					<Alert
						severity='error'
						onClose={() => {
							setAlertStatus(false)
						}}
						className='mb-4'
					>
						<FormattedMessage id='ALERT.ONE_QUESTION_SHOULD_REMAIN'></FormattedMessage>
					</Alert>
				)}
				{qList.data.map((q, index) => (
					<Fragment key={index}>
						<div className='d-flex align-items-center justify-content-between mb-2 pb-2 border-secondary border-bottom'>
							<p style={style.qMaxWidth}>
								<Checkbox
									className='mr-2'
									checked={selectedQuestions.indexOf(q.id) > -1 ? true : false}
									onChange={(event) => setSelectedQuestionsHandler(event, q.id)}
									value='checkedA'
									inputProps={{
										'aria-label': 'Select',
									}}
								/>
								{q.questionText}
							</p>
							<OverlayTrigger
								overlay={
									<Tooltip>
										<FormattedMessage id='REMOVE_QUESTION'></FormattedMessage>
									</Tooltip>
								}
							>
								<div
									className='btn btn-icon btn-sm btn-light btn-hover-danger'
									onClick={() => onQsRemove(index)}
								>
									<span className='svg-icon svg-icon-md svg-icon-danger'>
										<SVG
											src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
										/>
									</span>
								</div>
							</OverlayTrigger>
						</div>
					</Fragment>
				))}
				<Button
					id='checklast'
					className='d-none'
					variant='primary'
					style={style.btn}
					onClick={() => saveQuizAllDetails()}
				>
					<div className='d-flex align-items-center'>
						<FormattedMessage id='CREATE_QUIZ'></FormattedMessage>
						{!loading && (
							<Spinner animation='border' className='ml-2' variant='light' />
						)}
					</div>
				</Button>
			</CardBody>
		</Fragment>
	)
}

const style = {
	qMaxWidth: {
		margin: '0 30px 0 0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	btn: {
		width: 'fit-content',
		marginTop: '.5rem',
	},
}

export default QuestionBasedList
