import React from 'react'
import { Route } from 'react-router-dom'
import { QuestionUIProvider } from './QuestionUIContext'
import QuestionCard from './QuestionCard'
import QuestionViewDialog from './question-view-dialog/QuestionViewDialog'
import QuestionDeleteDialog from './question-delete-dialog/QuestionDeleteDialog'
import QuestionNewCatCouseDialog from './question-new-catCourse-dialog/QuestionNewCatCourseDialog'
import QuestionUploadCSVDialog from './question-uploadCSV-dialog/QuestionUploadCSVDialog'

const QuestionPage = ({ history }) => {
	let paramsraw = history.location.pathname

	paramsraw = paramsraw.replace('/teacher/questions', '')
	let params = paramsraw.split('/')

	let page = ''
	let category = ''
	let course = ''
	if (params.length) {
		page = params[params.length - 1]

		if (page === 'load') {
			course = params[params.length - 2]
			category = params[params.length - 3]
		}
	}

	const questionUIEvents = {
		newQuestionButtonClick: () => {
			history.push('/teacher/question/new')
		},
		openEditQuestionPage: (id) => {
			history.push(`/teacher/question/${id}/edit`)
		},
		openViewQuestionDialog: (id) => {
			history.push(`/teacher/questions/${id}/view`)
		},
		openDeleteQuestionDialog: (id) => {
			history.push(`/teacher/questions/${id}/delete`)
		},
		openNewCategoryCourseDialog: () => {
			history.push(`/teacher/questions/newCatCourse`)
		},
		openUploadCSVDialog: () => {
			history.push(`/teacher/questions/uploadCSV`)
		},
	}

	return (
		<QuestionUIProvider questionUIEvents={questionUIEvents}>
			<QuestionCard
				categoryprop={category}
				courseprop={course}
				history={history}
			/>

			{page === 'view' && (
				<Route exact={true} path='/teacher/questions/:id/view'>
					{({ history, match }) => (
						<QuestionViewDialog
							show={match != null}
							id={match && match.params.id}
							history={history}
							onHide={() => {
								history.push('/teacher/questions')
							}}
						/>
					)}
				</Route>
			)}

			{page === 'delete' && (
				<Route exact={true} path='/teacher/questions/:id/delete'>
					{({ history, match }) => (
						<QuestionDeleteDialog
							show={match != null}
							id={match && match.params.id}
							onHide={() => {
								history.push('/teacher/questions')
							}}
						/>
					)}
				</Route>
			)}

			{page === 'newCatCourse' && (
				<Route exact={true} path='/teacher/questions/newCatCourse'>
					{({ history, match }) => (
						<QuestionNewCatCouseDialog
							show={match != null}
							onHide={() => {
								history.push('/teacher/questions')
							}}
						/>
					)}
				</Route>
			)}

			{page === 'uploadCSV' && (
				<Route exact={true} path='/teacher/questions/uploadCSV'>
					{({ history, match }) => (
						<QuestionUploadCSVDialog
							show={match != null}
							onHide={() => {
								history.push('/teacher/questions')
							}}
						/>
					)}
				</Route>
			)}
		</QuestionUIProvider>
	)
}

export default QuestionPage
