import React, { useMemo,useState } from "react";
import { useDispatch } from "react-redux";
import { useQuizViewStatsUIContext } from "../QuizViewStatsUIContext";
import * as actions from '../../../../../_redux/takenQuiz/takenQuizAction';
import {useIntl,FormattedMessage} from "react-intl";
export function QuizViewStatsGrouping({qId}) {
  
  const intl = useIntl()

  const [status,setStatus] = useState('Pending')
  const dispatch = useDispatch();
  const quizViewStatsUIContext = useQuizViewStatsUIContext()
    const quizViewStatsUIProps = useMemo(() => {  
      return {
            ids: quizViewStatsUIContext.ids,
            setIds: quizViewStatsUIContext.setIds,
            queryParams: quizViewStatsUIContext.queryParams,
            setQueryParams: quizViewStatsUIContext.setQueryParams,
            downloadCertificateDialog: quizViewStatsUIContext.downloadCertificateDialog,
            emailQuizDialog: quizViewStatsUIContext.emailQuizDialog,
        }
    }, [quizViewStatsUIContext])

    const updateStatus = async () => {
      let takenQuizIds = quizViewStatsUIProps.ids
      if(takenQuizIds.length){
          dispatch(actions.updateStatus(takenQuizIds, status, qId, quizViewStatsUIContext.queryParams));
          //unset after the process has done
          quizViewStatsUIContext.setIds([])
      }
    }

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions my-3">
        <div className="col-md-3">
          <select
                className="form-control"
                name="status"
                value={status}
                onChange={e=>setStatus(e.target.value)}
                placeholder={intl.formatMessage({id:"FILTER.UPDATE_STATUS"})}
            >
                <option value={"Pending"}>{intl.formatMessage({id:"PENDING"})}</option>
                <option value={"Accepted"}>{intl.formatMessage({id:"ACCEPTED"})}</option>
                <option value={"Rejected"}>{intl.formatMessage({id:"REJECTED"})}</option>
            </select>
        </div>
        <div className="col-md-2">
        <button
          type="button"
          className="btn btn-danger font-weight-bolder font-size-sm"
          onClick={updateStatus}
          >
          <i className="fa fa-trash"></i> <FormattedMessage id="FILTER.UPDATE_STATUS"></FormattedMessage>
        </button>
        </div>
      </div>
    </div>
  );
}
