import axios from 'axios'

export const APIURL = process.env.REACT_APP_API_ENDPOINT

export const GET_ALL_FEEDBACK_URL = '/api/feedback/getAllQuestionFeedback'
export const GET_FEEDBACK_BY_ID_URL = '/api/feedback/getQuestionFeedbackById'
export const ADD_FEEDBACK_URL = '/api/feedback/sendQuestionFeedback'

export function getAllFeedback(queryParams) {
    return axios.post(APIURL + GET_ALL_FEEDBACK_URL, { queryParams })
}

export function getFeedbackById(feedbackId) {
    return axios.post(APIURL + GET_FEEDBACK_BY_ID_URL, { feedbackId })
}

export function addFeedback(questionId, feedbackText) {
    return axios.post(APIURL + ADD_FEEDBACK_URL, { questionId, feedbackText })
}
