import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import * as authCrud from '../_redux/authCrud'
import Swal from 'sweetalert2'

function Login(props) {
	const dispatch = useDispatch()

	const [loading, setLoading] = useState('')

	let useparams = useParams()

	let public_key_temp
	let private_key_temp
	let module_id_temp
	if (useparams.public_key && useparams.private_key && useparams.module_id) {
		public_key_temp = decodeURIComponent(useparams.public_key)
		private_key_temp = decodeURIComponent(useparams.private_key)
		module_id_temp = decodeURIComponent(useparams.module_id)
	}

	const process_keys_in_url =
		public_key_temp && private_key_temp && module_id_temp ? true : false

	const [public_key, setPublicKey] = useState(public_key_temp || '')
	const [private_key, setPrivateKey] = useState(private_key_temp || '')
	const [module_id, setModuleId] = useState(module_id_temp || '')

	const [public_key_is_invalid, validatePublicKey] = useState('')
	const [private_key_is_invalid, validatePrivateKey] = useState('')
	const [module_id_is_invalid, validateModuleId] = useState('')

	const create_user = async (token, userdata) => {
		let validations_ok = true

		if (validations_ok) {
			setLoading('create_user')
			let response = {}
			//token will be there, when user has used login with user module
			if (token) {
				//login with user module case
				response = await authCrud.create_user({ token, role: 'Student' })
			} else {
				//login using app keys case
				response = await authCrud.create_user_with_userdata({
					userdata,
					role: 'Student',
				})
			}

			response = response.data
			setLoading('')
			if (response.status) {
				login_with_user_module(token, userdata)
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: response.message,
					confirmButtonColor: '#1BC5BD',
				})
			}
		}
	}

	const login_with_user_module = async (tokentemp, userdata) => {
		setLoading('login_with_user_module')

		let response = {}

		if (tokentemp) {
			response = await authCrud.login_with_user_module({ token: tokentemp })
		} else {
			response = await authCrud.login_with_user_module_with_user_data({
				data: userdata,
			})
		}

		response = response.data
		setLoading('')
		if (response.status) {
			//we have token in this response
			let authToken = response.token
			let user = response.user
			let refreshToken

			//save this token

			dispatch(auth.actions.login(authToken, refreshToken, user))
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: response.message,
				confirmButtonColor: '#1BC5BD',
			})
		}
	}

	const login_using_keys = async (show_error_in_swal = false) => {
		let validations_ok = true
		validatePublicKey('')
		validatePrivateKey('')
		validateModuleId('')

		if (public_key === '') {
			validatePublicKey('is-invalid')
			validations_ok = false
		}

		if (private_key === '') {
			validatePrivateKey('is-invalid')
			validations_ok = false
		}

		if (module_id === '') {
			validateModuleId('is-invalid')
			validations_ok = false
		}

		if (validations_ok) {
			//we need to send this data to backend

			let map = {
				public_key,
				private_key,
				module_id,
			}

			setLoading('login_using_keys')
			let response = await authCrud.login_using_keys(map)
			response = response.data
			setLoading('')

			if (response.status) {
				let { userexists } = response

				if (!userexists) {
					let user = response.user
					create_user(null, user)
				} else {
					let authToken = response.token
					let user = response.user
					let refreshToken

					dispatch(auth.actions.login(authToken, refreshToken, user))
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: response.message,
					confirmButtonColor: '#1BC5BD',
				})
			}
		} else if (show_error_in_swal) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html:
					'Please provide all data i.e. public key, private key and module id',
				confirmButtonColor: '#1BC5BD',
			})
		}
	}

	useEffect(() => {
		if (public_key && private_key && module_id && process_keys_in_url) {
			login_using_keys(true)
		}
	}, [public_key, private_key, module_id])

	useEffect(() => {
		window.addEventListener('message', handleEvent, false)
		return () => {
			window.removeEventListener('message', handleEvent, false)
		}
	}, [])

	//Event listener function for useeffect

	const handleEvent = async (event) => {
		let url = process.env.REACT_APP_USER_MODULE_URL
		url = url.split('/')

		var protocol = url[0]
		var host = url[2]
		var origin = protocol + '//' + host
		if (event.origin === origin) {
			//window.location.href = "<%= redirectURL %>/moduleauthenticated?token=" + event.data.token + '&module_id=' + event.data.module_id

			//we need to send this token to API, and check that is this a new user or a previous user
			//if this is a new user then we need to ask them for their role i.e. Teacher or student
			setLoading('login_with_user_module')
			let response = await authCrud.check_user_existance(event.data.token)
			setLoading('')
			response = response.data
			if (response.status) {
				let { userexists } = response
				if (!userexists) {
					create_user(event.data.token, null)
				} else {
					login_with_user_module(event.data.token, null)
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: response.message,
					confirmButtonColor: '#1BC5BD',
				})
			}
		}
		return
	}

	return (
		<div className='text-center w-50'>
			{process_keys_in_url ? (
				<div className='h-100 d-flex'>
					<h3 className='font-size-h1'>
						<FormattedMessage id='Please wait'></FormattedMessage>
					</h3>
				</div>
			) : (
				<div className='login-signin'>
					<div className='mb-10'>
						<h3 className='opacity-90 font-weight-normal'>
							Sign In To QuizApp
						</h3>
					</div>

					<p className='opacity-80'>
						<FormattedMessage id='With app keys'></FormattedMessage>
					</p>

					<div className='form-group'>
						<input
							onChange={(e) => setPublicKey(e.target.value)}
							className={`form-control h-auto  rounded-pill py-4 px-8 ${public_key_is_invalid}`}
							type='text'
							placeholder='Public key'
							name='public_key'
							autoComplete='off'
						/>
						<div className='invalid-feedback text-left'>
							<FormattedMessage id='Public key is invalid'></FormattedMessage>
						</div>
					</div>
					<div className='form-group'>
						<input
							onChange={(e) => setPrivateKey(e.target.value)}
							className={`form-control h-auto  rounded-pill py-4 px-8 ${private_key_is_invalid}`}
							type='text'
							placeholder='Private key'
							name='private_key'
						/>
						<div className='invalid-feedback text-left'>
							<FormattedMessage id='Private key is invalid'></FormattedMessage>
						</div>
					</div>
					<div className='form-group'>
						<input
							onChange={(e) => setModuleId(e.target.value)}
							className={`form-control h-auto rounded-pill py-4 px-8 ${module_id_is_invalid}`}
							type='text'
							placeholder='Module ID'
							name='module_id'
						/>
						<div className='invalid-feedback text-left'>
							<FormattedMessage id='Module id is invalid'></FormattedMessage>
						</div>
					</div>

					<div className='form-group text-center mt-10'>
						<button
							onClick={() => login_using_keys()}
							id='kt_login_signin_submit1'
							className='btn btn-pill btn-primary opacity-90 px-15 py-3'
						>
							<FormattedMessage id='Sign In'></FormattedMessage>

							{loading === 'login_using_keys' && (
								<span className='spinner-border text-light ml-3'></span>
							)}
						</button>
					</div>

					<p className='opacity-90'>OR</p>

					<div className='form-group text-center mt-10'>
						<button
							id='kt_login_signin_submit'
							onClick={() =>
								window.open(
									process.env.REACT_APP_USER_MODULE_URL,
									'test',
									'width=500,height=500',
								)
							}
							className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
						>
							<span>Sign In With SingleLogin.io</span>
							{loading === 'login_with_user_module' && (
								<span className='spinner-border text-light ml-3'></span>
							)}
						</button>
					</div>
				</div>
			)}
		</div>
	)
}

export default injectIntl(connect(null, auth.actions)(Login))
