import React, { Fragment, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Timer from 'react-compound-timer'

import { Button, ButtonGroup, Spinner } from 'react-bootstrap'
import * as questionCrud from '../../../../../_redux/question/questionCrud'
import * as takenQuizCrud from '../../../../../_redux/takenQuiz/takenQuizCrud'
import _ from 'lodash'
import Question from './Question'
import ProvideFeedbackModal from '../quiz-feedback-modal/QuizFeedbackModal'

const QuizProcess1Page = ({ currentQuiz, finishExam }) => {
	//Main States
	const [place, setPlace] = useState(0)
	const nextPlace = () => setPlace(place + 1)

	const [ready, setReady] = useState(false)

	const [questionrating, setQuestionRating] = useState({})

	const [timeTaken, _setTimeTaken] = useState(0)
	const timeTaken_ref = React.useRef(timeTaken)
	const setTimeTaken = (x) => {
		timeTaken_ref.current = x // keep updated
		_setTimeTaken(x)
	}

	const [answerData, _setAnswerData] = useState({
		data: [],
		stateName: 'answerData',
	})
	const answerData_ref = React.useRef(answerData)
	const setAnswerData = (x) => {
		answerData_ref.current = x // keep updated
		answerData_ref.current.stateName = 'answerData'
		_setAnswerData(x)
	}

	const [quizSubmitted, _setQuizSubmitted] = useState(false)
	const quizSubmitted_ref = React.useRef(quizSubmitted)
	const setQuizSubmitted = (x) => {
		quizSubmitted_ref.current = x // keep updated
		_setQuizSubmitted(x)
	}

	const { actionsLoading } = useSelector(
		(state) => ({
			actionsLoading: state.takenQuiz.actionsLoading,
		}),
		shallowEqual,
	)

	//Disabling back button
	window.history.pushState(null, null, window.location.href)
	window.onpopstate = function(event) {
		window.history.go(1)
	}

	// Initialize answer object
	useEffect(() => {
		const newArray = []

		let ratings = {}
		currentQuiz.questions.map((q) => {
			if (q.optionType === 'radio')
				newArray.push({ id: q.id, status: '', value: '' })
			if (q.optionType === 'checkbox')
				newArray.push({ id: q.id, status: '', value: [] })

			if (q.questionRatings.length) {
				ratings[q.id] = q.questionRatings[0].questionRatingsText
			}
		})
		setAnswerData({ data: newArray })
		setQuestionRating(ratings)
		setReady(true)
	}, [])

	const markForReview = (i) => {
		let newArray = answerData_ref.current.data
		if (newArray[i].status === '') {
			newArray[i].status = 'not'
			setAnswerData({ data: newArray })
		}
	}

	const [showFeedBack, setShowFeedback] = useState(false)

	const endExam = (self) => {
		//once quiz is submitted, it cannot be submitted again
		//this is introduced because when user submits quiz, and stays on page, our timer is still running, and when timer stops, it calls this method, so we are keeping the check that the operation has already been done

		if (!quizSubmitted_ref.current) {
			document.getElementById('pause_time').click()
			setQuizSubmitted(true)
			if (self) finishExam(answerData_ref.current, timeTaken_ref.current)
			else finishExam(answerData_ref.current, timeTaken_ref.current)
		}
	}

	// Next Sub components methods
	const answeringRadio = (e) => {
		let newArray = answerData_ref.current.data
		newArray[place].value = e.target.value
		// if (newArray[place].status !== "not") newArray[place].status = "yes"
		newArray[place].status = 'yes'
		setAnswerData({ data: newArray })
	}

	const answeringCheckbox = (e) => {
		let newArray = answerData_ref.current.data
		let checkArray = []

		newArray[place].value.map((obj) => checkArray.push(obj))

		if (checkArray.includes(e.target.value))
			checkArray = checkArray.filter((obj) => obj !== e.target.value)
		else checkArray.push(e.target.value)
		newArray[place].value = checkArray
		// if (newArray[place].status !== "not") {
		//     if (checkArray.length > 0) newArray[place].status = "yes"
		//     else newArray[place].status = ""
		// }
		if (checkArray.length > 0) newArray[place].status = 'yes'
		else newArray[place].status = ''
		setAnswerData({ data: newArray })
	}

	const nextPlaceHandler = () => {
		if (currentQuiz.timeType === 'Question') {
			setTimeValue(currentQuiz.time * 60)
			document.getElementById('reset_time').click()
		}
		nextPlace()
	}

	const rateQuestion = (question, rating) => {
		// we will get thumbs-up and thumbs-down in rating
		//the buttons work as toggles,for example
		let rating_action = ''
		let questionrating_temp = _.cloneDeep(questionrating)
		if (questionrating_temp.hasOwnProperty(question.id)) {
			//if there is already rating of this question, we will check if it is same rating which the user has gave previous time
			//for example there is already thumbs-up in question_x and user has again clicked on that thumb,then we will reach in if
			//and in if we will remove the rating, in else we will update the rating

			if (questionrating_temp[question.id] === rating) {
				delete questionrating_temp[question.id]
				rating_action = 'DELETE'
			} else {
				//update rating
				questionrating_temp[question.id] = rating
				rating_action = 'UPDATE'
			}
		} else {
			//there was no rating added, so just add rating
			questionrating_temp[question.id] = rating
			rating_action = 'ADD'
		}
		setQuestionRating(questionrating_temp)

		questionCrud.rateUnrateQuestion({
			question_id: question.id,
			rating_action,
			rating,
		})
	}

	const [timeValue, setTimeValue] = useState(currentQuiz.time * 60 - 1)
	useEffect(() => {
		//if quiz is based on time per question then, display next question after time has passed (i.e. timer = 0) and reset the timer

		let timetakentemp = _.cloneDeep(timeTaken_ref.current)
		timetakentemp = timetakentemp + 1
		setTimeTaken(timetakentemp++)

		if (currentQuiz.timeType === 'Question') {
			if (timeValue === 0) {
				//go to next question, but make sure we have next question
				//and if there is no next question then timer will end and exam will be finished

				if (place < currentQuiz.totalQuestion - 1) {
					nextPlaceHandler()
				}
			}
		}
	}, [timeValue])

	const jumpToQuestion = (newPlace) => {
		let newArray = answerData_ref.current.data
		if (currentQuiz.timeType !== 'Question') {
			if (
				newArray[newPlace].status === '' ||
				newArray[newPlace].status === 'not'
			) {
				setPlace(newPlace)
			}
		}
	}

	return (
		<div style={style.root}>
			<ProvideFeedbackModal
				showFeedBack={showFeedBack}
				onHide={() => setShowFeedback(false)}
				question={currentQuiz.questions[place]}
				questions={currentQuiz.questions}
			/>
			<div className='quizNumberHeader'>
				{ready && (
					<ButtonGroup className='border rounded mb-5 d-flex align-content-start flex-wrap'>
						{[...Array(currentQuiz.totalQuestion)].map((e, i) => (
							<Button
								key={i}
								/*onClick={() => setPlace(i)}*/ className={`${
									answerData_ref.current.data[i].status === 'yes'
										? 'btn-green'
										: answerData_ref.current.data[i].status === 'no'
										? 'btn-danger'
										: answerData_ref.current.data[i].status === 'not'
										? 'btn-warning'
										: 'btn-secondary'
								} ${i === place && 'active'}`}
								onClick={() => jumpToQuestion(i)}
							>
								{i + 1}
							</Button>
						))}
					</ButtonGroup>
				)}
				{currentQuiz.time === -1 ? (
					<p>
						<FormattedMessage id='TIME_PASSED'></FormattedMessage>
						{' : '}
						<Timer
							initialTime={0}
							// startImmediately={false}
							direction='forward'
						>
							{({ getTime, pause }) => (
								<Fragment>
									{setTimeValue(Math.round(getTime() / 1000) + 1)}
									<span className='h4'>
										<Timer.Minutes /> : <Timer.Seconds />
									</span>
									<button onClick={pause} id='pause_time' className='d-none'>
										Pause timer silently
									</button>
								</Fragment>
							)}
						</Timer>
					</p>
				) : (
					<p>
						<FormattedMessage id='REMAINING_TIME'></FormattedMessage>
						{' : '}
						<Timer
							initialTime={currentQuiz.time * 60 * 1000}
							// startImmediately={false}
							direction='backward'
							checkpoints={[
								{
									time: 0,
									callback: () => endExam(false),
								},
							]}
						>
							{({ getTime, reset, pause }) => (
								<Fragment>
									{setTimeValue(Math.round(getTime() / 1000) - 1)}
									<span className='h4'>
										<Timer.Minutes /> : <Timer.Seconds />
									</span>
									<button onClick={pause} id='pause_time' className='d-none'>
										Pause timer silently
									</button>
									<button onClick={reset} id='reset_time' className='d-none'>
										Reset timer silently
									</button>
								</Fragment>
							)}
						</Timer>
					</p>
				)}
			</div>
			<div className='quizBox'>
				<div>
					{ready && answerData_ref.current.data[place].status === '' && (
						<Button
							size='sm'
							variant='primary'
							onClick={() => markForReview(place)}
						>
							<FormattedMessage id='MARK_FOR_REVIEW'></FormattedMessage>
						</Button>
					)}
					{/* <Button
						size='sm'
						variant='primary'
						onClick={() => setShowFeedback(true)}
					>
						<FormattedMessage id='PROVIDE_FEEDBACK'></FormattedMessage>
					</Button> */}
				</div>
				<Button variant='success' size='sm' onClick={() => endExam(true)}>
					<div className='d-flex align-items-center'>
						<FormattedMessage id='FINISH_EXAM'></FormattedMessage>
						{actionsLoading && (
							<Spinner animation='border' className='ml-2' variant='light' />
						)}
					</div>
				</Button>
			</div>
			<div>
				<h4 className='mt-5'>
					Question {place + 1} <FormattedMessage id='OF'></FormattedMessage>{' '}
					{currentQuiz.totalQuestion}
				</h4>
				<hr className='bg-primary my-5' />
				{ready && (
					<div>
						{ready && (
							<Question
								data={currentQuiz.questions[place]}
								current={place}
								answerData={answerData_ref.current}
								answeringRadio={answeringRadio}
								answeringCheckbox={answeringCheckbox}
							/>
						)}
						<div className='quizBox'>
							<div></div>
							<div className='btnGroupQuestion'>
								{place < currentQuiz.totalQuestion - 1 && (
									<Button
										variant='primary'
										size='sm'
										className='mt-0'
										onClick={() => nextPlaceHandler()}
									>
										<FormattedMessage id='NEXT'></FormattedMessage>
									</Button>
								)}
							</div>
						</div>
						{/* <div className="row mt-8 align-items-center">
                            <div className="col-md-3 col-lg-2"><span style={{fontSize:"1.3rem",fontWeight:"bold"}}>Rate question: </span></div>
                            <div className="col-md-2 col-lg-1"><i onClick={() => rateQuestion(currentQuiz.questions[place],'thumbs-up') } style={{fontSize:"2.5rem"}} className={`fas fa-thumbs-up icon_hover cursor-pointer ${questionrating.hasOwnProperty(currentQuiz.questions[place].id) && questionrating[currentQuiz.questions[place].id] === "thumbs-up" ? 'icon_selected' : ''}`}></i></div>
                            <div className="col-md-2 col-lg-1"><i onClick={() => rateQuestion(currentQuiz.questions[place],'thumbs-down') } style={{fontSize:"2.5rem"}} className={`fas fa-thumbs-down icon_hover cursor-pointer ${questionrating.hasOwnProperty(currentQuiz.questions[place].id) && questionrating[currentQuiz.questions[place].id] === "thumbs-down" ? 'icon_selected' : ''}`}></i></div>
                        </div> */}
					</div>
				)}
			</div>
		</div>
	)
}

const style = {
	root: {
		width: '100%',
	},
}

export default QuizProcess1Page
