import React, { Fragment, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { isEqual } from "lodash";
import { useQuestionUIContext } from "../../QuestionUIContext";

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const prepareFilter = (queryParams, searchText) => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.questionText = searchText;
    newQueryParams.filter = filter;
    return newQueryParams;
};

export const QuestionFilter = () => {
    // Question UI Context
    const questionUIContext = useQuestionUIContext();
    const questionUIProps = useMemo(() => {
        return {
            setQueryParams: questionUIContext.setQueryParams,
            queryParams: questionUIContext.queryParams,
        };
    }, [questionUIContext]);

    const [searchText, setSearchText] = useState("");

    const setSearchTextHandler = (value) => {
        setSearchText(value)
        applyFilter(value)
    }

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(questionUIProps.queryParams, values);
        if (!isEqual(newQueryParams, questionUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            questionUIProps.setQueryParams(newQueryParams);
        }
    };

    const useStyles = makeStyles(theme => ({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
            maxWidth: 250
        },
        button: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            textTransform: 'none'
        }
    }));
    const classes = useStyles();

    return (
        <Fragment>
            <TextField
                id="outlined-search"
                label={<FormattedMessage id="FILTER.SEARCH"></FormattedMessage>}
                className={classes.textField}
                value={searchText}
                onChange={(e) => setSearchTextHandler(e.target.value)}
                margin="dense"
                variant="outlined"
                color="primary"
                helperText={<FormattedMessage id="FILTER.SEARCH_BY_QUESTION"></FormattedMessage>}
            />
            
        </Fragment>
    )

}