import React, {useMemo} from 'react'
import { QuizViewStatsUIProvider } from './QuizViewStatsUIContext'
import QuizViewStatsTable from "./quiz-view-stats-table/QuizViewStatsTable";
const QuizViewStatsPage = ({ history, match: { params: { id } } }) => {

    const quizViewStatsUIEvents = {
        downloadCertificateDialog: (id, qId) => {
            history.push(`/teacher/quiz/${id}/${qId}/download`);
        },
        emailQuizDialog: (id, qId) => {
            history.push(`/teacher/quiz/${id}/${qId}/email`);
        }
    }

    return (
        <QuizViewStatsUIProvider quizViewStatsUIEvents={quizViewStatsUIEvents}>
            <QuizViewStatsTable qId={id} history={history} />
        </QuizViewStatsUIProvider>
    )
}

export default QuizViewStatsPage
