import React, { Fragment, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Beforeunload } from 'react-beforeunload'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'
import { Button, Spinner } from 'react-bootstrap'
import _ from 'lodash'

import SVG from 'react-inlinesvg'
import * as actions from '../../../../_redux/quiz/quizAction'
import * as actions2 from '../../../../_redux/takenQuiz/takenQuizAction'
import * as actions3 from '../../../../_redux/adminSetting/adminSettingAction'
import * as takenQuizCrud from '../../../../_redux/takenQuiz/takenQuizCrud'
import QuizPasswordPage from './quiz-password/QuizPasswordPage'
import QuizOptionSelectionPage from './quiz-option-selection/QuizOptionSelectionPage'
import QuizInstructionPage from './quiz-instruction-page/QuizInstructionPage'
import ReactStars from 'react-rating-stars-component'
import QuizProcess1Page from './quiz-process1-page/QuizProcess1Page'
import QuizProcess2Page from './quiz-process2-page/QuizProcess2Page'
const QuizTakePage = ({
	history,
	match: {
		params: { id },
	},
	intl,
}) => {
	const [examfinished, setExamFinished] = useState(false)
	const [quizRating, setQuizRating] = useState(0)
	const [showRatingControls, setShowRatingControls] = useState(false)

	const dispatch = useDispatch()
	const {
		currentQuiz,
		quizLoading,
		currentAdminSetting,
		settingLoading,
	} = useSelector(
		(state) => ({
			user: state.auth.user,
			currentQuiz: state.quiz.currentQuiz,
			quizLoading: state.quiz.actionsLoading,
			takenAttempt: state.takenQuiz.totalAttempts,
			currentAdminSetting: state.adminSetting.currentAdminSetting,
			settingLoading: state.adminSetting.actionsLoading,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(actions.fetchQuiz(id, history))
	}, [id, dispatch])

	useEffect(() => {
		dispatch(actions3.fetchAdminSetting())
	}, [dispatch])

	const goBack = async () => {
		if (place === 3 && !examfinished) {
			let result = window.confirm("You'll lose your data!")
			if (result) {
				await closeFullscreen()
				history.push('/student/quizPool')
			}
		} else {
			await closeFullscreen()
			history.push('/student/quizPool')
		}
	}

	const [place, setPlace] = useState(0)
	const nextPlace = () => setPlace(place + 1)
	// const prevPlace = () => setPlace(place - 1)

	const [option, setOption] = useState('')

	const finishExam = async (answers, timeTaken) => {
		await closeFullscreen()
		const answerObject = {}
		answerObject.quizId = currentQuiz.quizId
		answerObject.answers = answers
		answerObject.timeTaken = timeTaken
		dispatch(
			actions2.answerAll(answers, currentQuiz.quizId, timeTaken, history, intl),
		)
		setExamFinished(true)
		// nextPlace()
		// Swal.fire({
		//   icon: "primary",
		//   title: `${intl.formatMessage({ id: "QUIZ_COMPLETED" })}`,
		//   html: `${intl.formatMessage({ id: "QUIZ_COMPLETED" })}`,
		//   confirmButtonColor: "#1BC5BD",
		// })
	}

	useEffect(() => {
		//on load, get quiz rating if there was any, and set it to quizRating
		if (currentQuiz) {
			if (currentQuiz.takenQuizes.length) {
				setQuizRating(parseFloat(currentQuiz.takenQuizes[0].rating))
			}
		}
	}, [currentQuiz])

	const ratingChanged = (newRating) => {
		setQuizRating(newRating)
		takenQuizCrud.rateQuiz({
			quizId: currentQuiz.quizId,
			rating: parseInt(newRating),
		})
	}

	useEffect(() => {
		//Opening full screen
		openFullscreen()
		console.log('going')
		return () => {
			//Closing full screen
			// closeFullscreen() // no need
			console.log('Leaving')
		}
	}, [])

	function openFullscreen() {
		var elem = document.getElementById('fullScreenTab')

		if (elem.requestFullscreen) {
			elem.requestFullscreen()
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */
			elem.mozRequestFullScreen()
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */
			elem.webkitRequestFullscreen()
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem = window.top.document.body //To break out of frame in IE
			elem.msRequestFullscreen()
		}
	}

	const closeFullscreen = async () => {
		console.log('this')
		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				await document.exitFullscreen()
			} else if (document.mozCancelFullScreen) {
				await document.mozCancelFullScreen()
			} else if (document.webkitExitFullscreen) {
				await document.webkitExitFullscreen()
			} else if (document.msExitFullscreen) {
				await window.top.document.msExitFullscreen()
			}
		}
	}

	return (
		<Card id='fullScreenTab' className='fullScreen'>
			{/* <Card> */}
			{(quizLoading || settingLoading) && (
				<div className='text-center p-4'>
					<Spinner animation='border' variant='dark' />
				</div>
			)}
			{currentQuiz && currentAdminSetting && !_.isEmpty(currentAdminSetting) && (
				<Fragment>
					<CardHeader title={`${currentQuiz.quizName}`}>
						<CardHeaderToolbar>
							{showRatingControls && (
								<ReactStars
									classNames='mr-2'
									count={5}
									value={quizRating ? quizRating : 0}
									onChange={ratingChanged}
									size={24}
									activeColor='#ffd700'
								/>
							)}
							{place < 3 && (
								<Button variant='secondary' size='sm' onClick={goBack}>
									<span className='svg-icon svg-icon-md svg-icon-dark'>
										<SVG
											src={toAbsoluteUrl(
												'/media/svg/icons/Navigation/Arrow-left.svg',
											)}
										/>
									</span>
									<FormattedMessage id='GO_BACK'></FormattedMessage>
								</Button>
							)}
						</CardHeaderToolbar>
					</CardHeader>

					<CardBody>
						{place === 0 ? (
							<Fragment>
								<QuizPasswordPage
									password={currentQuiz.password}
									nextPlace={nextPlace}
								/>
							</Fragment>
						) : place === 1 ? (
							<Fragment>
								<QuizOptionSelectionPage
									nextPlace={nextPlace}
									option={option}
									setOption={setOption}
									showAnswerSettingAllowed={
										currentAdminSetting.showAnswerSettingAllowed
									}
									showAnswer={currentQuiz.showAnswer}
								/>
							</Fragment>
						) : place === 2 ? (
							<Fragment>
								<Beforeunload
									onBeforeunload={(e) => {
										return examfinished ? false : "You'll lose your data!"
									}}
								>
									<QuizInstructionPage
										setShowRatingControls={setShowRatingControls}
										nextPlace={nextPlace}
										history={history}
										quizId={currentQuiz.quizId}
										goBack={goBack}
									/>
								</Beforeunload>
							</Fragment>
						) : place === 3 ? (
							<Fragment>
								<Beforeunload
									onBeforeunload={(e) => {
										return examfinished ? false : "You'll lose your data!"
									}}
								>
									{option === 'all' ? (
										<QuizProcess1Page
											currentQuiz={currentQuiz}
											finishExam={finishExam}
										/>
									) : (
										<QuizProcess2Page
											currentQuiz={currentQuiz}
											finishExam={finishExam}
										/>
									)}
								</Beforeunload>
							</Fragment>
						) : (
							// ) : place === 4 ? (
							//     <QuizResultPage resultObject={resultObject} />
							<Fragment>
								{/* <div className='d-flex'>
                                    <span className='svg-icon svg-icon-lg svg-icon-dark'>
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Sad.svg")} />
                                    </span>
                                    <h4>
                                        <FormattedMessage id='PROMPT_QUIZ_LIMIT_REACHED'></FormattedMessage>
                                    </h4>
                                </div> */}
							</Fragment>
						)}
					</CardBody>
				</Fragment>
			)}
		</Card>
	)
}

export default injectIntl(QuizTakePage)
