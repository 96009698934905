import React, { useEffect, useState, useRef } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"

import { Modal, Button, Spinner } from "react-bootstrap"

import { EmailStudentForm } from "./EmailStudentForm"

import * as actions from "../../../../_redux/takenQuiz/takenQuizAction"

const LeaderBoardEmailStudentDialog = ({ show, onHide, history, id }) => {
    const dispatch = useDispatch()

    const { currentTakenQuiz, actionsLoading } = useSelector(
        (state) => ({
            currentTakenQuiz: state.takenQuiz.currentTakenQuiz,
            actionsLoading: state.takenQuiz.actionsLoading,
        }),
        shallowEqual
    )

    useEffect(() => {
        if (id) dispatch(actions.fetchTakenQuizById(id, history))
    }, [dispatch, id])

    useEffect(() => {
        if (currentTakenQuiz !== undefined)
            if (currentTakenQuiz !== null)
                setFormData({
                    ...formData,
                    studentName: currentTakenQuiz.user.fullname,
                    studentEmail: currentTakenQuiz.user.email,
                })
            else {
                onHide()
            }
    }, [currentTakenQuiz])

    const [formData, setFormData] = useState({
        studentName: "",
        studentEmail: "",
        emailSubject: "",
        emailMessage: "",
    })

    const saveEmailInfo = (values) => {
        setFormData({
            ...formData,
            emailSubject: values.emailSubject,
            emailMessage: values.emailMessage,
        })
        dispatch(
            actions.sendEmail(formData.studentName, formData.studentEmail, values.emailSubject, values.emailMessage, -1, history)
        )
    }

    const btnRef = useRef()
    const sendEmailClick = () => {
        if (btnRef && btnRef.current) btnRef.current.click()
    }

    return (
        <Modal show={show} onHide={onHide} keyboard={false} backdrop='static' aria-labelledby='example-modal-sizes-title-lg'>
            <Modal.Header closeButton>
                <Modal.Title id='example-modal-sizes-title-lg'>
                    <FormattedMessage id='SEND_EMAIL_TO_STUDENT'></FormattedMessage>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailStudentForm
                    formData={formData}
                    saveEmailInfo={saveEmailInfo}
                    btnRef={btnRef}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={() => sendEmailClick()}>
                    <div className="d-flex align-items-center">
                    <FormattedMessage id='SEND_EMAIL'></FormattedMessage>
                        {actionsLoading &&
                            <Spinner animation="border" className="ml-2" variant="light" />
                        }
                    </div>
                </Button>
                <Button variant='secondary' onClick={() => onHide()}>
                    <FormattedMessage id='CLOSE'></FormattedMessage>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LeaderBoardEmailStudentDialog
