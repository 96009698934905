import React, { Fragment, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'

import * as actions from '../../../../_redux/feedback/feedbackAction'

const FeedbackViewDialog = ({ history, id, show, onHide }) => {
    // if !id we should close modal
    useEffect(() => {
        if (!id) {
            onHide()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    // Quiz Redux state
    const dispatch = useDispatch()
    const { isLoading, currentFeedback } = useSelector(
        (state) => ({
            isLoading: state.question.actionsLoading,
            currentFeedback: state.feedback.currentFeedback,
        }),
        shallowEqual
    )

    // looking for loading/dispatch
    useEffect(() => {}, [isLoading, dispatch])

    useEffect(() => {
        if (id) {
            dispatch(actions.fetchFeedbackById(id, history))
        }
    }, [id, dispatch])

    return (
        <Fragment>
            {currentFeedback && (
                <Modal
                    show={show}
                    onHide={onHide}
                    keyboard={false}
                    backdrop='static'
                    aria-labelledby='example-modal-sizes-title-lg'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id='example-modal-sizes-title-lg'>
                            <FormattedMessage id='VIEW_FEEDBACK'></FormattedMessage>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Student name</h6>
                        <span>{currentFeedback.studentName}</span>
                        <h6 className='mt-4'>Student email</h6>
                        <span>{currentFeedback.studentEmail}</span>
                        <h6 className='mt-4'>Question</h6>
                        <p>{currentFeedback.questionText}</p>
                        <h6 className='mt-4'>Feedback</h6>
                        <p>{currentFeedback.feedbackText}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={() => onHide()}>
                            <FormattedMessage id='CLOSE'></FormattedMessage>
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Fragment>
    )
}

export default FeedbackViewDialog
