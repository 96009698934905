import React, { Fragment, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEqual } from 'lodash'
import { useFeedbackUIContext } from '../../FeedbackUIContext'

import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const prepareFilter = (queryParams, searchText1, searchText2) => {
    const newQueryParams = { ...queryParams }
    const filter = {}
    filter.studentName = searchText1
    filter.questionText = searchText2
    newQueryParams.filter = filter
    return newQueryParams
}

export const FeedbackFilter = () => {
    // Feedback UI Context
    const feedbackUIContext = useFeedbackUIContext()
    const feedbackUIProps = useMemo(() => {
        return {
            setQueryParams: feedbackUIContext.setQueryParams,
            queryParams: feedbackUIContext.queryParams,
        }
    }, [feedbackUIContext])

    const [searchText1, setSearchText1] = useState('')
    const [searchText2, setSearchText2] = useState('')

    const setSearchText1Handler = (value) => {
        setSearchText1(value)
        applyFilter(value,searchText2)
    }

    const setSearchText2Handler = (value) => {
        setSearchText2(value)
        applyFilter(searchText1,value)
    }

    const applyFilter = (value1, value2) => {
        const newQueryParams = prepareFilter(feedbackUIProps.queryParams, value1, value2)
        if (!isEqual(newQueryParams, feedbackUIProps.queryParams)) {
            newQueryParams.pageNumber = 1
            feedbackUIProps.setQueryParams(newQueryParams)
        }
    }

    const useStyles = makeStyles((theme) => ({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
            maxWidth: 250,
        },
        button: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            textTransform: 'none',
        },
    }))
    const classes = useStyles()

    return (
        <Fragment>
            <TextField
                id='outlined-search1'
                label={<FormattedMessage id='FILTER.SEARCH'></FormattedMessage>}
                className={classes.textField}
                value={searchText1}
                onChange={(e) => setSearchText1Handler(e.target.value)}
                margin='dense'
                variant='outlined'
                color='primary'
                helperText={<FormattedMessage id='FILTER.SEARCH_BY_STUDENT_NAME'></FormattedMessage>}
            />
            <TextField
                id='outlined-search2'
                label={<FormattedMessage id='FILTER.SEARCH'></FormattedMessage>}
                className={classes.textField}
                value={searchText2}
                onChange={(e) => setSearchText2Handler(e.target.value)}
                margin='dense'
                variant='outlined'
                color='primary'
                helperText={<FormattedMessage id='FILTER.SEARCH_BY_QUESTION'></FormattedMessage>}
            />
        </Fragment>
    )
}
