export default
    [
        {
            id: "278404cd-d212-4ee6-a130-b6dafe77c111",
            teacherId: 2,
            quizName: 'Quiz One name',
            quizDescription: 'Quiz one description',
            course: 'Calculus',
            category: 'Calculation',
            level: 'Moderate',
            time: 3,
            totalQuestion: 3,
            allowSkip: "Yes",
            attemptsAllowed: "2",
            negativeMarking: "No",
            takeBestScore: "No",
            password: "",
            questions: [
                {
                    id: "9e516e22-b27d-4a73-9efe-1b26c46adb4f",
                    questionText: 'Quiz 1 Question text 1',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 1',
                            status: false
                        },
                        {
                            text: 'Option text 2',
                            status: false
                        },
                        {
                            text: 'Option text 3',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 1</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "51b85773-5699-444d-ba9b-7914ac421996",
                    questionText: 'Quiz 1 Question text 2',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 4',
                            status: false
                        },
                        {
                            text: 'Option text 5',
                            status: false
                        },
                        {
                            text: 'Option text 6',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 2</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "b89fca36-0156-4ab7-9826-16512dd3f6d8",
                    questionText: 'Quiz 1 Question text 3',
                    optionType: 'checkbox',
                    options: [
                        {
                            text: 'Option text 7',
                            status: true
                        },
                        {
                            text: 'Option text 8',
                            status: false
                        },
                        {
                            text: 'Option text 9',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 3</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                }
            ]

        },
        {
            id: "70c91f16-b40c-4456-93a2-f7cf87db4d80",
            teacherId: 2,
            quizName: 'Quiz Two name',
            quizDescription: 'Quiz two description',
            course: 'Differential Equation',
            category: 'Calculation',
            level: 'Moderate',
            time: -1,
            totalQuestion: 3,
            allowSkip: "No",
            attemptsAllowed: "3",
            negativeMarking: "No",
            takeBestScore: "No",
            password: "abc",
            questions: [
                {
                    id: "85735ab2-7ae5-40e3-ac2f-61ff244e1543",
                    questionText: 'Quiz 2 Question text 1',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 1',
                            status: false
                        },
                        {
                            text: 'Option text 2',
                            status: true
                        },
                        {
                            text: 'Option text 3',
                            status: false
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 1</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "36c3c924-ab70-4226-b7bf-e81d4e3562e2",
                    questionText: 'Quiz 2 Question text 2',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 4',
                            status: true
                        },
                        {
                            text: 'Option text 5',
                            status: false
                        },
                        {
                            text: 'Option text 6',
                            status: false
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 2</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "61188ee7-36c0-4e82-a456-59d91f0e1bfb",
                    questionText: 'Quiz 2 Question text 3',
                    optionType: 'checkbox',
                    options: [
                        {
                            text: 'Option text 7',
                            status: false
                        },
                        {
                            text: 'Option text 8',
                            status: true
                        },
                        {
                            text: 'Option text 9',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 3</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                }
            ]
        },
        {
            id: "fde91c61-8207-4791-9e5e-3badfffecf1d",
            teacherId: 2,
            quizName: 'Quiz Three name',
            quizDescription: 'Quiz three description',
            course: 'English',
            category: 'Literature',
            level: 'Hard',
            time: 3,
            totalQuestion: 2,
            allowSkip: "No",
            attemptsAllowed: "3",
            negativeMarking: "No",
            takeBestScore: "No",
            password: "",
            questions: [
                {
                    id: "9bfae27a-f379-43f6-b230-0a9aa991ad52",
                    questionText: 'Quiz 3 Question text 1',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 1',
                            status: true
                        },
                        {
                            text: 'Option text 2',
                            status: false
                        },
                        {
                            text: 'Option text 3',
                            status: false
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 1</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "55689a75-6184-4271-99a3-9f6a14132a38",
                    questionText: 'Quiz 3 Question text 2',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 4',
                            status: false
                        },
                        {
                            text: 'Option text 5',
                            status: false
                        },
                        {
                            text: 'Option text 6',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 2</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                }
            ]
        },
        {
            id: "08792a53-3a68-4e39-b6cc-76544bd34ec5",
            teacherId: 2,
            quizName: 'Quiz Four name',
            quizDescription: 'Quiz four description',
            course: 'Geography',
            category: 'Literature',
            level: 'Easy',
            time: 3,
            totalQuestion: 2,
            allowSkip: "Yes",
            attemptsAllowed: "3",
            negativeMarking: "No",
            takeBestScore: "No",
            password: "",
            questions: [
                {
                    id: "a8029b88-d276-401f-820c-354025b2fefd",
                    questionText: 'Quiz 4 Question text 1',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 1',
                            status: false
                        },
                        {
                            text: 'Option text 2',
                            status: false
                        },
                        {
                            text: 'Option text 3',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 1</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "c918b999-0ea8-4d4d-9ef1-ea8b333da2d0",
                    questionText: 'Quiz 4 Question text 2',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 4',
                            status: false
                        },
                        {
                            text: 'Option text 5',
                            status: false
                        },
                        {
                            text: 'Option text 6',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 2</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                }
            ]
        },
        {
            id: "d5718111-6b2f-45c0-ad8c-52bbf9991be1",
            teacherId: 5,
            quizName: 'Quiz Five name',
            quizDescription: 'This is quiz five detailed description for overflow ellipsis',
            course: 'Partial Fraction',
            category: 'Calculation',
            level: 'Moderate',
            time: .5,
            totalQuestion: 3,
            allowSkip: "Yes",
            attemptsAllowed: "3",
            negativeMarking: "No",
            takeBestScore: "No",
            password: "abc",
            questions: [
                {
                    id: "cb672e36-46d6-4c35-9d39-2b3af643c61b",
                    questionText: 'Quiz 5 Question text 1',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 1',
                            status: false
                        },
                        {
                            text: 'Option text 2',
                            status: true
                        },
                        {
                            text: 'Option text 3',
                            status: false
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 1</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "0eae5d0f-afc5-4fbb-aa7a-236e94fcefae",
                    questionText: 'Quiz 5 Question text 2',
                    optionType: 'radio',
                    options: [
                        {
                            text: 'Option text 4',
                            status: true
                        },
                        {
                            text: 'Option text 5',
                            status: false
                        },
                        {
                            text: 'Option text 6',
                            status: false
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 2</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                },
                {
                    id: "7da1e2f7-c0a5-499b-92e9-dec71b9f775b",
                    questionText: 'Quiz 5 Question text 3',
                    optionType: 'checkbox',
                    options: [
                        {
                            text: 'Option text 7',
                            status: false
                        },
                        {
                            text: 'Option text 8',
                            status: true
                        },
                        {
                            text: 'Option text 9',
                            status: true
                        }
                    ],
                    answerExplaination: "<p><strong>Explaination 3</strong></p><p>This is Question <strong>Text.</strong></p><p><i><strong>Italic </strong>text</i></p><ul><li>Point 1</li><li>Point 2</li></ul><p>Something else…</p>"
                }
            ]
        }
    ];