import axios from 'axios'

export const APIURL = process.env.REACT_APP_API_ENDPOINT;

export const GET_ALL_QUIZ_URL = "/api/quiz/allByTeacher";
export const GET_ALL_QUIZ_FOR_STUDENT_URL = "/api/quiz/all";

export const ADD_QUIZ_URL = "/api/quiz";
export const UPDATE_QUIZ_URL = "/api/quiz/update";
export const DELETE_QUIZ_URL = "/api/quiz/delete";
export const GET_QUIZ_BY_ID_URL = "/api/quiz/findOne";
export const CHECK_QUIZ_ALL_ANSWER = "api/quiz/checkAllAnswer";
export const RANDOM_QUIZ_URL = "/api/quiz/allRandom";

//Done
export function getAllQuiz(queryParams, tId) {
    return axios.post(APIURL + GET_ALL_QUIZ_URL, { queryParams, tId })
}

export function getAllQuizForStudent(queryParams) {
    return axios.post(APIURL + GET_ALL_QUIZ_FOR_STUDENT_URL, { queryParams })
}

export function randomQuiz(filter) {
    return axios.post(APIURL + RANDOM_QUIZ_URL, filter)
}

export function getQuizById(quizId) {
    return axios.post(APIURL + GET_QUIZ_BY_ID_URL, { quizId })
}

export function addQuiz(quizData) {
    return axios.post(APIURL + ADD_QUIZ_URL, quizData)
}

export function updateQuiz(quizData) {
    return axios.post(APIURL + UPDATE_QUIZ_URL, quizData)
}

export function deleteQuiz(quizId) {
    return axios.post(APIURL + DELETE_QUIZ_URL, { quizId })
}

//Pending

export function checkQuizAllAnswer(answerObject) {
    return axios.post(CHECK_QUIZ_ALL_ANSWER, { answerObject })
}
