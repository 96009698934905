import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'

import {
	// getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from '../../../../../../../_metronic/_helpers'

import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { useQuizUIContext } from '../QuizUIContext'
import * as uiHelpers from '../QuizUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/quiz/quizAction'
import { quizSlice } from '../../../../_redux/quiz/quizSlice'

import { QuizFilter } from '../quiz-filter/QuizFilter'
import swal from 'sweetalert2'
import { Link } from 'react-router-dom'

const quiz_slice = quizSlice.actions

const QuizTable = ({ intl }) => {
	const quizUIContext = useQuizUIContext()
	const quizUIProps = useMemo(() => {
		return {
			ids: quizUIContext.ids,
			setIds: quizUIContext.setIds,
			queryParams: quizUIContext.queryParams,
			setQueryParams: quizUIContext.setQueryParams,
			quizTakePage: quizUIContext.quizTakePage,
		}
	}, [quizUIContext])

	// Getting curret state of quiz list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({
			currentState: state.quiz,
		}),
		shallowEqual,
	)

	const { totalCount, entities, listLoading, premiumQuiz } = currentState

	// quiz Redux state
	const dispatch = useDispatch()
	useEffect(() => {
		// clear selections list
		quizUIProps.setIds([])
		// server call by queryParams

		//initially make empty
		let totalCount = 0
		let entities = []
		dispatch(quiz_slice.quizesFetched({ totalCount, entities }))
		dispatch(actions.fetchAllQuizes(quizUIProps.queryParams))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quizUIProps.queryParams, dispatch])

	const [showErrors, setShowErrors] = useState(false)
	useEffect(() => {
		if (premiumQuiz && premiumQuiz.subscribed === false) {
			if (premiumQuiz.subscriptionEnabled === 'Yes')
				swal
					.fire({
						title: 'Oops!!',
						html: 'Not Subscribed!! Proceed to subscription page',
						icon: 'error',
						showCancelButton: true,
					})
					.then(
						(result) =>
							result.isConfirmed &&
							window.location.replace('/student/subscription'),
					)
			if (premiumQuiz.totalCount > totalCount) setShowErrors(true)
		}
	}, [premiumQuiz, totalCount])
	// Table columns
	const columns = [
		{
			dataField: 'quizId',
			text: 'ID',
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'quizName',
			text: `${intl.formatMessage({ id: 'QUIZ_NAME' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'quizDescription',
			text: `${intl.formatMessage({ id: 'DESCRIPTION' })}`,
			style: {
				maxWidth: 250,
				// display: 'block',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
		{
			dataField: 'totalQuestion',
			text: `${intl.formatMessage({ id: 'TOTAL_QUESTION' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'action',
			text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				quizTakePage: quizUIProps.quizTakePage,
			},
			classes: 'text-center',
			headerClasses: 'text-center',
			style: {
				minWidth: '50px',
			},
		},
	]

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: quizUIProps.queryParams.pageSize,
		page: quizUIProps.queryParams.pageNumber,
	}

	// For selecting columns
	// const [ids, setIds] = useState([])

	return (
		<Fragment>
			<Card>
				<CardHeader
					title={
						<>
							<FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>{' '}
							{showErrors && premiumQuiz && (
								<span className='text-danger font-size-xs'>
									Displaving {totalCount} of {premiumQuiz.totalCount} Quizzes
									(subscribe to view all quizzes with answers.)
								</span>
							)}
						</>
					}
				>
					<CardHeaderToolbar>
						{showErrors && (
							<Link to='/student/subscription' className='btn btn-primary'>
								Subscribe
							</Link>
						)}
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<QuizFilter />
					<PaginationProvider pagination={paginationFactory(paginationOptions)}>
						{({ paginationProps, paginationTableProps }) => {
							return (
								<Pagination
									isLoading={listLoading}
									paginationProps={paginationProps}
									variant='primary'
								>
									<BootstrapTable
										wrapperClasses='table-responsive'
										bordered={false}
										classes='table table-head-custom table-vertical-center'
										bootstrap4
										remote
										keyField='quizId'
										data={entities === null ? [] : entities}
										columns={columns}
										defaultSorted={uiHelpers.defaultSorted}
										onTableChange={getHandlerTableChange(
											quizUIProps.setQueryParams,
										)}
										//For selecting columns
										// selectRow={getSelectRow({
										//     entities,
										//     ids,
										//     setIds,
										// })}
										{...paginationTableProps}
									>
										<PleaseWaitMessage entities={entities} />
										<NoRecordsFoundMessage entities={entities} />
									</BootstrapTable>
								</Pagination>
							)
						}}
					</PaginationProvider>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default injectIntl(QuizTable)
