import { createSlice } from "@reduxjs/toolkit"

const initialTakenQuizesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entityType: "",
    currentTakenQuiz: null,
    nameList: null,
    startQuiz: false,
    lastError: null,
}

export const callTypes = {
    list: "list",
    action: "action",
}

export const takenQuizSlice = createSlice({
    name: "takenQuiz",
    initialState: initialTakenQuizesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },
        startCall: (state, action) => {
            state.error = null
            state.startQuiz = false
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
        },
        endCall: (state, action) => {
            state.error = null
            state.startQuiz = false
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },
        // getTakenQuiz
        takenQuizFetched: (state, action) => {
            const { totalCount, entities, entityType } = action.payload
            state.listLoading = false
            state.error = null
            state.entities = entities
            state.totalCount = totalCount
            state.entityType = entityType
        },
        // getTotalAttempts
        totalAttemptsFetched: (state, action) => {
            state.listLoading = false
            state.error = null
            if (action.payload === undefined) {
                state.entities = null
                state.totalAttempts = 0
            } else {
                state.entities = [action.payload]
                state.totalAttempts = action.payload.totalAttempts
            }
        },
        // getTakenQuizName
        takenQuizNameFetched: (state, action) => {
            state.listLoading = false
            state.error = null
            state.nameList = action.payload
        },
        takenQuizByIdFetched: (state, action) => {
            state.actionsLoading = false
            state.error = null
            state.currentTakenQuiz = action.payload
        },
        startQuiz: (state, action) => {
            state.actionsLoading = false
            state.error = null
            state.startQuiz = action.payload
        },
        endQuiz: (state, action) => {
            state.actionsLoading = false
            state.error = null
        },
        clearList:(state,action)=>{
            state.entities = []
            state.totalCount = 0
        },
    },
})
