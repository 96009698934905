import AWS from '../../s3config'

export function removeCSSClass(ele, cls) {
	const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
	ele.className = ele.className.replace(reg, ' ')
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls)
}

export const toAbsoluteUrl = (pathname) =>
	process.env.REACT_APP_OUR_APP_URL + pathname

export const getSignedUrl = (filename) => {
	let signedUrlExpireSeconds = Number(
		process.env.REACT_APP_S3_SIGNED_URL_EXPIRY_SECONDS,
	)
	let s3 = new AWS.S3()
	const url = s3.getSignedUrl('getObject', {
		Bucket: process.env.REACT_APP_S3_BUCKET,
		Key: filename,
		Expires: signedUrlExpireSeconds,
	})

	return url
}

export const formatTime = (seconds) => {
	const format = (val) => `0${Math.floor(val)}`.slice(-2)
	const minutes = (seconds % 3600) / 60

	return [minutes, seconds % 60].map(format).join(' : ')
}
