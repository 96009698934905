import * as requestFromServer from './settingsCrud'
import { settingsSlice, callTypes } from './settingsSlice'

const { actions } = settingsSlice

export const fetchSettings = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.findSettings(queryParams)
		if (response.status !== 200) throw response
		const entities = response.data.data
		dispatch(actions.settingsFetched({ entities }))
	} catch ({ response }) {
		let error = response?.data?.message || "Can't find settings"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchSetting = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.settingFetched({ settingForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.getSettingById(id)
		if (response.status !== 200) throw response
		const setting = response.data.data
		dispatch(actions.settingFetched({ settingForEdit: setting }))
	} catch ({ response }) {
		let error = response?.data?.message || "Can't find setting"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateSettings = (setting) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateSettings(setting)
		if (response.status !== 200) throw response
		dispatch(actions.settingUpdated({ setting }))
	} catch ({ response }) {
		console.log(response)
		let error = response?.data?.message || "Can't update setting"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
