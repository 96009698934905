import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Modal, Button, Spinner } from "react-bootstrap";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as actions from "../../../../_redux/categoryCourse/categoryCourseAction";

import { NewCatCourseForm } from "./NewCatCourseForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    margin: theme.spacing(1, "auto"),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 120,
  },
}));

const QuestionNewCatCourseDialog = ({ show, onHide }) => {
  const classes = useStyles();
  const [place, setPlace] = useState(0);
  const nextPlace = () => setPlace(place + 1);
  const prevPlace = () => setPlace(place - 1);

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = {
      filter: {
        category: "",
      },
      sortOrder: "asc", // asc||desc
      sortField: "category",
      pageNumber: 1,
      pageSize: 100,
    };
    dispatch(actions.fetchAllCategoryCourse(queryParams));
  }, [dispatch]);
  const { entities, actionsLoading } = useSelector(
    (state) => ({
      entities: state.categoryCourse.entities,
      actionsLoading: state.categoryCourse.actionsLoading,
    }),
    shallowEqual
  );

  const [formData, setFormData] = useState({
    category: "",
    course: "",
  });

  const[processing, setProcessing]=useState(false)

  const [prevCat, setPrevCat] = useState("");
  const selectFromAbove = (e) => {
    setPrevCat(e.target.value);
    setFormData({ ...formData, category: e.target.value });
  };

  const saveFormData = (values) => {
    setProcessing("saving")
    setFormData({
      category: values.category,
      course: values.course,
    });
    dispatch(actions.addCategoryCourse({ category: values.category, course: values.course}));
  };
  useEffect(() => {
    if (processing === 'saving' && !actionsLoading){
      setProcessing("")
      onHide();
    }
  }, [actionsLoading]);

  const btnRef = useRef();
  const saveBtnClick = () => {
    if (btnRef && btnRef.current) btnRef.current.click();
  };

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onHide}
        keyboard={false}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="TEACHER.CREATE_CATEGORY_AND_COURSE"></FormattedMessage>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {place === 0 ? (
            <Fragment>
              <p>
                <strong>
                  <FormattedMessage id="PROMPT_CATEGORY_OPTION"></FormattedMessage>
                </strong>
              </p>
              {entities && entities.length > 0 && (
                <Fragment>
                  <div className={classes.root}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="category">
                        <FormattedMessage id="CATEGORY"></FormattedMessage>
                      </InputLabel>
                      <Select
                        labelId="category"
                        id="category"
                        value={prevCat}
                        onChange={(e) => selectFromAbove(e)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {entities.map((c) => (
                          <MenuItem key={c.id} value={c.category}>
                            {c.category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Fragment>
              )}
            </Fragment>
          ) : place === 1 ? (
            <NewCatCourseForm
              formData={formData}
              saveFormData={saveFormData}
              btnRef={btnRef}
            />
          ) : (
            <Fragment></Fragment>
          )}
          {/* {
                        entities && entities.length > 0 &&
                        <Fragment>
                            <p><strong>If you want to create only course than select category from below options</strong></p>
                            <RadioGroup value={prevCat} onChange={e => selectFromAbove(e)}>
                                {
                                    entities.map(c => <FormControlLabel key={c.id} value={c.category} control={<Radio color="primary" />} label={c.category} />)
                                }
                            </RadioGroup>
                            <Button variant="secondary" onClick={clearFields}>Clear</Button>
                        </Fragment>
                    } */}
        </Modal.Body>
        <Modal.Footer>
          {place === 0 && (
            <Button variant="primary" onClick={() => nextPlace()}>
              <FormattedMessage id="NEXT"></FormattedMessage>
            </Button>
          )}
          {place === 1 && (
            <>
              <Button variant="primary" onClick={() => saveBtnClick()}>
                <div className="d-flex align-items-center">
                <FormattedMessage id="CREATE"></FormattedMessage>
                  {processing==="saving" &&
                      <Spinner animation="border" className="ml-2" variant="light" />
                  }
                </div>
              </Button>
              <Button variant="secondary" onClick={() => prevPlace()}>
                <FormattedMessage id="BACK"></FormattedMessage>
              </Button>
            </>
          )}
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage id="CLOSE"></FormattedMessage>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default QuestionNewCatCourseDialog;
