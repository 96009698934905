import * as requestFromServer from './quizCrud'

import { quizSlice, callTypes } from './quizSlice'

import Swal from 'sweetalert2'

const { actions } = quizSlice

export const fetchAllQuizes = (queryParams) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	return requestFromServer
		.getAllQuizForStudent(queryParams)
		.then((response) => {
			if (response.status === 200) {
				const { totalCount, entities, premiumQuiz } = response.data
				dispatch(actions.quizesFetched({ totalCount, entities, premiumQuiz }))
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				}).then(() => {
					dispatch(actions.quizesFetched({ totalCount: 0, entities: [] }))
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find quizes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchQuizes = (queryParams, tId) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	return requestFromServer
		.getAllQuiz(queryParams, tId)
		.then((response) => {
			if (response.status === 200) {
				const { totalCount, entities } = response.data
				dispatch(actions.quizesFetched({ totalCount, entities }))
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				}).then(() => {
					dispatch(actions.quizesFetched({ totalCount: 0, entities: [] }))
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find quizes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchQuiz = (id, history) => (dispatch) => {
	if (!id) {
		return dispatch(actions.quizFetched({ currentQuiz: undefined }))
	}
	dispatch(actions.startCall({ callType: callTypes.action }))
	dispatch(actions.clearFetched())
	return requestFromServer
		.getQuizById(id)
		.then((response) => {
			if (response.status === 200) {
				const { currentQuiz } = response.data
				dispatch(actions.quizFetched({ currentQuiz }))
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				}).then(() => {
					history.push('/teacher/quizPool')
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find quizes"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const checkAllAnswers = (answerObject) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	requestFromServer
		.checkQuizAllAnswer(answerObject)
		.then((response) => {})
		.catch((error) => {
			error.clientMessage = "Can't check answers"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const addQuiz = (quizData, history) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.addQuiz(quizData)
		.then((response) => {
			if (response.status === 200) {
				dispatch(actions.quizAdded())
				Swal.fire({
					title: 'success',
					html: '<strong>Quiz Added</strong>',
					icon: 'success',
					confirmButtonColor: '#1BC5BD',
					confirmButtonText: 'CONTINUE',
				}).then(() => {
					history.push('/teacher/quizPool')
				})
				return response
			} else {
				//Just to clear loading
				dispatch(actions.quizAdded())

				let { message } = response.data
				if (response.data.hasOwnProperty('errors')) {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						// html: `<strong>Something wrong happened</strong>`,
						html: `<strong>${response.data.errors[0].msg}</strong>`,
					})
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						// html: `<strong>Something wrong happened</strong>`,
						html: `<strong>${message}</strong>`,
					})
				}
				return response
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't add quiz"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const updateQuiz = (quizData, history) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	requestFromServer
		.updateQuiz(quizData)
		.then((response) => {
			if (response.status === 200) {
				Swal.fire({
					title: 'success',
					html: '<strong>Quiz Updated</strong>',
					icon: 'success',
					confirmButtonColor: '#1BC5BD',
					confirmButtonText: 'CONTINUE',
				}).then(() => {
					history.push('/teacher/quizPool')
				})
			} else {
				let { message } = response.data
				console.log(message)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>Something wrong happened</strong>`,
					// html: `<strong>${message}</strong>`,
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't add quiz"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const randomQuiz = (filter) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	requestFromServer
		.randomQuiz(filter)
		.then((response) => {
			const { totalCount, entities } = response.data
			dispatch(actions.quizesFetched({ totalCount, entities }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find quizes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const deleteQuiz = (quizId) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	requestFromServer
		.deleteQuiz(quizId)
		.then((response) => {
			if (response.status === 200) {
				let { message } = response.data
				dispatch(actions.quizDeleted({ quizId: quizId }))
				Swal.fire({
					title: 'success',
					html: `<strong>${message}</strong>`,
					icon: 'success',
					confirmButtonColor: '#1BC5BD',
					confirmButtonText: 'CONTINUE',
				})
			} else {
				let { message } = response.data
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: `<strong>${message}</strong>`,
				})
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't delete quiz"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
