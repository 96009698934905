import { createSlice } from "@reduxjs/toolkit";

const initialQuizesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    questionForEdit: undefined,
    currentQuestion: undefined,
    lastError: null
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const questionSlice = createSlice({
    name: "question",
    initialState: initialQuizesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getAllQuestions
        questionsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // getQuestionById
        questionFetched: (state, action) => {
            const { question } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.currentQuestion = question;
        },
        // Add Question
        questionAdded: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.question);
            state.totalCount = state.entities.length;
        },
        // Update Question
        questionUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities = action.payload.list;
            state.totalCount = state.entities.length;
            state.currentQuestion = undefined;
        },
        // Delete Question
        questionDeleted: (state, action) => {
            let { questionId } = action.payload
            questionId = parseInt(questionId)
            state.actionsLoading = false;
            state.error = null;
            state.entities = state.entities.filter(el => el.id !== questionId);
            state.totalCount = state.totalCount - 1
        },
    }
});