import React from "react"
import { Route } from "react-router-dom"

import { TakenQuizUIProvider } from "./TakenQuizUIContext"
import TakenQuizTable from "./taken-quiz-table/TakenQuizTable"
import TakenQuizDownloadDialog from "./taken-quiz-download-dialog/TakenQuizDownloadDialog"

const TakenQuizPage = ({ history }) => {
    const takenQuizUIEvents = {
        downloadCertificateDialog: (id) => {
            history.push(`/student/takenQuiz/${id}/download`)
        },
        retakeQuizDialog: (id) => {
            history.push(`/student/quizPool/${id}/take`)
        },
        openResultPage: (id) => {
            history.push(`/student/takenQuiz/${id}/result`)
        },
    }

    return (
        <TakenQuizUIProvider takenQuizUIEvents={takenQuizUIEvents}>
            <Route path='/student/takenQuiz/:id/download'>
                {({ history, match }) => (
                    <TakenQuizDownloadDialog
                        show={match != null}
                        id={match && match.params.id}
                        history={history}
                        onHide={() => {
                            history.push("/student/takenQuiz")
                        }}
                    />
                )}
            </Route>
            <TakenQuizTable />
        </TakenQuizUIProvider>
    )
}

export default TakenQuizPage
