import React, { createContext, useContext, useState, useCallback } from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter } from './QuizUIHelper'

const QuizUIContext = createContext()

export function useQuizUIContext() {
	return useContext(QuizUIContext)
}

export const QuizUIConsumer = QuizUIContext.Consumer

export function QuizUIProvider({ quizUIEvents, children }) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	const [ids, setIds] = useState([])
	const setQueryParams = useCallback((nextQueryParams) => {
		setQueryParamsBase((prevQueryParams) => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams)
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams
			}

			return nextQueryParams
		})
	}, [])

	// const initQuiz = {
	//     id: undefined,
	//     name: ""
	// };

	const value = {
		queryParams,
		setQueryParamsBase,
		ids,
		setIds,
		setQueryParams,
		// initQuiz,
		newQuizButtonClick: quizUIEvents.newQuizButtonClick,
		openEditQuizPage: quizUIEvents.openEditQuizPage,
		openEditQuizDialog: quizUIEvents.openEditQuizDialog,
		openViewQuizPage: quizUIEvents.openViewQuizPage,
		openViewStatsPage: quizUIEvents.openViewStatsPage,
	}

	return (
		<QuizUIContext.Provider value={value}>{children}</QuizUIContext.Provider>
	)
}
