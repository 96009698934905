import React, { Fragment, useEffect, useMemo } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationProvider,
} from 'react-bootstrap-table2-paginator'

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'
import {
	// getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
	formatTime,
} from '../../../../../../../_metronic/_helpers'
import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import { useLeaderBoardUIContext } from '../LeaderBoardUIContext'
import * as uiHelpers from '../LeaderBoardUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/takenQuiz/takenQuizAction'
import { takenQuizSlice } from '../../../../_redux/takenQuiz/takenQuizSlice'

import { LeaderBoardFilter } from '../leader-board-filter/LeaderBoardFilter'

const takenQuiz_slice = takenQuizSlice.actions

const LeaderBoardTable = ({ intl }) => {
	const leaderBoardUIContext = useLeaderBoardUIContext()
	const leaderBoardUIProps = useMemo(() => {
		return {
			ids: leaderBoardUIContext.ids,
			setIds: leaderBoardUIContext.setIds,
			queryParams: leaderBoardUIContext.queryParams,
			setQueryParams: leaderBoardUIContext.setQueryParams,
			downloadCertificateDialog: leaderBoardUIContext.downloadCertificateDialog,
			emailStudentDialog: leaderBoardUIContext.emailStudentDialog,
		}
	}, [leaderBoardUIContext])

	// Getting curret state of quiz list from store (Redux)
	const { currentState, user } = useSelector(
		(state) => ({
			currentState: state.takenQuiz,
			user: state.auth.user,
		}),
		shallowEqual,
	)

	const { totalCount, entities, entityType, listLoading } = currentState

	// taken quiz Redux state
	const dispatch = useDispatch()
	useEffect(() => {
		// clear selections list
		leaderBoardUIProps.setIds([])
		// server call by queryParams

		//initially make empty
		let totalCount = 0
		let entities = []
		dispatch(
			takenQuiz_slice.takenQuizFetched({
				totalCount,
				entities,
				entityType: '',
			}),
		)
		dispatch(actions.fetchAllTakenQuiz(leaderBoardUIProps.queryParams))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leaderBoardUIProps.queryParams, dispatch])

	// Table columns
	const columns = [
		{
			dataField: 'takenQuizId',
			text: 'ID',
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'fullname',
			text: `${intl.formatMessage({ id: 'STUDENT_NAME' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		// {
		// 	dataField: 'studentEmail',
		// 	text: `${intl.formatMessage({ id: 'STUDENT_EMAIL' })}`,
		// 	formatter: (cell, row) => {
		// 		return row.studentEmail.length > 10
		// 			? row.studentEmail.substring(0, 10) + '...'
		// 			: row.studentEmail
		// 	},
		// },
		{
			dataField: 'quizName',
			text: `${intl.formatMessage({ id: 'QUIZ_NAME' })}`,
		},
		{
			dataField: 'quizCategory',
			text: `${intl.formatMessage({ id: 'QUIZ_CATEGORY' })}`,
		},
		{
			dataField: 'percentage',
			text: `${intl.formatMessage({ id: 'SCORE' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'totalAttempts',
			text: `${intl.formatMessage({ id: 'ATTEMPTS' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'timeTaken',
			text: `${intl.formatMessage({ id: 'TIME_MM:SS' })}`,
			formatter: (cell, row) => {
				return formatTime(row.timeTaken)
			},
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'status',
			text: `${intl.formatMessage({ id: 'STATUS' })}`,
		},
		{
			dataField: 'action',
			text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				user: user,
				downloadCertificateDialog: leaderBoardUIProps.downloadCertificateDialog,
				emailStudentDialog: leaderBoardUIProps.emailStudentDialog,
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '90px',
			},
		},
	]

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: leaderBoardUIProps.queryParams.pageSize,
		page: leaderBoardUIProps.queryParams.pageNumber,
	}

	return (
		<Fragment>
			<Card>
				<CardHeader
					title={<FormattedMessage id='NAV.LEADER_BOARD'></FormattedMessage>}
				>
					<CardHeaderToolbar>
						{/* <Button variant="primary" size="sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/DownloadedFile.svg")} />
                            </span>
                            <FormattedMessage id="DOWNLOAD_CSV"></FormattedMessage>
                        </Button> */}
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<p>
						<FormattedMessage id='PROMPT_NUMBER_STUDENT_TOOK_QUIZ'></FormattedMessage>
						{' : '}
						<strong>{totalCount}</strong>
					</p>
					<LeaderBoardFilter />
					<PaginationProvider pagination={paginationFactory(paginationOptions)}>
						{({ paginationProps, paginationTableProps }) => {
							return (
								<Pagination
									isLoading={
										listLoading || entities === null || entityType !== 'all'
									}
									paginationProps={paginationProps}
									variant='primary'
								>
									<BootstrapTable
										wrapperClasses='table-responsive'
										bordered={false}
										classes='table table-head-custom table-vertical-center'
										bootstrap4
										remote
										keyField='id'
										data={
											entities === null || entityType !== 'all' ? [] : entities
										}
										columns={columns}
										defaultSorted={uiHelpers.defaultSorted}
										onTableChange={getHandlerTableChange(
											leaderBoardUIProps.setQueryParams,
										)}
										//For selecting columns
										// selectRow={getSelectRow({
										//     entities,
										//     ids: leaderBoardUIProps.ids,
										//     setIds: leaderBoardUIProps.setIds,
										// })}
										{...paginationTableProps}
									>
										<PleaseWaitMessage entities={entities} />
										<NoRecordsFoundMessage entities={entities} />
									</BootstrapTable>
								</Pagination>
							)
						}}
					</PaginationProvider>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default injectIntl(LeaderBoardTable)
