import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./FeedbackUIHelper";

const FeedbackUIContext = createContext();

export function useFeedbackUIContext() {
    return useContext(FeedbackUIContext);
}

export const FeedbackUIConsumer = FeedbackUIContext.Consumer;

export function FeedbackUIProvider({ feedbackUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        openViewFeedbackDialog: feedbackUIEvents.openViewFeedbackDialog,
    };

    return <FeedbackUIContext.Provider value={value}>{children}</FeedbackUIContext.Provider>;
}