import * as requestFromServer from "./categoryCourseCrud";

import { categoryCourseSlice, callTypes } from "./categoryCourseSlice";

import Swal from "sweetalert2";

const { actions } = categoryCourseSlice;

export const fetchAllCategoryCourse = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getAllCategoryCourse(queryParams)
    .then((response) => {
            const { totalCount, entities } = response.data;
            dispatch(actions.categoryCourseFetched({ totalCount, entities }));
        })
    .catch((error) => {
            error.clientMessage = "Can't find category and course";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const addCategoryCourse = (categoryCourseObject) => async (dispatch) => {
    await dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .addCategoryCourse(categoryCourseObject)
    .then((response) => {
      if(response.status===200){
      const { totalCount, entities } = response.data;
                Swal.fire({
        icon: "success",
        title: "Category and Course added",
        text: "Your new category and course added",
        confirmButtonColor: "#1BC5BD",
      });
      dispatch(actions.categoryCourseAdded({ totalCount, entities }));
      }
      else{
        const { message } = response.data;
        Swal.fire({
          icon: "error",
          title: "Category and Course error",
          text: message,
          confirmButtonColor: "#1BC5BD",
        });
        dispatch(actions.endCall({ callType: callTypes.action }));
      }
        })
    .catch((error) => {
            error.clientMessage = "Can't add category and course";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};
