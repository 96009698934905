import React, { useEffect, Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import {
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
} from '@material-ui/core'
import { Button, Spinner } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
	group: {
		margin: theme.spacing(1, 0),
		display: 'flex',
		flexDirection: 'row',
	},
	btn: {
		width: 'fit-content',
	},
}))

export function QuizSettingForm({
	formData,
	setFormData,
	saveSetting,
	loading,
}) {
	const intl = useIntl()
	const classes = useStyles()

	const [alertStatus, setAlertStatus] = useState(false)

	const onChangeQuizTime = (value) => {
		setFormData({ ...formData, quizTimeAllowed: value })
	}

	useEffect(() => {
		if (formData.quizTimeAllowed === 'No') {
			setFormData({ ...formData, questionTimeAllowed: 'No' })
		}
	}, [formData.quizTimeAllowed])

	return (
		<Fragment>
			{alertStatus && (
				<Alert
					severity='error'
					onClose={() => {
						setAlertStatus(false)
					}}
					className='mb-4'
				>
					<FormattedMessage id='ALERT.REQUIRED_OPTIONS'></FormattedMessage>
				</Alert>
			)}
			<Formik
				enableReinitialize={true}
				initialValues={formData}
				onSubmit={async () => {
					if (
						formData.quizTimeAllowed !== '' &&
						formData.negativeMarking !== '' &&
						formData.showAnswerSettingAllowed !== '' &&
						formData.passwordQuiz !== '' &&
						formData.internalization !== ''
					) {
						await saveSetting()
					} else {
						setAlertStatus(true)
						setTimeout(() => {
							setAlertStatus(false)
						}, 4000)
					}
				}}
			>
				{({ handleSubmit }) => (
					<>
						<Form className='form form-label-right d-flex flex-column'>
							<div className='row'>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='QUIZ_TIME_ALLOWED'></FormattedMessage>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.quizTimeAllowed}
											onChange={(e) => onChangeQuizTime(e.target.value)}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='QUESTION_TIME_ALLOWED'></FormattedMessage>
										<sub>
											&nbsp;(
											<FormattedMessage id='QUESTION_TIME_ALLOWED_HELP'></FormattedMessage>
											)
										</sub>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.questionTimeAllowed}
											onChange={(e) =>
												setFormData({
													...formData,
													questionTimeAllowed: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={
													<Radio
														disabled={
															formData.quizTimeAllowed === 'No' ? true : false
														}
														color='primary'
													/>
												}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={
													<Radio
														disabled={
															formData.quizTimeAllowed === 'No' ? true : false
														}
														color='primary'
													/>
												}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className='row'>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='NEGATIVE_MARKING_ALLOWED'></FormattedMessage>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.negativeMarking}
											onChange={(e) =>
												setFormData({
													...formData,
													negativeMarking: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='SHOW_ANSWERS_ALLOWED'></FormattedMessage>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.showAnswerSettingAllowed}
											onChange={(e) =>
												setFormData({
													...formData,
													showAnswerSettingAllowed: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className='row'>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='PASSWORD_QUIZ_ALLOWED'></FormattedMessage>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.passwordQuiz}
											onChange={(e) =>
												setFormData({
													...formData,
													passwordQuiz: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										<FormattedMessage id='INTERNALIZATION_SETTING'></FormattedMessage>
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.internalization}
											onChange={(e) =>
												setFormData({
													...formData,
													internalization: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className='row'>
								<div className='col-lg-6 d-flex flex-column'>
									<FormLabel>
										Enable Subscription
										{/* <FormattedMessage id='PASSWORD_QUIZ_ALLOWED'></FormattedMessage> */}
									</FormLabel>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<RadioGroup
											className={classes.group}
											value={formData.enableSubscription}
											onChange={(e) =>
												setFormData({
													...formData,
													enableSubscription: e.target.value,
												})
											}
										>
											<FormControlLabel
												value='Yes'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='YES'></FormattedMessage>}
											/>
											<FormControlLabel
												value='No'
												control={<Radio color='primary' />}
												label={<FormattedMessage id='NO'></FormattedMessage>}
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<FormLabel>
								<FormattedMessage id='TEACHER_FUNCTIONALITY_ALLOWED_TO'></FormattedMessage>
							</FormLabel>
							<FormControl className={classes.formControl}>
								<select
									value={formData.maximumRoleLevelForTeacher}
									onChange={(e) =>
										setFormData({
											...formData,
											maximumRoleLevelForTeacher: e.target.value,
										})
									}
									className='form-control col-12 col-md-6 mt-5'
								>
									<option value='0'>
										{intl.formatMessage({ id: 'OWNER' })}
									</option>
									<option value='1'>{intl.formatMessage({ id: 'CEO' })}</option>
									<option value='2'>{intl.formatMessage({ id: 'VP' })}</option>
									<option value='3'>
										{intl.formatMessage({ id: 'DIRECTOR' })}
									</option>
									<option value='4'>
										{intl.formatMessage({ id: 'MANAGER' })}
									</option>
									<option value='5'>
										{intl.formatMessage({ id: 'SUPERVISOR' })}
									</option>
									<option value='6'>
										{intl.formatMessage({ id: 'TEAM_LEAD' })}
									</option>
									<option value='7'>
										{intl.formatMessage({ id: 'TEAM_MEMBER' })}
									</option>
									<option value='8'>
										{intl.formatMessage({ id: 'USER' })}
									</option>
								</select>
							</FormControl>

							<Button
								type='submit'
								variant='primary'
								className={`${classes.btn} px-8 mt-6`}
								onSubmit={handleSubmit}
							>
								<div className='d-flex align-items-center'>
									<FormattedMessage id='SAVE'></FormattedMessage>
									{loading === 'saving' && (
										<Spinner
											animation='border'
											className='ml-2'
											variant='light'
										/>
									)}
								</div>
							</Button>
						</Form>
					</>
				)}
			</Formik>
		</Fragment>
	)
}
