import { createSlice } from '@reduxjs/toolkit'

const initialFeedbackState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    currentFeedback: undefined,
    lastError: null,
}

export const callTypes = {
    list: 'list',
    action: 'action',
}

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: initialFeedbackState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },
        startCall: (state, action) => {
            state.error = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
        },
        endCall: (state, action) => {
            state.error = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },
        // getFeedback
        feedbackFetched: (state, action) => {
            const { feedbackObject } = action.payload
            state.actionsLoading = false
            state.error = null
            state.currentFeedback = feedbackObject
        },
        // getAllFeedbacks
        feedbacksFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.error = null
            state.entities = entities
            state.totalCount = totalCount
        },
    },
})
