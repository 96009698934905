import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import * as actions from '../../../../_redux/question/questionAction'

import { getSignedUrl } from '../../../../../../../_metronic/_helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
    width: 'fit-content',
  },
}))

const QuestionViewDialog = ({ history, id, show, onHide }) => {
  const classes = useStyles()
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      console.log("here?",id)
      onHide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const [q, setQ] = useState(null)

  // Quiz Redux state
  const dispatch = useDispatch()
  const { isLoading, currentQ } = useSelector(
    (state) => ({
      isLoading: state.question.actionsLoading,
      currentQ: state.question.currentQuestion,
    }),
    shallowEqual
  )

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchQuestionById(id,history))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (currentQ) {
      setQ(currentQ)
    }
  }, [currentQ])

  return (
    <Fragment>
      <Modal show={show} onHide={onHide} keyboard={false} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="VIEW_QUESTION"></FormattedMessage>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {q===null &&
            <div className="text-center p-2">
              <Spinner animation="border" variant="dark" />
            </div>
          }
          {q !== null && (
            <Fragment>
              <h6>
                <FormattedMessage id="QUESTION"></FormattedMessage>
              </h6>
              <p>{q.questionText}</p>
              <div className="text-center">
                {q.questionImage &&
                  <img
                    className="questionImage my-4"
                    src={getSignedUrl(q.questionImage)}
                    alt="explaination"
                  />
                }
              </div>
              <h6>
                <FormattedMessage id="OPTION_LIST"></FormattedMessage>
              </h6>
              <div className="d-flex flex-column mb-2">
                <ol>
                {q.options.map((o, i) => (
                  <li key={i}><Chip label={o.optionText} color={o.optionStatus ? 'primary' : 'default'} className={classes.chip} /></li>
                ))}
                </ol>
              </div>
              <h6>
                <FormattedMessage id="ANSWER_EXPLAINATION"></FormattedMessage>
              </h6>
              <div dangerouslySetInnerHTML={{ __html: q.answerExplaination }}></div>
              <div className="text-center">
                {q.image &&
                  <img
                    className="questionImage my-4"
                    src={getSignedUrl(q.image)}
                    alt="explaination"
                  />
                }
              </div>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setQ(null)
              onHide()
            }}
          >
            <FormattedMessage id="CLOSE"></FormattedMessage>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default QuestionViewDialog
