import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import {
	LayoutSplashScreen,
	ContentRoute,
} from '../../../../../_metronic/layout'

import QuizSettingPage from './quizSetting/QuizSettingPage'
import { PaymentSettings } from './subscriptions/PaymentSettings'

const AdminPage = () => {
	const { user } = useSelector(
		(state) => ({
			user: state.auth.user,
		}),
		shallowEqual,
	)

	const { profile } = user

	return profile !== parseInt(process.env.REACT_APP_OWNER_PROFILE_ID) ? (
		<Redirect to='/' />
	) : (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact={true} from='/admin' to='/admin/leaderBoard' />}
				<ContentRoute path='/admin/quizSetting' component={QuizSettingPage} />
				<ContentRoute path='/admin/subscription' component={PaymentSettings} />
				{/* <ContentRoute path='/teacher/quizPool' component={QuizPoolPage} />
                    <ContentRoute path='/teacher/questions' component={QuestionPage} />
                    <ContentRoute path='/teacher/quiz/new' component={QuizAddPage} />
                    <ContentRoute path='/teacher/quiz/:id/edit' component={QuizEditPage} />
                    <ContentRoute path='/teacher/quiz/:id/view' component={QuizViewPage} /> */}
				<Redirect to='../error/error-v2' />
			</Switch>
		</Suspense>
	)
}

export default AdminPage
