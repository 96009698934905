import axios from "axios"

const APIURL = process.env.REACT_APP_API_ENDPOINT

export const GET_ADMIN_SETTING_URL = "/api/admin/adminSetting"
export const UPDATE_ADMIN_SETTING_URL = "/api/admin/adminSetting"

export function getAdminSetting() {
    return axios.get(APIURL + GET_ADMIN_SETTING_URL)
}

export function updateAdminSetting(formData) {
    return axios.post(APIURL + UPDATE_ADMIN_SETTING_URL, formData)
}
