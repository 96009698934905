import { createSlice } from "@reduxjs/toolkit";

const initialcategoryCourseState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    currentCategoryCourse: undefined,
  lastError: null,
};

export const callTypes = {
    list: "list",
  action: "action",
};

export const categoryCourseSlice = createSlice({
    name: "categoryCourse",
    initialState: initialcategoryCourseState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getAllCategoryCourse
        categoryCourseFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // categoryCourseAdded
        categoryCourseAdded: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.actionsLoading = false;
            state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
  },
});
