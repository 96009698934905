export default
    [
        {
            id: 1,
            category: "Calculation",
            course: [
                {
                    value: "Calculus"
                },
                {
                    value: "Differential Equation"
                },
                {
                    value: "Integration"
                },
                {
                    value: "Partial Fraction"
                }
            ]
        },
        {
            id: 2,
            category: "Literature",
            course: [
                {
                    value: "English"
                },
                {
                    value: "Geography"
                }
            ]
        }
    ];